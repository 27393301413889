import style from "./downloadappcard.module.scss";

import PhoneImage from "../../assets/images/home/phone-image.png";
import GoogleImage from "../../assets/images/home/google-play-download.png";
import AppleImage from "../../assets/images/home/app-store-download.png";
import Amblem from "../../assets/images/amblem.png";

const DownloadAppCard = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.card}>
        <img className={style.phone} src={PhoneImage} />
        <div className={style.content}>
          <div className={style.title}>
            <img src={Amblem} />
            <h3>Uygulamamızı İndirin!</h3>
          </div>
          <p>
            Evinizi, ofisinizi, parça eşyanızı taşıtmak ya da taşıma hizmeti
            vermek için uygulamamızı ücretsiz indirin, size özel hizmetlerimize
            mobil cihazlarınızdan hemen ulaşın.
          </p>
          <div className={style.buttonWrapper}>
            <a href={"/"}>
              <img src={GoogleImage} />
            </a>
            <a href={"/"}>
              <img src={AppleImage} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadAppCard;
