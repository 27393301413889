import React from "react";
import style from "./footer.module.scss";
import LogoWhite from "../../assets/images/logo-white.png";
import FooterImage from "../../assets/images/footer-image.png";
import { SocialMediaIcon } from "../../assets/icon";
import { useLocation } from "react-router-dom";

// const Footer = () => {
//   return (
//     <div className="my-[70px]">
//       <div className="container">
//         <div className="footer-container-width">
//           <div className="footer-container">
//             <div className="footer-section grid lg:grid-cols-2 grid-cols-1">
//               <div className="footer-box">
//                 <div className="f-box-text">Kolay ve Hesaplı…</div>
//                 <div className="f-box-text-alt">
//                   Son kullanıcıya hizmete ulaşmada kolaylık sunan borvey, taşıma
//                   hizmeti alan müşteriye planlama yapma ya da hizmet almak
//                   istediği takvim ve güzergahta uygun hizmet verenlerle bir
//                   araya gelme olanağı tanır
//                 </div>
//                 <div className="f-box-btn or-f">
//                   <a href="#">Hizmet Al</a>
//                 </div>
//               </div>
//               <div className="footer-box">
//                 <div className="f-box-text">Aracın boşta beklemesin</div>
//                 <div className="f-box-text-alt">
//                   Müşteri ya da yük için günlerce garajlarda beklemeye, boş ya
//                   da yarım yükle masraflı seferlere son. borvey'e gel, uygun
//                   güzergahta uygun yük bulalım, zaman ve akaryakıt harcama
//                 </div>
//                 <div className="f-box-btn bl-f">
//                   <a href="#">Hizmet Ver</a>
//                 </div>
//               </div>
//             </div>
//             <div className="border-line"></div>
//             <div className="footer-links-container px-[50px]">
//               <div className="footer-logo">
//                 <a href="#">
//                   <img src={logo} alt="logo" />
//                 </a>
//               </div>
//               <div className="footer-links">
//                 <a href="/">Anasayfa</a>
//                 <a href="/about">Hakkımızda</a>
//                 <a href="/help">Hizmetler</a>
//                 <a href="/contact">İletişim</a>
//               </div>
//               <div className="footer-links-social">
//                 <a href="https://www.facebook.com/people/borvey/100093522541664/">
//                   <i className="fa-brands fa-facebook-f"></i>
//                 </a>
//                 <a href="https://x.com/borveyborvey">
//                   <i class="fa-brands fa-twitter"></i>
//                 </a>
//                 <a href="https://www.linkedin.com/in/borvey-com-441345287/">
//                   <i className="fa-brands fa-linkedin-in"></i>
//                 </a>
//                 <a href="https://www.instagram.com/borveycom/">
//                   <i className="fa-brands fa-instagram"></i>
//                 </a>
//               </div>
//             </div>
//             <div className="border-line"></div>
//             <div className="footer-links-container text-[#8C9491]">
//               <div className="footer-links">
//                 © 2011 - 2024 borvey Teknoloji AŞ, Tüm Hakları Saklıdır
//               </div>
//               <div className="footer-links footer-kvk">
//                 <a href="/cookies">Gizlilik Sözleşmesi</a>
//                 <a href="/cookies">KVKK</a>
//                 <a href="/cookies">Kullanıcı Sözleşmesi</a>
//                 <a href="/cookies">Çağrı Merkezi Aydınlatma Metni</a>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

const Footer = () => {
  const location = useLocation();
  const greyPath = ["/cookies", "/help", "/contact"];

  return (
    <footer
      className={`${style.footer} ${
        greyPath.includes(location.pathname) ? style.grey : null
      }`}
    >
      <div className={style.imageWrapper}>
        <img src={FooterImage} />
      </div>
      <div className={style.contentWrapper}>
        <div className={style.content}>
          <a className={style.logo} href="/">
            <img src={LogoWhite} />
          </a>
          <ul>
            <li>
              <a href="/">Anasayfa</a>
            </li>
            <li>
              <a href="/about">Hakkımızda</a>
            </li>
            <li>
              <a href="/help">Yardım</a>
            </li>
            <li>
              <a href="/contact">İletişim</a>
            </li>
          </ul>
          <div className={style.socialMedia}>
            <a
              target="_blank"
              href="https://www.facebook.com/people/borvey/100093522541664/"
            >
              <SocialMediaIcon icon={"facebook"} />
            </a>
            <a target="_blank" href="https://x.com/borveyborvey">
              <SocialMediaIcon icon={"twitter"} />
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/in/borvey-com-441345287/"
            >
              <SocialMediaIcon icon={"linkedin"} />
            </a>
            <a target="_blank" href="https://www.instagram.com/borveycom/">
              <SocialMediaIcon icon={"instagram"} />
            </a>
          </div>
        </div>
      </div>
      <div className={style.bottomWrapper}>
        <div className={style.bottom}>
          <h6>© 2011 - 2024 borvey Teknoloji AŞ, Tüm Hakları Saklıdır</h6>
          <ul>
            <li>
              <a href="/cookies">Gizlilik Sözleşmesi</a>
            </li>

            <li>
              <a href="/cookies">KVKK</a>
            </li>
            <li>
              <a href="/cookies">Kullanıcı Sözleşmesi</a>
            </li>
            <li>
              <a href="/cookies">Çağrı Merkezi Aydınlatma Metni</a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
