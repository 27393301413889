import React from "react";
import qi from "../../assets/images/qi.png";
import q1 from "../../assets/images/q1.jpg";
import q2 from "../../assets/images/q2.jpg";
import q3 from "../../assets/images/q3.jpg";

import TitleSubtitle from "../HomeElement/TitleSubtitle/TitleSubtitle";
import style from "./howitworks.module.scss";
import Image1 from "../../assets/images/home/how-to-1.png";
import Image2 from "../../assets/images/home/how-to-2.png";
import Image3 from "../../assets/images/home/how-to-3.png";

// const HowItWorks = () => {
//   return (
//     <section className="bg-effect">
//       <div className="container">
//         <div className="sup-header">
//           <div className="home-title flex-j">Sistem Nasıl Çalışıyor?</div>
//         </div>
//         <div className="sy-box-container">
//           <div className="sy-box">
//             <div className="flex items-center justify-center flex-col">
//               <img src={qi} alt="q1" />
//               <br />
//               İhtiyacını Birkaç Soruda Anlat
//             </div>
//             <br />
//             <img width="100%" src={q1} alt="q1" />
//             <br />
//             Sadece ilanını bırak gerisini biz halledelim
//           </div>
//           <div className="sy-box">
//             <div className="flex items-center justify-center flex-col">
//               <img src={qi} alt="q1" />
//               <br />
//               Özel Fiyat teklifleri Al
//             </div>
//             <br />
//             <img width="100%" src={q2} alt="q1" />
//             <br />
//               Gelen teklifleri değerlendir
//           </div>
//           <div className="sy-box">
//             <div className="flex items-center justify-center flex-col">
//               <img src={qi} alt="q1" />
//               <br />
//               Karşılaştır ve Seç
//             </div>
//             <br />
//             <img className="w-[100%]" src={q3} alt="q1" />
//             <br />
//               Senin için en uygun teklifi onayla
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

const HowItWorks = () => {
  const CardData = [
    {
      title: "İhtiyacını Birkaç Soruda Anlat",
      text: "Sadece ilanını bırak gerisini biz halledelim",
      illustration: Image1,
    },
    {
      title: "Özel Fiyat Teklifleri Al",
      text: "Gelen teklifleri değerlendir",
      illustration: Image2,
    },
    {
      title: "Karşılaştır ve Seç",
      text: "Senin için en uygun teklifi onayla",
      illustration: Image3,
    },
  ];

  return (
    <div className={style.wrapper}>
      <TitleSubtitle
        title={"Sistem Nasıl Çalışıyor?"}
        subTitle={
          "İhtiyaçlarını birkaç basit soruyla anlat. Sana özel gelen fiyat tekliflerini değerlendir, en uygun olanını seç ve gerisini bize bırak. Zahmetsizce en iyi hizmeti almanın keyfini çıkar."
        }
      />

      <div className={style.cardWrapper}>
        {CardData.map((item) => {
          return (
            <div className={style.card}>
              <img src={item.illustration} />
              <h5>{item.title}</h5>
              <p>{item.text}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HowItWorks;
