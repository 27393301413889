import truck from "../../assets/images/icon/truck.png";
import box from "../../assets/images/icon/box.png";
import boxes from "../../assets/images/icon/boxes.png";
import short from "../../assets/images/icon/short.png";

import TitleSubtitle from "../HomeElement/TitleSubtitle/TitleSubtitle";
import style from "./servicecategories.module.scss";

const ServiceCategories = () => {
  const ServiceData = [
    {
      title: "Evden Eve Nakliye",
      image: truck,
      color: "#F59A13",
    },
    {
      title: "Tekli Ürün Nakliye",
      image: box,
      color: "#306FDF",
    },
    {
      title: "Ofis Nakliye",
      image: boxes,
      color: "#24C4BB",
    },
    {
      title: "Kısa Mesafeli Nakliye",
      image: short,
      color: "#383E42",
    },
  ];

  return (
    <div className={style.wrapper}>
      <TitleSubtitle
        title={"Hizmet Kategorileri"}
        subTitle={
          "Güvenilir ve deneyimli nakliyat firmalarını sizin için bulan borvey, doğrudan tedarikçi olmayıp, sadece taşıma hizmeti almak isteyenlerle nakliye sektörü oyuncularını bir araya getiren paylaşım platformudur."
        }
      />

      <div className={style.cardWrapper}>
        {ServiceData.map((item) => {
          return (
            <a href="/kayit-ol" className={style.card}>
              <img src={item.image} />
              <div
                style={{
                  background: `linear-gradient(180deg, rgba(255,255,255,0) 0%, ${item.color} 100%)`,
                }}
                className={style.cover}
              >
                <p>{item.title}</p>
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default ServiceCategories;
