import React, { useEffect, useState } from "react";
import profile from "../../assets/images/profile2.png";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../../partials/Loader";
import { Link, useNavigate } from "react-router-dom";
import TitleSubtitle from "../HomeElement/TitleSubtitle/TitleSubtitle";
import style from "./latestcompany.module.scss";
import Slider from "react-slick";
import CompanyLogo from "../../assets/images/company-logo.png";
import { QuoteIcon } from "../../assets/icon";

// const LatestCompany = () => {
//   const {userCargoListData: data, userCargoListLoading: loading} = useSelector((state) => state.users);
//   const dispatch = useDispatch();
//   useEffect(() => {
//      dispatch(userCargoList());
//   }, [dispatch])

//   if (loading)
//     return (
//       <div>
//         <Loading />
//       </div>
//     );
//   return (
//     <div className="relative">
//       <div className="container">
//         <div className="sup-header">
//           <div className="home-title flex-j">Öne Çıkan Firmalar</div>
//         </div>
//         <div className="features-link">
//           <a className="active" href="#">
//             Tümü
//           </a>
//           <a href="#">En Çok Değerlendirilenler</a>
//         </div>
//         <div className="s-card-container">
//           {data?.slice(0,6).map((item, index) => {
//             return (
//               <Link to={`/nakliye/${item?.user?._id}`} className="s-card">
//                 <div className="s-card-img">
//                   {item?.user?.image ? (
//                   <img src={item?.user?.image} alt="profile" />
//                   ) : (
//                   <img src={profile} alt="profile" />
//                   )}
//                 </div>
//                 <div className="">
//                   <div className="s-card-title">{item?.user?.cargoName}</div>
//                   <div className="s-card-cap">{item?.user?.fullName}</div>
//                   <div className="s-card-info">

//                     <div className="s-card-info-star">
//                     <i className="text-[#FF9A00] fa-solid fa-star"></i>
//                     {item?.averageRating} ({item?.numberOfComments})
//                     </div>
//                   </div>
//                 </div>
//               </Link>
//             );
//           })}
//         </div>
//       </div>
//       <div className="see-all-container">
//         <a href="/nakliyeciler" className="primary-btn-st">
//           Tümünü Gör <i className="fa-solid fa-chevron-right"></i>
//         </a>
//       </div>
//     </div>
//   );
// };

const LatestCompany = ({ data }) => {
  const navigate = useNavigate();
  const [slideStatus, setSlideStatus] = useState(0);

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    centerMode: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    afterChange: (current) => setSlideStatus(current),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  console.log("data", data);

  return (
    <div className={style.wrapper}>
      <span>
        <TitleSubtitle
          title={"Öne Çıkan Firmalar"}
          subTitle={
            "Üyelerimizin kusursuz hizmet alıp memnuniyet yaşadığı nakliyecileri inceleyip yorum bırakabilirsiniz"
          }
        />
      </span>

      <div className={style.sliderWrapper}>
        <Slider {...settings}>
          {[...data, ...data].map((item, index) => {
            return (
              <div
                onClick={() => {
                  window.location.href = "/kayit-ol";
                }}
                className={`${style.card} ${
                  index === slideStatus ? style.active : null
                }`}
              >
                <QuoteIcon />
                <div className={style.content}>
                  <div className={style.title}>
                    <img src={item.firm.logo ? item.firm.logo : CompanyLogo} />
                    <h5>{item.firm.name}</h5>
                  </div>
                  <p>
                    {item.firm.description.length > 100
                      ? `${item.firm.description.slice(0, 100)}...`
                      : item.firm.description}
                  </p>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default LatestCompany;
