import React, { useEffect } from "react";

import TitleSubtitle from "../HomeElement/TitleSubtitle/TitleSubtitle";
import style from "./featuredadverts.module.scss";
import { SeeAllIcon } from "../../assets/icon";
import { TypeText } from "../../utils/TypeText";

const FeaturedAdverts = ({ data }) => {
  return (
    <div className={style.wrapper}>
      <TitleSubtitle
        title={"En Son Eklenen İlanlar"}
        subTitle={
          "borvey ile en güncel ilanlar ve taşıma taleplerini inceleyip teklif verebilirsiniz."
        }
      />

      <div className={style.cardWrapper}>
        {data.map((item, index) => {
          return (
            <div className={style.card}>
              <div className={style.textWrapper}>
                <h5>{item.title}</h5>
                <h5
                  style={{
                    color: "#f59a13",
                  }}
                >
                  {TypeText(item.type)}
                </h5>
                <p>{item.additionalInfo}</p>
              </div>
              <img
                src={
                  index === 0
                    ? `https://images.pexels.com/photos/4554243/pexels-photo-4554243.jpeg`
                    : index === 1
                    ? `https://plus.unsplash.com/premium_photo-1681487834818-369c5e07675a?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D`
                    : `https://images.pexels.com/photos/4568693/pexels-photo-4568693.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1`
                }
              />
            </div>
          );
        })}
      </div>

      <a className={style.seeAll} href="/kayit-ol">
        <span>Tümünü Gör</span> <SeeAllIcon />
      </a>
    </div>
  );
};

export default FeaturedAdverts;
