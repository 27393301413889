import style from "./twocard.module.scss";

const TwoCard = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.cardWrapper}>
        <div className={`${style.card} ${style.primary}`}>
          <div className={style.textWrapper}>
            <h5>Kolay ve Hesaplı…</h5>
            <p>
              Son kullanıcıya hizmete ulaşmada kolaylık sunan borvey, taşıma
              hizmeti alan müşteriye planlama yapma ya da hizmet almak istediği
              takvim ve güzergahta uygun hizmet verenlerle bir araya gelme
              olanağı tanır
            </p>
          </div>
          <a href="/kayit-ol">Hizmet Al</a>
        </div>
        <div className={`${style.card} ${style.secondary}`}>
          <div className={style.textWrapper}>
            <h5>Aracın boşta beklemesin</h5>
            <p>
              Müşteri ya da yük için günlerce garajlarda beklemeye, boş ya da
              yarım yükle masraflı seferlere son. borvey'e gel, uygun güzergahta
              uygun yük bulalım, zaman ve akaryakıt harcama
            </p>
          </div>
          <a href="/kayit-ol?type=service">Hizmet Ver</a>
        </div>
      </div>
    </div>
  );
};

export default TwoCard;
