import logo from "../../../assets/images/logo.png";
import style from "./topbar.module.scss";

const TopBar = ({ isActive, selectedType, currentStep }) => {
  const userData = [
    {
      title: "Başlamadan Önce",
    },
    {
      title: "Hitap",
    },
    {
      title: "Servisler",
    },
    {
      title: "Nakliye Büyüklüğü",
    },

    {
      title: "Adres",
    },
    {
      title: "Nakliye Detay",
    },
    {
      title: "Profilini Tamamla!",
    },
  ];

  const serviceData = [
    {
      title: "Başlamadan Önce",
    },
    {
      title: "Hitap",
    },
    {
      title: "Firma Bilgileri",
    },

    {
      title: "Profilini Tamamla!",
    },
  ];

  return (
    <div
      className={`${style.topbar} ${
        selectedType === "service" ? style.service : null
      }`}
    >
      <a className={style.logo} href="/">
        <img src={logo} />
      </a>
      <div className={style.stepperWrapper}>
        {[selectedType === "service" ? serviceData : userData]
          .flat()
          .map((item, index) => {
            return (
              <div
                className={`${style.stepper} ${
                  index === currentStep ? style.active : null
                } ${index < currentStep ? style.complete : null}`}
              >
                <div className={style.stepBar} />
                <h6>{item.title}</h6>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default TopBar;
