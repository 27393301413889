export const CityData = [
  {
    il_adi: "Adana",
    plaka_kodu: " 01",
    alan_kodu: "0322",
    nufus: "2.274.106",
    bolge: "Akdeniz",
    yuzolcumu: "13.844",
    nufus_artisi: "4,7%",
    erkek_nufus_yuzde: "49.15%",
    erkek_nufus: "1.106.811",
    kadin_nufus_yuzde: "50.15%",
    kadin_nufus: "1.113.314",
    nufus_yuzdesi_genel: "2,71%",
    ilceler: [
      {
        plaka_kodu: "1",
        ilce_kodu: "1757",
        il_adi: "ADANA",
        ilce_adi: "ALADAĞ",
        nufus: "15.855",
        erkek_nufus: "8.194",
        kadin_nufus: "7.661",
      },
      {
        plaka_kodu: "1",
        ilce_kodu: "1219",
        il_adi: "ADANA",
        ilce_adi: "CEYHAN",
        nufus: "159.955",
        erkek_nufus: "80.523",
        kadin_nufus: "79.432",
      },
      {
        plaka_kodu: "1",
        ilce_kodu: "2033",
        il_adi: "ADANA",
        ilce_adi: "ÇUKUROVA",
        nufus: "389.319",
        erkek_nufus: "187.172",
        kadin_nufus: "202.147",
      },
      {
        plaka_kodu: "1",
        ilce_kodu: "1329",
        il_adi: "ADANA",
        ilce_adi: "FEKE",
        nufus: "16.072",
        erkek_nufus: "8.395",
        kadin_nufus: "7.677",
      },
      {
        plaka_kodu: "1",
        ilce_kodu: "1806",
        il_adi: "ADANA",
        ilce_adi: "İMAMOĞLU",
        nufus: "27.399",
        erkek_nufus: "13.867",
        kadin_nufus: "13.532",
      },
      {
        plaka_kodu: "1",
        ilce_kodu: "1437",
        il_adi: "ADANA",
        ilce_adi: "KARAİSALI",
        nufus: "21.967",
        erkek_nufus: "11.275",
        kadin_nufus: "10.692",
      },
      {
        plaka_kodu: "1",
        ilce_kodu: "1443",
        il_adi: "ADANA",
        ilce_adi: "KARATAŞ",
        nufus: "23.321",
        erkek_nufus: "11.940",
        kadin_nufus: "11.381",
      },
      {
        plaka_kodu: "1",
        ilce_kodu: "1486",
        il_adi: "ADANA",
        ilce_adi: "KOZAN",
        nufus: "132.320",
        erkek_nufus: "66.723",
        kadin_nufus: "65.597",
      },
      {
        plaka_kodu: "1",
        ilce_kodu: "1580",
        il_adi: "ADANA",
        ilce_adi: "POZANTI",
        nufus: "20.005",
        erkek_nufus: "10.410",
        kadin_nufus: "9.595",
      },
      {
        plaka_kodu: "1",
        ilce_kodu: "1588",
        il_adi: "ADANA",
        ilce_adi: "SAİMBEYLİ",
        nufus: "14.030",
        erkek_nufus: "7.384",
        kadin_nufus: "6.646",
      },
      {
        plaka_kodu: "1",
        ilce_kodu: "2032",
        il_adi: "ADANA",
        ilce_adi: "SARIÇAM",
        nufus: "208.227",
        erkek_nufus: "106.664",
        kadin_nufus: "101.563",
      },
      {
        plaka_kodu: "1",
        ilce_kodu: "1104",
        il_adi: "ADANA",
        ilce_adi: "SEYHAN",
        nufus: "792.536",
        erkek_nufus: "394.736",
        kadin_nufus: "397.800",
      },
      {
        plaka_kodu: "1",
        ilce_kodu: "1687",
        il_adi: "ADANA",
        ilce_adi: "TUFANBEYLİ",
        nufus: "16.660",
        erkek_nufus: "8.640",
        kadin_nufus: "8.020",
      },
      {
        plaka_kodu: "1",
        ilce_kodu: "1734",
        il_adi: "ADANA",
        ilce_adi: "YUMURTALIK",
        nufus: "18.653",
        erkek_nufus: "10.135",
        kadin_nufus: "8.518",
      },
      {
        plaka_kodu: "1",
        ilce_kodu: "1748",
        il_adi: "ADANA",
        ilce_adi: "YÜREĞİR",
        nufus: "407.054",
        erkek_nufus: "204.804",
        kadin_nufus: "202.250",
      },
    ],
    kisa_bilgi:
      "Ülkenin en büyük şehirlerinden biri olan Adana, Toros dağlarının güneyinde bulunan ve Mersin, Osmaniye, Hatay illerini içine alan Çukurova'nın merkezinde, Seyhan nehri üzerinde yer alır",
  },
  {
    il_adi: "Adıyaman",
    plaka_kodu: "02",
    alan_kodu: "416",
    nufus: "635.169",
    bolge: "Güneydoğu Anadolu",
    yuzolcumu: "7.337",
    nufus_artisi: "4,8%",
    erkek_nufus_yuzde: "50,44%",
    erkek_nufus: "314.998",
    kadin_nufus_yuzde: "49,56%",
    kadin_nufus: "309.515",
    nufus_yuzdesi_genel: "0,76%",
    ilceler: [
      {
        plaka_kodu: "2",
        ilce_kodu: "1105",
        il_adi: "ADIYAMAN",
        ilce_adi: "MERKEZ",
        nufus: "312.207",
        erkek_nufus: "156.565",
        kadin_nufus: "155.642",
      },
      {
        plaka_kodu: "2",
        ilce_kodu: "1182",
        il_adi: "ADIYAMAN",
        ilce_adi: "BESNİ",
        nufus: "77.207",
        erkek_nufus: "38.970",
        kadin_nufus: "38.237",
      },
      {
        plaka_kodu: "2",
        ilce_kodu: "1246",
        il_adi: "ADIYAMAN",
        ilce_adi: "ÇELİKHAN",
        nufus: "15.294",
        erkek_nufus: "7.844",
        kadin_nufus: "7.450",
      },
      {
        plaka_kodu: "2",
        ilce_kodu: "1347",
        il_adi: "ADIYAMAN",
        ilce_adi: "GERGER",
        nufus: "16.416",
        erkek_nufus: "8.221",
        kadin_nufus: "8.195",
      },
      {
        plaka_kodu: "2",
        ilce_kodu: "1354",
        il_adi: "ADIYAMAN",
        ilce_adi: "GÖLBAŞI",
        nufus: "50.150",
        erkek_nufus: "25.218",
        kadin_nufus: "24.932",
      },
      {
        plaka_kodu: "2",
        ilce_kodu: "1425",
        il_adi: "ADIYAMAN",
        ilce_adi: "KAHTA",
        nufus: "127.534",
        erkek_nufus: "64.913",
        kadin_nufus: "62.621",
      },
      {
        plaka_kodu: "2",
        ilce_kodu: "1592",
        il_adi: "ADIYAMAN",
        ilce_adi: "SAMSAT",
        nufus: "7.313",
        erkek_nufus: "3.769",
        kadin_nufus: "3.544",
      },
      {
        plaka_kodu: "2",
        ilce_kodu: "1985",
        il_adi: "ADIYAMAN",
        ilce_adi: "SİNCİK",
        nufus: "16.341",
        erkek_nufus: "8.139",
        kadin_nufus: "8.202",
      },
      {
        plaka_kodu: "2",
        ilce_kodu: "1989",
        il_adi: "ADIYAMAN",
        ilce_adi: "TUT",
        nufus: "9.686",
        erkek_nufus: "4.980",
        kadin_nufus: "4.706",
      },
    ],
    kisa_bilgi:
      "Güneydoğu Anadolu Bölgesinin Batısında Akdeniz Bölgesiyle birleşen noktada yer alan Adıyaman, 16 farklı medeniyete ev sahipliği yapmış, tarihin bilinen en eski yerleşim yerlerinden biri.",
  },
  {
    il_adi: "Afyonkarahisar",
    plaka_kodu: "03",
    alan_kodu: "272",
    nufus: "747.555",
    bolge: "Ege",
    yuzolcumu: "14.016",
    nufus_artisi: "4,5%",
    erkek_nufus_yuzde: "49,64%",
    erkek_nufus: "360.152",
    kadin_nufus_yuzde: "50,36%",
    kadin_nufus: "365.416",
    nufus_yuzdesi_genel: "0,88%",
    ilceler: [
      {
        plaka_kodu: "3",
        ilce_kodu: "1108",
        il_adi: "AFYONKARAHİSAR",
        ilce_adi: "MERKEZ",
        nufus: "319.574",
        erkek_nufus: "159.747",
        kadin_nufus: "159.827",
      },
      {
        plaka_kodu: "3",
        ilce_kodu: "1771",
        il_adi: "AFYONKARAHİSAR",
        ilce_adi: "BAŞMAKÇI",
        nufus: "9.536",
        erkek_nufus: "4.744",
        kadin_nufus: "4.792",
      },
      {
        plaka_kodu: "3",
        ilce_kodu: "1773",
        il_adi: "AFYONKARAHİSAR",
        ilce_adi: "BAYAT",
        nufus: "7.687",
        erkek_nufus: "3.784",
        kadin_nufus: "3.903",
      },
      {
        plaka_kodu: "3",
        ilce_kodu: "1200",
        il_adi: "AFYONKARAHİSAR",
        ilce_adi: "BOLVADİN",
        nufus: "45.944",
        erkek_nufus: "23.656",
        kadin_nufus: "22.288",
      },
      {
        plaka_kodu: "3",
        ilce_kodu: "1239",
        il_adi: "AFYONKARAHİSAR",
        ilce_adi: "ÇAY",
        nufus: "30.777",
        erkek_nufus: "15.085",
        kadin_nufus: "15.692",
      },
      {
        plaka_kodu: "3",
        ilce_kodu: "1906",
        il_adi: "AFYONKARAHİSAR",
        ilce_adi: "ÇOBANLAR",
        nufus: "14.131",
        erkek_nufus: "7.095",
        kadin_nufus: "7.036",
      },
      {
        plaka_kodu: "3",
        ilce_kodu: "1267",
        il_adi: "AFYONKARAHİSAR",
        ilce_adi: "DAZKIRI",
        nufus: "11.364",
        erkek_nufus: "5.703",
        kadin_nufus: "5.661",
      },
      {
        plaka_kodu: "3",
        ilce_kodu: "1281",
        il_adi: "AFYONKARAHİSAR",
        ilce_adi: "DİNAR",
        nufus: "47.378",
        erkek_nufus: "23.598",
        kadin_nufus: "23.780",
      },
      {
        plaka_kodu: "3",
        ilce_kodu: "1306",
        il_adi: "AFYONKARAHİSAR",
        ilce_adi: "EMİRDAĞ",
        nufus: "42.327",
        erkek_nufus: "21.384",
        kadin_nufus: "20.943",
      },
      {
        plaka_kodu: "3",
        ilce_kodu: "1923",
        il_adi: "AFYONKARAHİSAR",
        ilce_adi: "EVCİLER",
        nufus: "7.183",
        erkek_nufus: "3.604",
        kadin_nufus: "3.579",
      },
      {
        plaka_kodu: "3",
        ilce_kodu: "1944",
        il_adi: "AFYONKARAHİSAR",
        ilce_adi: "HOCALAR",
        nufus: "9.035",
        erkek_nufus: "4.514",
        kadin_nufus: "4.521",
      },
      {
        plaka_kodu: "3",
        ilce_kodu: "1404",
        il_adi: "AFYONKARAHİSAR",
        ilce_adi: "İHSANİYE",
        nufus: "27.455",
        erkek_nufus: "13.799",
        kadin_nufus: "13.656",
      },
      {
        plaka_kodu: "3",
        ilce_kodu: "1809",
        il_adi: "AFYONKARAHİSAR",
        ilce_adi: "İSCEHİSAR",
        nufus: "24.999",
        erkek_nufus: "12.614",
        kadin_nufus: "12.385",
      },
      {
        plaka_kodu: "3",
        ilce_kodu: "1961",
        il_adi: "AFYONKARAHİSAR",
        ilce_adi: "KIZILÖREN",
        nufus: "2.161",
        erkek_nufus: "1.014",
        kadin_nufus: "1.147",
      },
      {
        plaka_kodu: "3",
        ilce_kodu: "1594",
        il_adi: "AFYONKARAHİSAR",
        ilce_adi: "SANDIKLI",
        nufus: "55.210",
        erkek_nufus: "26.799",
        kadin_nufus: "28.411",
      },
      {
        plaka_kodu: "3",
        ilce_kodu: "1626",
        il_adi: "AFYONKARAHİSAR",
        ilce_adi: "SİNANPAŞA",
        nufus: "38.830",
        erkek_nufus: "19.332",
        kadin_nufus: "19.498",
      },
      {
        plaka_kodu: "3",
        ilce_kodu: "1639",
        il_adi: "AFYONKARAHİSAR",
        ilce_adi: "SULTANDAĞI",
        nufus: "14.279",
        erkek_nufus: "6.896",
        kadin_nufus: "7.383",
      },
      {
        plaka_kodu: "3",
        ilce_kodu: "1664",
        il_adi: "AFYONKARAHİSAR",
        ilce_adi: "ŞUHUT",
        nufus: "36.309",
        erkek_nufus: "18.053",
        kadin_nufus: "18.256",
      },
    ],
    kisa_bilgi:
      "Afyonkarahisar ın büyük bir bölümü Ege Bölgesinin iç batı olarak adlandırılan kesiminde yer alır. Türkiye'de kaplıca ve ılıca yönünden en önemli şehirlerden biri olan Afyonkarahisar'da, dört tane termal turizm merkezi var.",
  },
  {
    il_adi: "Ağrı",
    plaka_kodu: "04",
    alan_kodu: "472",
    nufus: "510.626",
    bolge: "Doğu Anadolu",
    yuzolcumu: "11.099",
    nufus_artisi: "-27,1%",
    erkek_nufus_yuzde: "52,06%",
    erkek_nufus: "280.923",
    kadin_nufus_yuzde: "47,94%",
    kadin_nufus: "258.734",
    nufus_yuzdesi_genel: "0,66%",
    ilceler: [
      {
        plaka_kodu: "4",
        ilce_kodu: "1111",
        il_adi: "AĞRI",
        ilce_adi: "MERKEZ",
        nufus: "150.335",
        erkek_nufus: "76.558",
        kadin_nufus: "73.777",
      },
      {
        plaka_kodu: "4",
        ilce_kodu: "1283",
        il_adi: "AĞRI",
        ilce_adi: "DİYADİN",
        nufus: "40.286",
        erkek_nufus: "20.915",
        kadin_nufus: "19.371",
      },
      {
        plaka_kodu: "4",
        ilce_kodu: "1287",
        il_adi: "AĞRI",
        ilce_adi: "DOĞUBAYAZIT",
        nufus: "118.643",
        erkek_nufus: "62.389",
        kadin_nufus: "56.254",
      },
      {
        plaka_kodu: "4",
        ilce_kodu: "1301",
        il_adi: "AĞRI",
        ilce_adi: "ELEŞKİRT",
        nufus: "31.545",
        erkek_nufus: "16.110",
        kadin_nufus: "15.435",
      },
      {
        plaka_kodu: "4",
        ilce_kodu: "1379",
        il_adi: "AĞRI",
        ilce_adi: "HAMUR",
        nufus: "17.106",
        erkek_nufus: "8.919",
        kadin_nufus: "8.187",
      },
      {
        plaka_kodu: "4",
        ilce_kodu: "1568",
        il_adi: "AĞRI",
        ilce_adi: "PATNOS",
        nufus: "118.481",
        erkek_nufus: "62.317",
        kadin_nufus: "56.164",
      },
      {
        plaka_kodu: "4",
        ilce_kodu: "1667",
        il_adi: "AĞRI",
        ilce_adi: "TAŞLIÇAY",
        nufus: "19.321",
        erkek_nufus: "9.859",
        kadin_nufus: "9.462",
      },
      {
        plaka_kodu: "4",
        ilce_kodu: "1691",
        il_adi: "AĞRI",
        ilce_adi: "TUTAK",
        nufus: "28.927",
        erkek_nufus: "14.822",
        kadin_nufus: "14.105",
      },
    ],
    kisa_bilgi:
      "Ağrı, Türkiye'nin Doğu Anadolu Bölgesi'nde bulunan bir ildir. Adını kısmen sınırları içerisinde bulunan Ağrı Dağı'ndan almıştır.",
  },
  {
    il_adi: "Amasya",
    plaka_kodu: "05",
    alan_kodu: "358",
    nufus: "338.267",
    bolge: "Karadeniz",
    yuzolcumu: "5.628",
    nufus_artisi: "8,7%",
    erkek_nufus_yuzde: "50,35%",
    erkek_nufus: "169.920",
    kadin_nufus_yuzde: "49,65%",
    kadin_nufus: "167.588",
    nufus_yuzdesi_genel: "0,41%",
    ilceler: [
      {
        plaka_kodu: "5",
        ilce_kodu: "1134",
        il_adi: "AMASYA",
        ilce_adi: "MERKEZ",
        nufus: "147.380",
        erkek_nufus: "73.745",
        kadin_nufus: "73.635",
      },
      {
        plaka_kodu: "5",
        ilce_kodu: "1363",
        il_adi: "AMASYA",
        ilce_adi: "GÖYNÜCEK",
        nufus: "10.291",
        erkek_nufus: "5.173",
        kadin_nufus: "5.118",
      },
      {
        plaka_kodu: "5",
        ilce_kodu: "1368",
        il_adi: "AMASYA",
        ilce_adi: "GÜMÜŞHACIKÖY",
        nufus: "22.179",
        erkek_nufus: "11.022",
        kadin_nufus: "11.157",
      },
      {
        plaka_kodu: "5",
        ilce_kodu: "1938",
        il_adi: "AMASYA",
        ilce_adi: "HAMAMÖZÜ",
        nufus: "3.565",
        erkek_nufus: "1.747",
        kadin_nufus: "1.818",
      },
      {
        plaka_kodu: "5",
        ilce_kodu: "1524",
        il_adi: "AMASYA",
        ilce_adi: "MERZİFON",
        nufus: "74.727",
        erkek_nufus: "37.192",
        kadin_nufus: "37.535",
      },
      {
        plaka_kodu: "5",
        ilce_kodu: "1641",
        il_adi: "AMASYA",
        ilce_adi: "SULUOVA",
        nufus: "47.066",
        erkek_nufus: "23.406",
        kadin_nufus: "23.660",
      },
      {
        plaka_kodu: "5",
        ilce_kodu: "1668",
        il_adi: "AMASYA",
        ilce_adi: "TAŞOVA",
        nufus: "30.123",
        erkek_nufus: "14.748",
        kadin_nufus: "15.375",
      },
    ],
    kisa_bilgi:
      "7500 yıllık bir geçmişe sahip, ülkemizin önemli kültür, turizm ve tarih merkezlerinden biri olan şehir, bilindiği gibi misket elmasıyla meşhur. Sert, küçük ve tatlı olan, kendine has bir koku ve lezzete sahip bu elma oldukça dayanıklıdır.",
  },
  {
    il_adi: "Ankara",
    plaka_kodu: "06",
    alan_kodu: "312",
    nufus: "5.782.285",
    bolge: "İç Anadolu",
    yuzolcumu: "25.632",
    nufus_artisi: "6,1%",
    erkek_nufus_yuzde: "49,58%",
    erkek_nufus: "2.728.900",
    kadin_nufus_yuzde: "50,42%",
    kadin_nufus: "2.775.085",
    nufus_yuzdesi_genel: "6,71%",
    ilceler: [
      {
        plaka_kodu: "6",
        ilce_kodu: "1872",
        il_adi: "ANKARA",
        ilce_adi: "AKYURT",
        nufus: "38.588",
        erkek_nufus: "19.711",
        kadin_nufus: "18.877",
      },
      {
        plaka_kodu: "6",
        ilce_kodu: "1130",
        il_adi: "ANKARA",
        ilce_adi: "ALTINDAĞ",
        nufus: "407.675",
        erkek_nufus: "204.907",
        kadin_nufus: "202.768",
      },
      {
        plaka_kodu: "6",
        ilce_kodu: "1157",
        il_adi: "ANKARA",
        ilce_adi: "AYAŞ",
        nufus: "13.093",
        erkek_nufus: "7.032",
        kadin_nufus: "6.061",
      },
      {
        plaka_kodu: "6",
        ilce_kodu: "1167",
        il_adi: "ANKARA",
        ilce_adi: "BALA",
        nufus: "22.966",
        erkek_nufus: "12.602",
        kadin_nufus: "10.364",
      },
      {
        plaka_kodu: "6",
        ilce_kodu: "1187",
        il_adi: "ANKARA",
        ilce_adi: "BEYPAZARI",
        nufus: "48.393",
        erkek_nufus: "24.119",
        kadin_nufus: "24.274",
      },
      {
        plaka_kodu: "6",
        ilce_kodu: "1227",
        il_adi: "ANKARA",
        ilce_adi: "ÇAMLIDERE",
        nufus: "8.350",
        erkek_nufus: "4.421",
        kadin_nufus: "3.929",
      },
      {
        plaka_kodu: "6",
        ilce_kodu: "1231",
        il_adi: "ANKARA",
        ilce_adi: "ÇANKAYA",
        nufus: "949.265",
        erkek_nufus: "454.974",
        kadin_nufus: "494.291",
      },
      {
        plaka_kodu: "6",
        ilce_kodu: "1260",
        il_adi: "ANKARA",
        ilce_adi: "ÇUBUK",
        nufus: "91.363",
        erkek_nufus: "45.626",
        kadin_nufus: "45.737",
      },
      {
        plaka_kodu: "6",
        ilce_kodu: "1302",
        il_adi: "ANKARA",
        ilce_adi: "ELMADAĞ",
        nufus: "44.236",
        erkek_nufus: "22.223",
        kadin_nufus: "22.013",
      },
      {
        plaka_kodu: "6",
        ilce_kodu: "1922",
        il_adi: "ANKARA",
        ilce_adi: "ETİMESGUT",
        nufus: "606.472",
        erkek_nufus: "302.537",
        kadin_nufus: "303.935",
      },
      {
        plaka_kodu: "6",
        ilce_kodu: "1924",
        il_adi: "ANKARA",
        ilce_adi: "EVREN",
        nufus: "2.965",
        erkek_nufus: "1.464",
        kadin_nufus: "1.501",
      },
      {
        plaka_kodu: "6",
        ilce_kodu: "1744",
        il_adi: "ANKARA",
        ilce_adi: "GÖLBAŞI",
        nufus: "142.961",
        erkek_nufus: "71.505",
        kadin_nufus: "71.456",
      },
      {
        plaka_kodu: "6",
        ilce_kodu: "1365",
        il_adi: "ANKARA",
        ilce_adi: "GÜDÜL",
        nufus: "8.155",
        erkek_nufus: "4.107",
        kadin_nufus: "4.048",
      },
      {
        plaka_kodu: "6",
        ilce_kodu: "1387",
        il_adi: "ANKARA",
        ilce_adi: "HAYMANA",
        nufus: "27.298",
        erkek_nufus: "14.228",
        kadin_nufus: "13.070",
      },
      {
        plaka_kodu: "6",
        ilce_kodu: "1815",
        il_adi: "ANKARA",
        ilce_adi: "KAHRAMANKAZAN",
        nufus: "57.913",
        erkek_nufus: "29.658",
        kadin_nufus: "28.255",
      },
      {
        plaka_kodu: "6",
        ilce_kodu: "1427",
        il_adi: "ANKARA",
        ilce_adi: "KALECİK",
        nufus: "12.502",
        erkek_nufus: "6.455",
        kadin_nufus: "6.047",
      },
      {
        plaka_kodu: "6",
        ilce_kodu: "1745",
        il_adi: "ANKARA",
        ilce_adi: "KEÇİÖREN",
        nufus: "942.884",
        erkek_nufus: "461.863",
        kadin_nufus: "481.021",
      },
      {
        plaka_kodu: "6",
        ilce_kodu: "1473",
        il_adi: "ANKARA",
        ilce_adi: "KIZILCAHAMAM",
        nufus: "26.968",
        erkek_nufus: "13.745",
        kadin_nufus: "13.223",
      },
      {
        plaka_kodu: "6",
        ilce_kodu: "1746",
        il_adi: "ANKARA",
        ilce_adi: "MAMAK",
        nufus: "682.420",
        erkek_nufus: "340.018",
        kadin_nufus: "342.402",
      },
      {
        plaka_kodu: "6",
        ilce_kodu: "1539",
        il_adi: "ANKARA",
        ilce_adi: "NALLIHAN",
        nufus: "26.961",
        erkek_nufus: "13.422",
        kadin_nufus: "13.539",
      },
      {
        plaka_kodu: "6",
        ilce_kodu: "1578",
        il_adi: "ANKARA",
        ilce_adi: "POLATLI",
        nufus: "127.526",
        erkek_nufus: "63.738",
        kadin_nufus: "63.788",
      },
      {
        plaka_kodu: "6",
        ilce_kodu: "2034",
        il_adi: "ANKARA",
        ilce_adi: "PURSAKLAR",
        nufus: "159.676",
        erkek_nufus: "79.639",
        kadin_nufus: "80.037",
      },
      {
        plaka_kodu: "6",
        ilce_kodu: "1747",
        il_adi: "ANKARA",
        ilce_adi: "SİNCAN",
        nufus: "561.411",
        erkek_nufus: "286.283",
        kadin_nufus: "275.128",
      },
      {
        plaka_kodu: "6",
        ilce_kodu: "1658",
        il_adi: "ANKARA",
        ilce_adi: "ŞEREFLİKOÇHİSAR",
        nufus: "33.475",
        erkek_nufus: "16.799",
        kadin_nufus: "16.676",
      },
      {
        plaka_kodu: "6",
        ilce_kodu: "1723",
        il_adi: "ANKARA",
        ilce_adi: "YENİMAHALLE",
        nufus: "703.809",
        erkek_nufus: "342.333",
        kadin_nufus: "361.476",
      },
    ],
    kisa_bilgi:
      "Bolu, Çankırı, Kırıkkale, Kırşehir, Aksaray, Konya ve Eskişehir illeri ile çevrili ve Türkiye'nin başkenti Ankara, Cumhuriyetin kuruluşundan bu yana hızla gelişme göstermiştir. Şehrin amblemi, Hitit uygarlığının da simgesi olan Hitit Güneş Kursu'dur.",
  },
  {
    il_adi: "Antalya",
    plaka_kodu: "07",
    alan_kodu: "242",
    nufus: "2.688.004",
    bolge: "Akdeniz",
    yuzolcumu: "20.177",
    nufus_artisi: "25,7%",
    erkek_nufus_yuzde: "50,37%",
    erkek_nufus: "1.222.086",
    kadin_nufus_yuzde: "49,63%",
    kadin_nufus: "1.204.270",
    nufus_yuzdesi_genel: "2,96%",
    ilceler: [
      {
        plaka_kodu: "7",
        ilce_kodu: "1121",
        il_adi: "ANTALYA",
        ilce_adi: "AKSEKİ",
        nufus: "10.575",
        erkek_nufus: "5.338",
        kadin_nufus: "5.237",
      },
      {
        plaka_kodu: "7",
        ilce_kodu: "2035",
        il_adi: "ANTALYA",
        ilce_adi: "AKSU",
        nufus: "75.633",
        erkek_nufus: "38.689",
        kadin_nufus: "36.944",
      },
      {
        plaka_kodu: "7",
        ilce_kodu: "1126",
        il_adi: "ANTALYA",
        ilce_adi: "ALANYA",
        nufus: "350.636",
        erkek_nufus: "177.093",
        kadin_nufus: "173.543",
      },
      {
        plaka_kodu: "7",
        ilce_kodu: "1811",
        il_adi: "ANTALYA",
        ilce_adi: "DEMRE",
        nufus: "27.150",
        erkek_nufus: "13.946",
        kadin_nufus: "13.204",
      },
      {
        plaka_kodu: "7",
        ilce_kodu: "2036",
        il_adi: "ANTALYA",
        ilce_adi: "DÖŞEMEALTI",
        nufus: "73.809",
        erkek_nufus: "37.710",
        kadin_nufus: "36.099",
      },
      {
        plaka_kodu: "7",
        ilce_kodu: "1303",
        il_adi: "ANTALYA",
        ilce_adi: "ELMALI",
        nufus: "40.614",
        erkek_nufus: "21.179",
        kadin_nufus: "19.435",
      },
      {
        plaka_kodu: "7",
        ilce_kodu: "1333",
        il_adi: "ANTALYA",
        ilce_adi: "FİNİKE",
        nufus: "49.407",
        erkek_nufus: "24.804",
        kadin_nufus: "24.603",
      },
      {
        plaka_kodu: "7",
        ilce_kodu: "1337",
        il_adi: "ANTALYA",
        ilce_adi: "GAZİPAŞA",
        nufus: "52.126",
        erkek_nufus: "26.454",
        kadin_nufus: "25.672",
      },
      {
        plaka_kodu: "7",
        ilce_kodu: "1370",
        il_adi: "ANTALYA",
        ilce_adi: "GÜNDOĞMUŞ",
        nufus: "7.170",
        erkek_nufus: "3.562",
        kadin_nufus: "3.608",
      },
      {
        plaka_kodu: "7",
        ilce_kodu: "1946",
        il_adi: "ANTALYA",
        ilce_adi: "İBRADI",
        nufus: "2.868",
        erkek_nufus: "1.464",
        kadin_nufus: "1.404",
      },
      {
        plaka_kodu: "7",
        ilce_kodu: "1451",
        il_adi: "ANTALYA",
        ilce_adi: "KAŞ",
        nufus: "61.488",
        erkek_nufus: "31.613",
        kadin_nufus: "29.875",
      },
      {
        plaka_kodu: "7",
        ilce_kodu: "1959",
        il_adi: "ANTALYA",
        ilce_adi: "KEMER",
        nufus: "46.615",
        erkek_nufus: "24.231",
        kadin_nufus: "22.384",
      },
      {
        plaka_kodu: "7",
        ilce_kodu: "2037",
        il_adi: "ANTALYA",
        ilce_adi: "KEPEZ",
        nufus: "591.895",
        erkek_nufus: "300.798",
        kadin_nufus: "291.097",
      },
      {
        plaka_kodu: "7",
        ilce_kodu: "2038",
        il_adi: "ANTALYA",
        ilce_adi: "KONYAALTI",
        nufus: "199.609",
        erkek_nufus: "95.179",
        kadin_nufus: "104.430",
      },
      {
        plaka_kodu: "7",
        ilce_kodu: "1483",
        il_adi: "ANTALYA",
        ilce_adi: "KORKUTELİ",
        nufus: "55.883",
        erkek_nufus: "28.046",
        kadin_nufus: "27.837",
      },
      {
        plaka_kodu: "7",
        ilce_kodu: "1492",
        il_adi: "ANTALYA",
        ilce_adi: "KUMLUCA",
        nufus: "72.478",
        erkek_nufus: "36.612",
        kadin_nufus: "35.866",
      },
      {
        plaka_kodu: "7",
        ilce_kodu: "1512",
        il_adi: "ANTALYA",
        ilce_adi: "MANAVGAT",
        nufus: "245.740",
        erkek_nufus: "125.368",
        kadin_nufus: "120.372",
      },
      {
        plaka_kodu: "7",
        ilce_kodu: "2039",
        il_adi: "ANTALYA",
        ilce_adi: "MURATPAŞA",
        nufus: "521.183",
        erkek_nufus: "253.955",
        kadin_nufus: "267.228",
      },
      {
        plaka_kodu: "7",
        ilce_kodu: "1616",
        il_adi: "ANTALYA",
        ilce_adi: "SERİK",
        nufus: "134.953",
        erkek_nufus: "68.714",
        kadin_nufus: "66.239",
      },
    ],
    kisa_bilgi:
      "Akdeniz bölgesinin batısında ve Antalya Körfezi ile Batı Toroslar arasında yer alan Antalya, özellikle turizmin etkisiyle hızla nüfus alan ve gelişen, en güzel şehirlerimizden biridir.",
  },
  {
    il_adi: "Artvin",
    plaka_kodu: "08",
    alan_kodu: "466",
    nufus: "169.403",
    bolge: "Karadeniz",
    yuzolcumu: "7.393",
    nufus_artisi: "-0,8%",
    erkek_nufus_yuzde: "50,41%",
    erkek_nufus: "87.715",
    kadin_nufus_yuzde: "49,59%",
    kadin_nufus: "86.295",
    nufus_yuzdesi_genel: "0,21%",
    ilceler: [
      {
        plaka_kodu: "8",
        ilce_kodu: "1152",
        il_adi: "ARTVİN",
        ilce_adi: "MERKEZ",
        nufus: "34.537",
        erkek_nufus: "17.763",
        kadin_nufus: "16.774",
      },
      {
        plaka_kodu: "8",
        ilce_kodu: "1145",
        il_adi: "ARTVİN",
        ilce_adi: "ARDANUÇ",
        nufus: "11.198",
        erkek_nufus: "5.760",
        kadin_nufus: "5.438",
      },
      {
        plaka_kodu: "8",
        ilce_kodu: "1147",
        il_adi: "ARTVİN",
        ilce_adi: "ARHAVİ",
        nufus: "21.661",
        erkek_nufus: "10.720",
        kadin_nufus: "10.941",
      },
      {
        plaka_kodu: "8",
        ilce_kodu: "1202",
        il_adi: "ARTVİN",
        ilce_adi: "BORÇKA",
        nufus: "22.030",
        erkek_nufus: "11.235",
        kadin_nufus: "10.795",
      },
      {
        plaka_kodu: "8",
        ilce_kodu: "1395",
        il_adi: "ARTVİN",
        ilce_adi: "HOPA",
        nufus: "28.136",
        erkek_nufus: "14.116",
        kadin_nufus: "14.020",
      },
      {
        plaka_kodu: "8",
        ilce_kodu: "2105",
        il_adi: "ARTVİN",
        ilce_adi: "KEMALPAŞA",
        nufus: "8.974",
        erkek_nufus: "4.571",
        kadin_nufus: "4.403",
      },
      {
        plaka_kodu: "8",
        ilce_kodu: "1828",
        il_adi: "ARTVİN",
        ilce_adi: "MURGUL",
        nufus: "6.522",
        erkek_nufus: "3.401",
        kadin_nufus: "3.121",
      },
      {
        plaka_kodu: "8",
        ilce_kodu: "1653",
        il_adi: "ARTVİN",
        ilce_adi: "ŞAVŞAT",
        nufus: "16.975",
        erkek_nufus: "8.550",
        kadin_nufus: "8.425",
      },
      {
        plaka_kodu: "8",
        ilce_kodu: "1736",
        il_adi: "ARTVİN",
        ilce_adi: "YUSUFELİ",
        nufus: "19.510",
        erkek_nufus: "9.836",
        kadin_nufus: "9.674",
      },
    ],
    kisa_bilgi:
      "Ülkemizin Gürcistan ile sınırında bulunan, Doğu Karadeniz bölümündeki bu yeşil şehir, kültürel olarak rengarenk. Nüfus Hemşinliler, Gürcüler, Lazlar ile Ahıska ve Kıpçak Türklerinden meydana geliyor.",
  },
  {
    il_adi: "Aydın",
    plaka_kodu: "09",
    alan_kodu: "256",
    nufus: "1.148.241",
    bolge: "Ege",
    yuzolcumu: "8.116",
    nufus_artisi: "12,5%",
    erkek_nufus_yuzde: "49,75%",
    erkek_nufus: "546.092",
    kadin_nufus_yuzde: "50,25%",
    kadin_nufus: "551.654",
    nufus_yuzdesi_genel: "1,34%",
    ilceler: [
      {
        plaka_kodu: "9",
        ilce_kodu: "1206",
        il_adi: "AYDIN",
        ilce_adi: "BOZDOĞAN",
        nufus: "32.963",
        erkek_nufus: "16.487",
        kadin_nufus: "16.476",
      },
      {
        plaka_kodu: "9",
        ilce_kodu: "1781",
        il_adi: "AYDIN",
        ilce_adi: "BUHARKENT",
        nufus: "12.951",
        erkek_nufus: "6.399",
        kadin_nufus: "6.552",
      },
      {
        plaka_kodu: "9",
        ilce_kodu: "1256",
        il_adi: "AYDIN",
        ilce_adi: "ÇİNE",
        nufus: "48.734",
        erkek_nufus: "24.164",
        kadin_nufus: "24.570",
      },
      {
        plaka_kodu: "9",
        ilce_kodu: "2000",
        il_adi: "AYDIN",
        ilce_adi: "DİDİM",
        nufus: "93.876",
        erkek_nufus: "47.635",
        kadin_nufus: "46.241",
      },
      {
        plaka_kodu: "9",
        ilce_kodu: "2076",
        il_adi: "AYDIN",
        ilce_adi: "EFELER",
        nufus: "300.225",
        erkek_nufus: "148.301",
        kadin_nufus: "151.924",
      },
      {
        plaka_kodu: "9",
        ilce_kodu: "1348",
        il_adi: "AYDIN",
        ilce_adi: "GERMENCİK",
        nufus: "43.713",
        erkek_nufus: "21.688",
        kadin_nufus: "22.025",
      },
      {
        plaka_kodu: "9",
        ilce_kodu: "1807",
        il_adi: "AYDIN",
        ilce_adi: "İNCİRLİOVA",
        nufus: "54.806",
        erkek_nufus: "27.228",
        kadin_nufus: "27.578",
      },
      {
        plaka_kodu: "9",
        ilce_kodu: "1435",
        il_adi: "AYDIN",
        ilce_adi: "KARACASU",
        nufus: "18.003",
        erkek_nufus: "8.858",
        kadin_nufus: "9.145",
      },
      {
        plaka_kodu: "9",
        ilce_kodu: "1957",
        il_adi: "AYDIN",
        ilce_adi: "KARPUZLU",
        nufus: "10.755",
        erkek_nufus: "5.316",
        kadin_nufus: "5.439",
      },
      {
        plaka_kodu: "9",
        ilce_kodu: "1479",
        il_adi: "AYDIN",
        ilce_adi: "KOÇARLI",
        nufus: "22.155",
        erkek_nufus: "11.128",
        kadin_nufus: "11.027",
      },
      {
        plaka_kodu: "9",
        ilce_kodu: "1968",
        il_adi: "AYDIN",
        ilce_adi: "KÖŞK",
        nufus: "28.101",
        erkek_nufus: "14.251",
        kadin_nufus: "13.850",
      },
      {
        plaka_kodu: "9",
        ilce_kodu: "1497",
        il_adi: "AYDIN",
        ilce_adi: "KUŞADASI",
        nufus: "125.812",
        erkek_nufus: "62.595",
        kadin_nufus: "63.217",
      },
      {
        plaka_kodu: "9",
        ilce_kodu: "1498",
        il_adi: "AYDIN",
        ilce_adi: "KUYUCAK",
        nufus: "26.393",
        erkek_nufus: "13.093",
        kadin_nufus: "13.300",
      },
      {
        plaka_kodu: "9",
        ilce_kodu: "1542",
        il_adi: "AYDIN",
        ilce_adi: "NAZİLLİ",
        nufus: "160.581",
        erkek_nufus: "78.700",
        kadin_nufus: "81.881",
      },
      {
        plaka_kodu: "9",
        ilce_kodu: "1637",
        il_adi: "AYDIN",
        ilce_adi: "SÖKE",
        nufus: "122.708",
        erkek_nufus: "61.466",
        kadin_nufus: "61.242",
      },
      {
        plaka_kodu: "9",
        ilce_kodu: "1640",
        il_adi: "AYDIN",
        ilce_adi: "SULTANHİSAR",
        nufus: "20.272",
        erkek_nufus: "9.915",
        kadin_nufus: "10.357",
      },
      {
        plaka_kodu: "9",
        ilce_kodu: "1724",
        il_adi: "AYDIN",
        ilce_adi: "YENİPAZAR",
        nufus: "11.983",
        erkek_nufus: "5.813",
        kadin_nufus: "6.170",
      },
    ],
    kisa_bilgi:
      "Turizm ve tarım sektörlerinde ülkenin önemli illerinden biri olan Aydın, aynı zamanda Türkiye'nin ilk demiryolu kurulan şehridir. Kuzeyinde İzmir, Manisa, doğusunda Denizli, güneyinde Muğla ile çevrili il, çok önemli turistik mekanlara, plajlara, antik kentlere evsahipliği yapar.",
  },
  {
    il_adi: "Balıkesir",
    plaka_kodu: "10",
    alan_kodu: "266",
    nufus: "1.257.590",
    bolge: "Marmara",
    yuzolcumu: "14.583",
    nufus_artisi: "5,6%",
    erkek_nufus_yuzde: "49,96%",
    erkek_nufus: "612.738",
    kadin_nufus_yuzde: "50,04%",
    kadin_nufus: "613.837",
    nufus_yuzdesi_genel: "1,50%",
    ilceler: [
      {
        plaka_kodu: "10",
        ilce_kodu: "2077",
        il_adi: "BALIKESİR",
        ilce_adi: "ALTIEYLÜL",
        nufus: "185.038",
        erkek_nufus: "94.355",
        kadin_nufus: "90.683",
      },
      {
        plaka_kodu: "10",
        ilce_kodu: "1161",
        il_adi: "BALIKESİR",
        ilce_adi: "AYVALIK",
        nufus: "72.371",
        erkek_nufus: "35.627",
        kadin_nufus: "36.744",
      },
      {
        plaka_kodu: "10",
        ilce_kodu: "1169",
        il_adi: "BALIKESİR",
        ilce_adi: "BALYA",
        nufus: "12.658",
        erkek_nufus: "6.364",
        kadin_nufus: "6.294",
      },
      {
        plaka_kodu: "10",
        ilce_kodu: "1171",
        il_adi: "BALIKESİR",
        ilce_adi: "BANDIRMA",
        nufus: "161.894",
        erkek_nufus: "81.890",
        kadin_nufus: "80.004",
      },
      {
        plaka_kodu: "10",
        ilce_kodu: "1191",
        il_adi: "BALIKESİR",
        ilce_adi: "BİGADİÇ",
        nufus: "49.717",
        erkek_nufus: "25.649",
        kadin_nufus: "24.068",
      },
      {
        plaka_kodu: "10",
        ilce_kodu: "1216",
        il_adi: "BALIKESİR",
        ilce_adi: "BURHANİYE",
        nufus: "63.379",
        erkek_nufus: "32.276",
        kadin_nufus: "31.103",
      },
      {
        plaka_kodu: "10",
        ilce_kodu: "1291",
        il_adi: "BALIKESİR",
        ilce_adi: "DURSUNBEY",
        nufus: "34.159",
        erkek_nufus: "16.792",
        kadin_nufus: "17.367",
      },
      {
        plaka_kodu: "10",
        ilce_kodu: "1294",
        il_adi: "BALIKESİR",
        ilce_adi: "EDREMİT",
        nufus: "164.696",
        erkek_nufus: "81.060",
        kadin_nufus: "83.636",
      },
      {
        plaka_kodu: "10",
        ilce_kodu: "1310",
        il_adi: "BALIKESİR",
        ilce_adi: "ERDEK",
        nufus: "32.038",
        erkek_nufus: "16.165",
        kadin_nufus: "15.873",
      },
      {
        plaka_kodu: "10",
        ilce_kodu: "1928",
        il_adi: "BALIKESİR",
        ilce_adi: "GÖMEÇ",
        nufus: "16.217",
        erkek_nufus: "8.128",
        kadin_nufus: "8.089",
      },
      {
        plaka_kodu: "10",
        ilce_kodu: "1360",
        il_adi: "BALIKESİR",
        ilce_adi: "GÖNEN",
        nufus: "74.788",
        erkek_nufus: "37.238",
        kadin_nufus: "37.550",
      },
      {
        plaka_kodu: "10",
        ilce_kodu: "1384",
        il_adi: "BALIKESİR",
        ilce_adi: "HAVRAN",
        nufus: "27.914",
        erkek_nufus: "13.888",
        kadin_nufus: "14.026",
      },
      {
        plaka_kodu: "10",
        ilce_kodu: "1418",
        il_adi: "BALIKESİR",
        ilce_adi: "İVRİNDİ",
        nufus: "31.897",
        erkek_nufus: "15.922",
        kadin_nufus: "15.975",
      },
      {
        plaka_kodu: "10",
        ilce_kodu: "2078",
        il_adi: "BALIKESİR",
        ilce_adi: "KARESİ",
        nufus: "185.118",
        erkek_nufus: "90.286",
        kadin_nufus: "94.832",
      },
      {
        plaka_kodu: "10",
        ilce_kodu: "1462",
        il_adi: "BALIKESİR",
        ilce_adi: "KEPSUT",
        nufus: "22.591",
        erkek_nufus: "12.461",
        kadin_nufus: "10.130",
      },
      {
        plaka_kodu: "10",
        ilce_kodu: "1514",
        il_adi: "BALIKESİR",
        ilce_adi: "MANYAS",
        nufus: "18.279",
        erkek_nufus: "8.977",
        kadin_nufus: "9.302",
      },
      {
        plaka_kodu: "10",
        ilce_kodu: "1824",
        il_adi: "BALIKESİR",
        ilce_adi: "MARMARA",
        nufus: "10.148",
        erkek_nufus: "5.379",
        kadin_nufus: "4.769",
      },
      {
        plaka_kodu: "10",
        ilce_kodu: "1608",
        il_adi: "BALIKESİR",
        ilce_adi: "SAVAŞTEPE",
        nufus: "17.003",
        erkek_nufus: "8.527",
        kadin_nufus: "8.476",
      },
      {
        plaka_kodu: "10",
        ilce_kodu: "1619",
        il_adi: "BALIKESİR",
        ilce_adi: "SINDIRGI",
        nufus: "32.661",
        erkek_nufus: "15.799",
        kadin_nufus: "16.862",
      },
      {
        plaka_kodu: "10",
        ilce_kodu: "1644",
        il_adi: "BALIKESİR",
        ilce_adi: "SUSURLUK",
        nufus: "38.044",
        erkek_nufus: "18.744",
        kadin_nufus: "19.300",
      },
    ],
    kisa_bilgi:
      "Hem Marmara hem de Ege Denizi'ne kıyısı olan, topraklarının büyük kısmı Güney Marmara bölümünde yer alan Balıkesir, Ayvalık, Altınoluk, Avşa Adası, Cunda, Gönen, Edremit, Manyas, Susurluk gibi turistik mekânlara sahiptir.",
  },
  {
    il_adi: "Bilecik",
    plaka_kodu: "11",
    alan_kodu: "228",
    nufus: "228.673",
    bolge: "Marmara",
    yuzolcumu: "4.179",
    nufus_artisi: "1,5%",
    erkek_nufus_yuzde: "52,33%",
    erkek_nufus: "116.923",
    kadin_nufus_yuzde: "47,67%",
    kadin_nufus: "106.525",
    nufus_yuzdesi_genel: "0,27%",
    ilceler: [
      {
        plaka_kodu: "11",
        ilce_kodu: "1192",
        il_adi: "BİLECİK",
        ilce_adi: "MERKEZ",
        nufus: "86.442",
        erkek_nufus: "47.751",
        kadin_nufus: "38.691",
      },
      {
        plaka_kodu: "11",
        ilce_kodu: "1210",
        il_adi: "BİLECİK",
        ilce_adi: "BOZÜYÜK",
        nufus: "78.010",
        erkek_nufus: "39.324",
        kadin_nufus: "38.686",
      },
      {
        plaka_kodu: "11",
        ilce_kodu: "1359",
        il_adi: "BİLECİK",
        ilce_adi: "GÖLPAZARI",
        nufus: "9.031",
        erkek_nufus: "4.621",
        kadin_nufus: "4.410",
      },
      {
        plaka_kodu: "11",
        ilce_kodu: "1948",
        il_adi: "BİLECİK",
        ilce_adi: "İNHİSAR",
        nufus: "2.183",
        erkek_nufus: "1.093",
        kadin_nufus: "1.090",
      },
      {
        plaka_kodu: "11",
        ilce_kodu: "1559",
        il_adi: "BİLECİK",
        ilce_adi: "OSMANELİ",
        nufus: "21.497",
        erkek_nufus: "10.737",
        kadin_nufus: "10.760",
      },
      {
        plaka_kodu: "11",
        ilce_kodu: "1571",
        il_adi: "BİLECİK",
        ilce_adi: "PAZARYERİ",
        nufus: "10.032",
        erkek_nufus: "4.919",
        kadin_nufus: "5.113",
      },
      {
        plaka_kodu: "11",
        ilce_kodu: "1636",
        il_adi: "BİLECİK",
        ilce_adi: "SÖĞÜT",
        nufus: "18.352",
        erkek_nufus: "9.095",
        kadin_nufus: "9.257",
      },
      {
        plaka_kodu: "11",
        ilce_kodu: "1857",
        il_adi: "BİLECİK",
        ilce_adi: "YENİPAZAR",
        nufus: "2.787",
        erkek_nufus: "1.363",
        kadin_nufus: "1.424",
      },
    ],
    kisa_bilgi:
      "Marmara Bölgesi’nin güneydoğusunda yer alan ve ilk yerleşimin M.Ö. 3 bin yılına dek uzandığı Bilecik, aynı zamanda Osmanlı’nın doğduğu topraklardır. ",
  },
  {
    il_adi: "Bingöl",
    plaka_kodu: "12",
    alan_kodu: "426",
    nufus: "282.556",
    bolge: "Doğu Anadolu",
    yuzolcumu: "8.004",
    nufus_artisi: "-2,0%",
    erkek_nufus_yuzde: "51,04%",
    erkek_nufus: "143.524",
    kadin_nufus_yuzde: "48,96%",
    kadin_nufus: "137.681",
    nufus_yuzdesi_genel: "0,34%",
    ilceler: [
      {
        plaka_kodu: "12",
        ilce_kodu: "1193",
        il_adi: "BİNGÖL",
        ilce_adi: "MERKEZ",
        nufus: "168.953",
        erkek_nufus: "84.307",
        kadin_nufus: "84.646",
      },
      {
        plaka_kodu: "12",
        ilce_kodu: "1750",
        il_adi: "BİNGÖL",
        ilce_adi: "ADAKLI",
        nufus: "8.243",
        erkek_nufus: "4.315",
        kadin_nufus: "3.928",
      },
      {
        plaka_kodu: "12",
        ilce_kodu: "1344",
        il_adi: "BİNGÖL",
        ilce_adi: "GENÇ",
        nufus: "33.929",
        erkek_nufus: "17.449",
        kadin_nufus: "16.480",
      },
      {
        plaka_kodu: "12",
        ilce_kodu: "1446",
        il_adi: "BİNGÖL",
        ilce_adi: "KARLIOVA",
        nufus: "28.309",
        erkek_nufus: "14.376",
        kadin_nufus: "13.933",
      },
      {
        plaka_kodu: "12",
        ilce_kodu: "1475",
        il_adi: "BİNGÖL",
        ilce_adi: "KİĞI",
        nufus: "4.801",
        erkek_nufus: "2.746",
        kadin_nufus: "2.055",
      },
      {
        plaka_kodu: "12",
        ilce_kodu: "1633",
        il_adi: "BİNGÖL",
        ilce_adi: "SOLHAN",
        nufus: "33.973",
        erkek_nufus: "17.051",
        kadin_nufus: "16.922",
      },
      {
        plaka_kodu: "12",
        ilce_kodu: "1855",
        il_adi: "BİNGÖL",
        ilce_adi: "YAYLADERE",
        nufus: "2.052",
        erkek_nufus: "1.243",
        kadin_nufus: "809",
      },
      {
        plaka_kodu: "12",
        ilce_kodu: "1996",
        il_adi: "BİNGÖL",
        ilce_adi: "YEDİSU",
        nufus: "2.852",
        erkek_nufus: "1.562",
        kadin_nufus: "1.290",
      },
    ],
    kisa_bilgi:
      "Doğu Anadolu bölgesindeki ilimiz Bingöl, doğal ve tarihi güzellikleriyle yerli ve yabancı turistlerin ilgisini çekecek potansiyele sahip.",
  },
  {
    il_adi: "Bitlis",
    plaka_kodu: "13",
    alan_kodu: "434",
    nufus: "353.988",
    bolge: "Doğu Anadolu",
    yuzolcumu: "8.294",
    nufus_artisi: "4,9%",
    erkek_nufus_yuzde: "52,08%",
    erkek_nufus: "181.970",
    kadin_nufus_yuzde: "47,92%",
    kadin_nufus: "167.426",
    nufus_yuzdesi_genel: "0,38%",
    ilceler: [
      {
        plaka_kodu: "13",
        ilce_kodu: "1196",
        il_adi: "BİTLİS",
        ilce_adi: "MERKEZ",
        nufus: "71.077",
        erkek_nufus: "36.691",
        kadin_nufus: "34.386",
      },
      {
        plaka_kodu: "13",
        ilce_kodu: "1106",
        il_adi: "BİTLİS",
        ilce_adi: "ADİLCEVAZ",
        nufus: "30.123",
        erkek_nufus: "15.362",
        kadin_nufus: "14.761",
      },
      {
        plaka_kodu: "13",
        ilce_kodu: "1112",
        il_adi: "BİTLİS",
        ilce_adi: "AHLAT",
        nufus: "42.131",
        erkek_nufus: "21.973",
        kadin_nufus: "20.158",
      },
      {
        plaka_kodu: "13",
        ilce_kodu: "1798",
        il_adi: "BİTLİS",
        ilce_adi: "GÜROYMAK",
        nufus: "48.536",
        erkek_nufus: "25.047",
        kadin_nufus: "23.489",
      },
      {
        plaka_kodu: "13",
        ilce_kodu: "1394",
        il_adi: "BİTLİS",
        ilce_adi: "HİZAN",
        nufus: "32.586",
        erkek_nufus: "16.618",
        kadin_nufus: "15.968",
      },
      {
        plaka_kodu: "13",
        ilce_kodu: "1537",
        il_adi: "BİTLİS",
        ilce_adi: "MUTKİ",
        nufus: "30.940",
        erkek_nufus: "16.140",
        kadin_nufus: "14.800",
      },
      {
        plaka_kodu: "13",
        ilce_kodu: "1669",
        il_adi: "BİTLİS",
        ilce_adi: "TATVAN",
        nufus: "96.884",
        erkek_nufus: "49.982",
        kadin_nufus: "46.902",
      },
    ],
    kisa_bilgi:
      "Tarihte Urartuların yerleşim yeri olmuş, 1514 yılında Osmanlı egemenliğine giren Bitlis, en çok Tatvan’dan ulaşılabilecek Nemrut Yanardağı ve Nemrut Krater Gölü ile turistlerin ilgisini çekiyor. ",
  },
  {
    il_adi: "Bolu",
    plaka_kodu: "14",
    alan_kodu: "374",
    nufus: "320.824",
    bolge: "Karadeniz",
    yuzolcumu: "8.313",
    nufus_artisi: "2,5%",
    erkek_nufus_yuzde: "49,62%",
    erkek_nufus: "154.711",
    kadin_nufus_yuzde: "50,38%",
    kadin_nufus: "157.099",
    nufus_yuzdesi_genel: "0,38%",
    ilceler: [
      {
        plaka_kodu: "14",
        ilce_kodu: "1199",
        il_adi: "BOLU",
        ilce_adi: "MERKEZ",
        nufus: "217.935",
        erkek_nufus: "107.396",
        kadin_nufus: "110.539",
      },
      {
        plaka_kodu: "14",
        ilce_kodu: "1916",
        il_adi: "BOLU",
        ilce_adi: "DÖRTDİVAN",
        nufus: "6.540",
        erkek_nufus: "3.203",
        kadin_nufus: "3.337",
      },
      {
        plaka_kodu: "14",
        ilce_kodu: "1346",
        il_adi: "BOLU",
        ilce_adi: "GEREDE",
        nufus: "33.833",
        erkek_nufus: "16.894",
        kadin_nufus: "16.939",
      },
      {
        plaka_kodu: "14",
        ilce_kodu: "1364",
        il_adi: "BOLU",
        ilce_adi: "GÖYNÜK",
        nufus: "14.679",
        erkek_nufus: "7.435",
        kadin_nufus: "7.244",
      },
      {
        plaka_kodu: "14",
        ilce_kodu: "1466",
        il_adi: "BOLU",
        ilce_adi: "KIBRISCIK",
        nufus: "3.114",
        erkek_nufus: "1.585",
        kadin_nufus: "1.529",
      },
      {
        plaka_kodu: "14",
        ilce_kodu: "1522",
        il_adi: "BOLU",
        ilce_adi: "MENGEN",
        nufus: "13.679",
        erkek_nufus: "6.929",
        kadin_nufus: "6.750",
      },
      {
        plaka_kodu: "14",
        ilce_kodu: "1531",
        il_adi: "BOLU",
        ilce_adi: "MUDURNU",
        nufus: "18.629",
        erkek_nufus: "9.445",
        kadin_nufus: "9.184",
      },
      {
        plaka_kodu: "14",
        ilce_kodu: "1610",
        il_adi: "BOLU",
        ilce_adi: "SEBEN",
        nufus: "4.767",
        erkek_nufus: "2.263",
        kadin_nufus: "2.504",
      },
      {
        plaka_kodu: "14",
        ilce_kodu: "1997",
        il_adi: "BOLU",
        ilce_adi: "YENİÇAĞA",
        nufus: "6.838",
        erkek_nufus: "3.430",
        kadin_nufus: "3.408",
      },
    ],
    kisa_bilgi:
      "Batı Karadeniz’de bulunan yemyeşil doğası, tertemiz havası ve su kaynaklarıyla dikkat çeken Bolu’da, Abant Gölü ve Yedigöller Milli Parkı en turistik yerler arasında.",
  },
  {
    il_adi: "Burdur",
    plaka_kodu: "15",
    alan_kodu: "248",
    nufus: "273.799",
    bolge: "Akdeniz",
    yuzolcumu: "7.175",
    nufus_artisi: "0,3%",
    erkek_nufus_yuzde: "50,10%",
    erkek_nufus: "135.240",
    kadin_nufus_yuzde: "49,90%",
    kadin_nufus: "134.686",
    nufus_yuzdesi_genel: "0,33%",
    ilceler: [
      {
        plaka_kodu: "15",
        ilce_kodu: "1215",
        il_adi: "BURDUR",
        ilce_adi: "MERKEZ",
        nufus: "117.189",
        erkek_nufus: "59.602",
        kadin_nufus: "57.587",
      },
      {
        plaka_kodu: "15",
        ilce_kodu: "1109",
        il_adi: "BURDUR",
        ilce_adi: "AĞLASUN",
        nufus: "7.652",
        erkek_nufus: "3.754",
        kadin_nufus: "3.898",
      },
      {
        plaka_kodu: "15",
        ilce_kodu: "1874",
        il_adi: "BURDUR",
        ilce_adi: "ALTINYAYLA",
        nufus: "5.335",
        erkek_nufus: "2.658",
        kadin_nufus: "2.677",
      },
      {
        plaka_kodu: "15",
        ilce_kodu: "1211",
        il_adi: "BURDUR",
        ilce_adi: "BUCAK",
        nufus: "66.156",
        erkek_nufus: "33.075",
        kadin_nufus: "33.081",
      },
      {
        plaka_kodu: "15",
        ilce_kodu: "1899",
        il_adi: "BURDUR",
        ilce_adi: "ÇAVDIR",
        nufus: "12.599",
        erkek_nufus: "6.222",
        kadin_nufus: "6.377",
      },
      {
        plaka_kodu: "15",
        ilce_kodu: "1903",
        il_adi: "BURDUR",
        ilce_adi: "ÇELTİKÇİ",
        nufus: "4.936",
        erkek_nufus: "2.349",
        kadin_nufus: "2.587",
      },
      {
        plaka_kodu: "15",
        ilce_kodu: "1357",
        il_adi: "BURDUR",
        ilce_adi: "GÖLHİSAR",
        nufus: "23.064",
        erkek_nufus: "11.551",
        kadin_nufus: "11.513",
      },
      {
        plaka_kodu: "15",
        ilce_kodu: "1813",
        il_adi: "BURDUR",
        ilce_adi: "KARAMANLI",
        nufus: "8.268",
        erkek_nufus: "4.181",
        kadin_nufus: "4.087",
      },
      {
        plaka_kodu: "15",
        ilce_kodu: "1816",
        il_adi: "BURDUR",
        ilce_adi: "KEMER",
        nufus: "3.000",
        erkek_nufus: "1.465",
        kadin_nufus: "1.535",
      },
      {
        plaka_kodu: "15",
        ilce_kodu: "1672",
        il_adi: "BURDUR",
        ilce_adi: "TEFENNİ",
        nufus: "10.744",
        erkek_nufus: "5.343",
        kadin_nufus: "5.401",
      },
      {
        plaka_kodu: "15",
        ilce_kodu: "1728",
        il_adi: "BURDUR",
        ilce_adi: "YEŞİLOVA",
        nufus: "14.773",
        erkek_nufus: "7.172",
        kadin_nufus: "7.601",
      },
    ],
    kisa_bilgi:
      "Akdeniz Bölgesinde, Orta ve Batı Anadolu’yu Akdeniz’e bağlayan yolların kavşağında yer alan Burdur, bu özelliğiyle tarih boyunca çeşitli uygarlıkların da kavşağı konumunda olmuştur. ",
  },
  {
    il_adi: "Bursa",
    plaka_kodu: "16",
    alan_kodu: "224",
    nufus: "3.194.720",
    bolge: "Marmara",
    yuzolcumu: "10.813",
    nufus_artisi: "14,8%",
    erkek_nufus_yuzde: "50,03%",
    erkek_nufus: "1.498.219",
    kadin_nufus_yuzde: "49,97%",
    kadin_nufus: "1.496.302",
    nufus_yuzdesi_genel: "3,65%",
    ilceler: [
      {
        plaka_kodu: "16",
        ilce_kodu: "1783",
        il_adi: "BURSA",
        ilce_adi: "BÜYÜKORHAN",
        nufus: "9.321",
        erkek_nufus: "4.608",
        kadin_nufus: "4.713",
      },
      {
        plaka_kodu: "16",
        ilce_kodu: "1343",
        il_adi: "BURSA",
        ilce_adi: "GEMLİK",
        nufus: "118.037",
        erkek_nufus: "59.987",
        kadin_nufus: "58.050",
      },
      {
        plaka_kodu: "16",
        ilce_kodu: "1935",
        il_adi: "BURSA",
        ilce_adi: "GÜRSU",
        nufus: "99.278",
        erkek_nufus: "50.138",
        kadin_nufus: "49.140",
      },
      {
        plaka_kodu: "16",
        ilce_kodu: "1799",
        il_adi: "BURSA",
        ilce_adi: "HARMANCIK",
        nufus: "6.016",
        erkek_nufus: "2.935",
        kadin_nufus: "3.081",
      },
      {
        plaka_kodu: "16",
        ilce_kodu: "1411",
        il_adi: "BURSA",
        ilce_adi: "İNEGÖL",
        nufus: "286.848",
        erkek_nufus: "144.197",
        kadin_nufus: "142.651",
      },
      {
        plaka_kodu: "16",
        ilce_kodu: "1420",
        il_adi: "BURSA",
        ilce_adi: "İZNİK",
        nufus: "44.050",
        erkek_nufus: "21.759",
        kadin_nufus: "22.291",
      },
      {
        plaka_kodu: "16",
        ilce_kodu: "1434",
        il_adi: "BURSA",
        ilce_adi: "KARACABEY",
        nufus: "84.241",
        erkek_nufus: "42.192",
        kadin_nufus: "42.049",
      },
      {
        plaka_kodu: "16",
        ilce_kodu: "1457",
        il_adi: "BURSA",
        ilce_adi: "KELES",
        nufus: "11.246",
        erkek_nufus: "5.527",
        kadin_nufus: "5.719",
      },
      {
        plaka_kodu: "16",
        ilce_kodu: "1960",
        il_adi: "BURSA",
        ilce_adi: "KESTEL",
        nufus: "72.439",
        erkek_nufus: "36.378",
        kadin_nufus: "36.061",
      },
      {
        plaka_kodu: "16",
        ilce_kodu: "1530",
        il_adi: "BURSA",
        ilce_adi: "MUDANYA",
        nufus: "105.308",
        erkek_nufus: "51.194",
        kadin_nufus: "54.114",
      },
      {
        plaka_kodu: "16",
        ilce_kodu: "1535",
        il_adi: "BURSA",
        ilce_adi: "MUSTAFAKEMALPAŞA",
        nufus: "101.407",
        erkek_nufus: "50.379",
        kadin_nufus: "51.028",
      },
      {
        plaka_kodu: "16",
        ilce_kodu: "1829",
        il_adi: "BURSA",
        ilce_adi: "NİLÜFER",
        nufus: "518.382",
        erkek_nufus: "255.471",
        kadin_nufus: "262.911",
      },
      {
        plaka_kodu: "16",
        ilce_kodu: "1553",
        il_adi: "BURSA",
        ilce_adi: "ORHANELİ",
        nufus: "18.786",
        erkek_nufus: "9.218",
        kadin_nufus: "9.568",
      },
      {
        plaka_kodu: "16",
        ilce_kodu: "1554",
        il_adi: "BURSA",
        ilce_adi: "ORHANGAZİ",
        nufus: "80.216",
        erkek_nufus: "40.228",
        kadin_nufus: "39.988",
      },
      {
        plaka_kodu: "16",
        ilce_kodu: "1832",
        il_adi: "BURSA",
        ilce_adi: "OSMANGAZİ",
        nufus: "884.451",
        erkek_nufus: "443.501",
        kadin_nufus: "440.950",
      },
      {
        plaka_kodu: "16",
        ilce_kodu: "1725",
        il_adi: "BURSA",
        ilce_adi: "YENİŞEHİR",
        nufus: "54.485",
        erkek_nufus: "27.041",
        kadin_nufus: "27.444",
      },
      {
        plaka_kodu: "16",
        ilce_kodu: "1859",
        il_adi: "BURSA",
        ilce_adi: "YILDIRIM",
        nufus: "653.307",
        erkek_nufus: "328.609",
        kadin_nufus: "324.698",
      },
    ],
    kisa_bilgi:
      "Akdeniz Bölgesinde, Orta ve Batı Anadolu’yu Akdeniz’e bağlayan yolların kavşağında yer alan Burdur, bu özelliğiyle tarih boyunca çeşitli uygarlıkların da kavşağı konumunda olmuştur. ",
  },
  {
    il_adi: "Çanakkale",
    plaka_kodu: "17",
    alan_kodu: "286",
    nufus: "559.383",
    bolge: "Ege",
    yuzolcumu: "9.817",
    nufus_artisi: "3,8%",
    erkek_nufus_yuzde: "50,51%",
    erkek_nufus: "273.092",
    kadin_nufus_yuzde: "49,49%",
    kadin_nufus: "267.570",
    nufus_yuzdesi_genel: "0,66%",
    ilceler: [
      {
        plaka_kodu: "17",
        ilce_kodu: "1230",
        il_adi: "ÇANAKKALE",
        ilce_adi: "MERKEZ",
        nufus: "195.439",
        erkek_nufus: "96.707",
        kadin_nufus: "98.732",
      },
      {
        plaka_kodu: "17",
        ilce_kodu: "1160",
        il_adi: "ÇANAKKALE",
        ilce_adi: "AYVACIK",
        nufus: "34.103",
        erkek_nufus: "17.158",
        kadin_nufus: "16.945",
      },
      {
        plaka_kodu: "17",
        ilce_kodu: "1180",
        il_adi: "ÇANAKKALE",
        ilce_adi: "BAYRAMİÇ",
        nufus: "29.136",
        erkek_nufus: "14.391",
        kadin_nufus: "14.745",
      },
      {
        plaka_kodu: "17",
        ilce_kodu: "1190",
        il_adi: "ÇANAKKALE",
        ilce_adi: "BİGA",
        nufus: "91.537",
        erkek_nufus: "45.704",
        kadin_nufus: "45.833",
      },
      {
        plaka_kodu: "17",
        ilce_kodu: "1205",
        il_adi: "ÇANAKKALE",
        ilce_adi: "BOZCAADA",
        nufus: "3.098",
        erkek_nufus: "1.693",
        kadin_nufus: "1.405",
      },
      {
        plaka_kodu: "17",
        ilce_kodu: "1229",
        il_adi: "ÇANAKKALE",
        ilce_adi: "ÇAN",
        nufus: "48.023",
        erkek_nufus: "24.096",
        kadin_nufus: "23.927",
      },
      {
        plaka_kodu: "17",
        ilce_kodu: "1293",
        il_adi: "ÇANAKKALE",
        ilce_adi: "ECEABAT",
        nufus: "8.769",
        erkek_nufus: "4.441",
        kadin_nufus: "4.328",
      },
      {
        plaka_kodu: "17",
        ilce_kodu: "1326",
        il_adi: "ÇANAKKALE",
        ilce_adi: "EZİNE",
        nufus: "32.374",
        erkek_nufus: "16.902",
        kadin_nufus: "15.472",
      },
      {
        plaka_kodu: "17",
        ilce_kodu: "1340",
        il_adi: "ÇANAKKALE",
        ilce_adi: "GELİBOLU",
        nufus: "44.598",
        erkek_nufus: "23.648",
        kadin_nufus: "20.950",
      },
      {
        plaka_kodu: "17",
        ilce_kodu: "1408",
        il_adi: "ÇANAKKALE",
        ilce_adi: "GÖKÇEADA",
        nufus: "10.377",
        erkek_nufus: "5.866",
        kadin_nufus: "4.511",
      },
      {
        plaka_kodu: "17",
        ilce_kodu: "1503",
        il_adi: "ÇANAKKALE",
        ilce_adi: "LAPSEKİ",
        nufus: "28.742",
        erkek_nufus: "14.347",
        kadin_nufus: "14.395",
      },
      {
        plaka_kodu: "17",
        ilce_kodu: "1722",
        il_adi: "ÇANAKKALE",
        ilce_adi: "YENİCE",
        nufus: "31.080",
        erkek_nufus: "15.381",
        kadin_nufus: "15.699",
      },
    ],
    kisa_bilgi:
      "Kuzeybatı Anadolu’da, Troya kalıntılarının yer aldığı, binlerce yıllık tarihi mirası, doğal güzellikleriyle en güzel şehirlerimizden biri olan Çanakkale’de, 1963 yılından bu yana her ağustos ayında Uluslararası Troia Festivali düzenleniyor. Truva filminin setinde kullanılan ve şehre hediye edilen Truva Atı, merkezde görülebilir.",
  },
  {
    il_adi: "Çankırı",
    plaka_kodu: "18",
    alan_kodu: "376",
    nufus: "195.766",
    bolge: "İç Anadolu",
    yuzolcumu: "7.542",
    nufus_artisi: "-3,8%",
    erkek_nufus_yuzde: "50,62%",
    erkek_nufus: "109.512",
    kadin_nufus_yuzde: "49,38%",
    kadin_nufus: "106.850",
    nufus_yuzdesi_genel: "0,26%",
    ilceler: [
      {
        plaka_kodu: "18",
        ilce_kodu: "1232",
        il_adi: "ÇANKIRI",
        ilce_adi: "MERKEZ",
        nufus: "100.027",
        erkek_nufus: "50.323",
        kadin_nufus: "49.704",
      },
      {
        plaka_kodu: "18",
        ilce_kodu: "1765",
        il_adi: "ÇANKIRI",
        ilce_adi: "ATKARACALAR",
        nufus: "4.968",
        erkek_nufus: "2.544",
        kadin_nufus: "2.424",
      },
      {
        plaka_kodu: "18",
        ilce_kodu: "1885",
        il_adi: "ÇANKIRI",
        ilce_adi: "BAYRAMÖREN",
        nufus: "2.433",
        erkek_nufus: "1.255",
        kadin_nufus: "1.178",
      },
      {
        plaka_kodu: "18",
        ilce_kodu: "1248",
        il_adi: "ÇANKIRI",
        ilce_adi: "ÇERKEŞ",
        nufus: "16.746",
        erkek_nufus: "8.554",
        kadin_nufus: "8.192",
      },
      {
        plaka_kodu: "18",
        ilce_kodu: "1300",
        il_adi: "ÇANKIRI",
        ilce_adi: "ELDİVAN",
        nufus: "5.977",
        erkek_nufus: "2.985",
        kadin_nufus: "2.992",
      },
      {
        plaka_kodu: "18",
        ilce_kodu: "1399",
        il_adi: "ÇANKIRI",
        ilce_adi: "ILGAZ",
        nufus: "13.700",
        erkek_nufus: "6.879",
        kadin_nufus: "6.821",
      },
      {
        plaka_kodu: "18",
        ilce_kodu: "1817",
        il_adi: "ÇANKIRI",
        ilce_adi: "KIZILIRMAK",
        nufus: "7.122",
        erkek_nufus: "3.577",
        kadin_nufus: "3.545",
      },
      {
        plaka_kodu: "18",
        ilce_kodu: "1963",
        il_adi: "ÇANKIRI",
        ilce_adi: "KORGUN",
        nufus: "4.569",
        erkek_nufus: "2.295",
        kadin_nufus: "2.274",
      },
      {
        plaka_kodu: "18",
        ilce_kodu: "1494",
        il_adi: "ÇANKIRI",
        ilce_adi: "KURŞUNLU",
        nufus: "8.463",
        erkek_nufus: "4.322",
        kadin_nufus: "4.141",
      },
      {
        plaka_kodu: "18",
        ilce_kodu: "1555",
        il_adi: "ÇANKIRI",
        ilce_adi: "ORTA",
        nufus: "13.207",
        erkek_nufus: "6.880",
        kadin_nufus: "6.327",
      },
      {
        plaka_kodu: "18",
        ilce_kodu: "1649",
        il_adi: "ÇANKIRI",
        ilce_adi: "ŞABANÖZÜ",
        nufus: "11.657",
        erkek_nufus: "5.910",
        kadin_nufus: "5.747",
      },
      {
        plaka_kodu: "18",
        ilce_kodu: "1718",
        il_adi: "ÇANKIRI",
        ilce_adi: "YAPRAKLI",
        nufus: "7.646",
        erkek_nufus: "3.754",
        kadin_nufus: "3.892",
      },
    ],
    kisa_bilgi:
      "Tarihi M.Ö. 5 bin yılına dek uzanan Çankırı, İç Anadolu’nun kuzey ucunda bulunuyor.",
  },
  {
    il_adi: "Çorum",
    plaka_kodu: "19",
    alan_kodu: "364",
    nufus: "524.130",
    bolge: "Karadeniz",
    yuzolcumu: "12.428",
    nufus_artisi: "-4,1%",
    erkek_nufus_yuzde: "49,52%",
    erkek_nufus: "265.678",
    kadin_nufus_yuzde: "50,48%",
    kadin_nufus: "270.805",
    nufus_yuzdesi_genel: "0,26%",
    ilceler: [
      {
        plaka_kodu: "19",
        ilce_kodu: "1259",
        il_adi: "ÇORUM",
        ilce_adi: "MERKEZ",
        nufus: "298.796",
        erkek_nufus: "148.326",
        kadin_nufus: "150.470",
      },
      {
        plaka_kodu: "19",
        ilce_kodu: "1124",
        il_adi: "ÇORUM",
        ilce_adi: "ALACA",
        nufus: "30.592",
        erkek_nufus: "15.278",
        kadin_nufus: "15.314",
      },
      {
        plaka_kodu: "19",
        ilce_kodu: "1177",
        il_adi: "ÇORUM",
        ilce_adi: "BAYAT",
        nufus: "15.199",
        erkek_nufus: "7.315",
        kadin_nufus: "7.884",
      },
      {
        plaka_kodu: "19",
        ilce_kodu: "1778",
        il_adi: "ÇORUM",
        ilce_adi: "BOĞAZKALE",
        nufus: "3.628",
        erkek_nufus: "1.829",
        kadin_nufus: "1.799",
      },
      {
        plaka_kodu: "19",
        ilce_kodu: "1911",
        il_adi: "ÇORUM",
        ilce_adi: "DODURGA",
        nufus: "5.413",
        erkek_nufus: "2.656",
        kadin_nufus: "2.757",
      },
      {
        plaka_kodu: "19",
        ilce_kodu: "1414",
        il_adi: "ÇORUM",
        ilce_adi: "İSKİLİP",
        nufus: "30.022",
        erkek_nufus: "14.744",
        kadin_nufus: "15.278",
      },
      {
        plaka_kodu: "19",
        ilce_kodu: "1445",
        il_adi: "ÇORUM",
        ilce_adi: "KARGI",
        nufus: "15.334",
        erkek_nufus: "7.444",
        kadin_nufus: "7.890",
      },
      {
        plaka_kodu: "19",
        ilce_kodu: "1972",
        il_adi: "ÇORUM",
        ilce_adi: "LAÇİN",
        nufus: "4.289",
        erkek_nufus: "2.202",
        kadin_nufus: "2.087",
      },
      {
        plaka_kodu: "19",
        ilce_kodu: "1520",
        il_adi: "ÇORUM",
        ilce_adi: "MECİTÖZÜ",
        nufus: "14.191",
        erkek_nufus: "7.146",
        kadin_nufus: "7.045",
      },
      {
        plaka_kodu: "19",
        ilce_kodu: "1976",
        il_adi: "ÇORUM",
        ilce_adi: "OĞUZLAR",
        nufus: "4.894",
        erkek_nufus: "2.369",
        kadin_nufus: "2.525",
      },
      {
        plaka_kodu: "19",
        ilce_kodu: "1556",
        il_adi: "ÇORUM",
        ilce_adi: "ORTAKÖY",
        nufus: "6.425",
        erkek_nufus: "3.210",
        kadin_nufus: "3.215",
      },
      {
        plaka_kodu: "19",
        ilce_kodu: "1558",
        il_adi: "ÇORUM",
        ilce_adi: "OSMANCIK",
        nufus: "43.634",
        erkek_nufus: "21.561",
        kadin_nufus: "22.073",
      },
      {
        plaka_kodu: "19",
        ilce_kodu: "1642",
        il_adi: "ÇORUM",
        ilce_adi: "SUNGURLU",
        nufus: "48.028",
        erkek_nufus: "24.328",
        kadin_nufus: "23.700",
      },
      {
        plaka_kodu: "19",
        ilce_kodu: "1850",
        il_adi: "ÇORUM",
        ilce_adi: "UĞURLUDAĞ",
        nufus: "5.837",
        erkek_nufus: "2.958",
        kadin_nufus: "2.879",
      },
    ],
    kisa_bilgi:
      "Pek çok medeniyete ev sahipliği yapmış, M.Ö. 4000 yılına dek uzanan yerleşim izlerine rastlanan, İç Orta Karadeniz bölgesinde, leblebisiyle meşhur bu ilimiz, ova ve akarsu zenginliğiyle dikkat çekiyor.",
  },
  {
    il_adi: "Denizli",
    plaka_kodu: "20",
    alan_kodu: "258",
    nufus: "1.056.332",
    bolge: "Ege",
    yuzolcumu: "12.134",
    nufus_artisi: "5,0%",
    erkek_nufus_yuzde: "49,83%",
    erkek_nufus: "512.109",
    kadin_nufus_yuzde: "50,17%",
    kadin_nufus: "515.673",
    nufus_yuzdesi_genel: "1,25%",
    ilceler: [
      {
        plaka_kodu: "20",
        ilce_kodu: "1102",
        il_adi: "DENİZLİ",
        ilce_adi: "ACIPAYAM",
        nufus: "55.141",
        erkek_nufus: "27.739",
        kadin_nufus: "27.402",
      },
      {
        plaka_kodu: "20",
        ilce_kodu: "1769",
        il_adi: "DENİZLİ",
        ilce_adi: "BABADAĞ",
        nufus: "6.404",
        erkek_nufus: "3.316",
        kadin_nufus: "3.088",
      },
      {
        plaka_kodu: "20",
        ilce_kodu: "1881",
        il_adi: "DENİZLİ",
        ilce_adi: "BAKLAN",
        nufus: "5.387",
        erkek_nufus: "2.665",
        kadin_nufus: "2.722",
      },
      {
        plaka_kodu: "20",
        ilce_kodu: "1774",
        il_adi: "DENİZLİ",
        ilce_adi: "BEKİLLİ",
        nufus: "6.464",
        erkek_nufus: "3.119",
        kadin_nufus: "3.345",
      },
      {
        plaka_kodu: "20",
        ilce_kodu: "1888",
        il_adi: "DENİZLİ",
        ilce_adi: "BEYAĞAÇ",
        nufus: "6.263",
        erkek_nufus: "3.191",
        kadin_nufus: "3.072",
      },
      {
        plaka_kodu: "20",
        ilce_kodu: "1889",
        il_adi: "DENİZLİ",
        ilce_adi: "BOZKURT",
        nufus: "12.192",
        erkek_nufus: "5.447",
        kadin_nufus: "6.745",
      },
      {
        plaka_kodu: "20",
        ilce_kodu: "1214",
        il_adi: "DENİZLİ",
        ilce_adi: "BULDAN",
        nufus: "26.781",
        erkek_nufus: "13.138",
        kadin_nufus: "13.643",
      },
      {
        plaka_kodu: "20",
        ilce_kodu: "1224",
        il_adi: "DENİZLİ",
        ilce_adi: "ÇAL",
        nufus: "18.107",
        erkek_nufus: "8.909",
        kadin_nufus: "9.198",
      },
      {
        plaka_kodu: "20",
        ilce_kodu: "1226",
        il_adi: "DENİZLİ",
        ilce_adi: "ÇAMELİ",
        nufus: "17.749",
        erkek_nufus: "8.940",
        kadin_nufus: "8.809",
      },
      {
        plaka_kodu: "20",
        ilce_kodu: "1233",
        il_adi: "DENİZLİ",
        ilce_adi: "ÇARDAK",
        nufus: "8.578",
        erkek_nufus: "4.359",
        kadin_nufus: "4.219",
      },
      {
        plaka_kodu: "20",
        ilce_kodu: "1257",
        il_adi: "DENİZLİ",
        ilce_adi: "ÇİVRİL",
        nufus: "60.362",
        erkek_nufus: "29.856",
        kadin_nufus: "30.506",
      },
      {
        plaka_kodu: "20",
        ilce_kodu: "1371",
        il_adi: "DENİZLİ",
        ilce_adi: "GÜNEY",
        nufus: "9.506",
        erkek_nufus: "4.684",
        kadin_nufus: "4.822",
      },
      {
        plaka_kodu: "20",
        ilce_kodu: "1803",
        il_adi: "DENİZLİ",
        ilce_adi: "HONAZ",
        nufus: "34.103",
        erkek_nufus: "19.322",
        kadin_nufus: "14.781",
      },
      {
        plaka_kodu: "20",
        ilce_kodu: "1426",
        il_adi: "DENİZLİ",
        ilce_adi: "KALE",
        nufus: "19.396",
        erkek_nufus: "9.663",
        kadin_nufus: "9.733",
      },
      {
        plaka_kodu: "20",
        ilce_kodu: "2079",
        il_adi: "DENİZLİ",
        ilce_adi: "MERKEZEFENDİ",
        nufus: "329.451",
        erkek_nufus: "162.518",
        kadin_nufus: "166.933",
      },
      {
        plaka_kodu: "20",
        ilce_kodu: "1871",
        il_adi: "DENİZLİ",
        ilce_adi: "PAMUKKALE",
        nufus: "347.621",
        erkek_nufus: "173.184",
        kadin_nufus: "174.437",
      },
      {
        plaka_kodu: "20",
        ilce_kodu: "1597",
        il_adi: "DENİZLİ",
        ilce_adi: "SARAYKÖY",
        nufus: "30.696",
        erkek_nufus: "15.368",
        kadin_nufus: "15.328",
      },
      {
        plaka_kodu: "20",
        ilce_kodu: "1840",
        il_adi: "DENİZLİ",
        ilce_adi: "SERİNHİSAR",
        nufus: "14.349",
        erkek_nufus: "7.123",
        kadin_nufus: "7.226",
      },
      {
        plaka_kodu: "20",
        ilce_kodu: "1670",
        il_adi: "DENİZLİ",
        ilce_adi: "TAVAS",
        nufus: "42.506",
        erkek_nufus: "20.957",
        kadin_nufus: "21.549",
      },
    ],
    kisa_bilgi:
      "Denizli ili sanayisi ve turizmi ile Türkiyenin önemli şehirlerindendir. Tekstilin başkentidir. İhracatta ülke ekonomisine önemli katkılar sağlamıştır. İhracatta Türkiye 7. sidir. Türkiye de gelen turist sayısı olarak 5. il konumundadır.",
  },
  {
    il_adi: "Diyarbakır",
    plaka_kodu: "21",
    alan_kodu: "412",
    nufus: "1.804.880",
    bolge: "Güneydoğu Anadolu",
    yuzolcumu: "15.168",
    nufus_artisi: "7,5%",
    erkek_nufus_yuzde: "50,54%",
    erkek_nufus: "875.468",
    kadin_nufus_yuzde: "49,46%",
    kadin_nufus: "856.928",
    nufus_yuzdesi_genel: "2,11%",
    ilceler: [
      {
        plaka_kodu: "21",
        ilce_kodu: "2040",
        il_adi: "DİYARBAKIR",
        ilce_adi: "BAĞLAR",
        nufus: "400.019",
        erkek_nufus: "201.307",
        kadin_nufus: "198.712",
      },
      {
        plaka_kodu: "21",
        ilce_kodu: "1195",
        il_adi: "DİYARBAKIR",
        ilce_adi: "BİSMİL",
        nufus: "118.592",
        erkek_nufus: "59.381",
        kadin_nufus: "59.211",
      },
      {
        plaka_kodu: "21",
        ilce_kodu: "1249",
        il_adi: "DİYARBAKIR",
        ilce_adi: "ÇERMİK",
        nufus: "50.390",
        erkek_nufus: "25.309",
        kadin_nufus: "25.081",
      },
      {
        plaka_kodu: "21",
        ilce_kodu: "1253",
        il_adi: "DİYARBAKIR",
        ilce_adi: "ÇINAR",
        nufus: "77.112",
        erkek_nufus: "38.757",
        kadin_nufus: "38.355",
      },
      {
        plaka_kodu: "21",
        ilce_kodu: "1263",
        il_adi: "DİYARBAKIR",
        ilce_adi: "ÇÜNGÜŞ",
        nufus: "11.020",
        erkek_nufus: "5.559",
        kadin_nufus: "5.461",
      },
      {
        plaka_kodu: "21",
        ilce_kodu: "1278",
        il_adi: "DİYARBAKIR",
        ilce_adi: "DİCLE",
        nufus: "36.711",
        erkek_nufus: "18.542",
        kadin_nufus: "18.169",
      },
      {
        plaka_kodu: "21",
        ilce_kodu: "1791",
        il_adi: "DİYARBAKIR",
        ilce_adi: "EĞİL",
        nufus: "22.165",
        erkek_nufus: "11.181",
        kadin_nufus: "10.984",
      },
      {
        plaka_kodu: "21",
        ilce_kodu: "1315",
        il_adi: "DİYARBAKIR",
        ilce_adi: "ERGANİ",
        nufus: "135.514",
        erkek_nufus: "68.817",
        kadin_nufus: "66.697",
      },
      {
        plaka_kodu: "21",
        ilce_kodu: "1381",
        il_adi: "DİYARBAKIR",
        ilce_adi: "HANİ",
        nufus: "32.658",
        erkek_nufus: "16.612",
        kadin_nufus: "16.046",
      },
      {
        plaka_kodu: "21",
        ilce_kodu: "1389",
        il_adi: "DİYARBAKIR",
        ilce_adi: "HAZRO",
        nufus: "16.541",
        erkek_nufus: "8.416",
        kadin_nufus: "8.125",
      },
      {
        plaka_kodu: "21",
        ilce_kodu: "2041",
        il_adi: "DİYARBAKIR",
        ilce_adi: "KAYAPINAR",
        nufus: "411.865",
        erkek_nufus: "208.902",
        kadin_nufus: "202.963",
      },
      {
        plaka_kodu: "21",
        ilce_kodu: "1962",
        il_adi: "DİYARBAKIR",
        ilce_adi: "KOCAKÖY",
        nufus: "15.750",
        erkek_nufus: "8.002",
        kadin_nufus: "7.748",
      },
      {
        plaka_kodu: "21",
        ilce_kodu: "1490",
        il_adi: "DİYARBAKIR",
        ilce_adi: "KULP",
        nufus: "34.644",
        erkek_nufus: "17.275",
        kadin_nufus: "17.369",
      },
      {
        plaka_kodu: "21",
        ilce_kodu: "1504",
        il_adi: "DİYARBAKIR",
        ilce_adi: "LİCE",
        nufus: "24.313",
        erkek_nufus: "12.683",
        kadin_nufus: "11.630",
      },
      {
        plaka_kodu: "21",
        ilce_kodu: "1624",
        il_adi: "DİYARBAKIR",
        ilce_adi: "SİLVAN",
        nufus: "86.745",
        erkek_nufus: "43.805",
        kadin_nufus: "42.940",
      },
      {
        plaka_kodu: "21",
        ilce_kodu: "2042",
        il_adi: "DİYARBAKIR",
        ilce_adi: "SUR",
        nufus: "100.322",
        erkek_nufus: "50.545",
        kadin_nufus: "49.777",
      },
      {
        plaka_kodu: "21",
        ilce_kodu: "2043",
        il_adi: "DİYARBAKIR",
        ilce_adi: "YENİŞEHİR",
        nufus: "217.012",
        erkek_nufus: "109.095",
        kadin_nufus: "107.917",
      },
    ],
    kisa_bilgi:
      "Tarihi surları, Ulucamisi, Dört Ayaklı Minaresi, binlerce yıllık geçmişiyle ülkemizin en güzel şehirlerinden biri olan Diyarbakır, Mezopotamya ile Anadolu medeniyetlerinin geçiş bölgesinde yer alır.",
  },
  {
    il_adi: "Edirne",
    plaka_kodu: "22",
    alan_kodu: "284",
    nufus: "414.714",
    bolge: "Marmara",
    yuzolcumu: "6.145",
    nufus_artisi: "6,3%",
    erkek_nufus_yuzde: "50,88%",
    erkek_nufus: "209.390",
    kadin_nufus_yuzde: "49,12%",
    kadin_nufus: "202.138",
    nufus_yuzdesi_genel: "0,50%",
    ilceler: [
      {
        plaka_kodu: "22",
        ilce_kodu: "1295",
        il_adi: "EDİRNE",
        ilce_adi: "MERKEZ",
        nufus: "186.426",
        erkek_nufus: "92.837",
        kadin_nufus: "93.589",
      },
      {
        plaka_kodu: "22",
        ilce_kodu: "1307",
        il_adi: "EDİRNE",
        ilce_adi: "ENEZ",
        nufus: "10.549",
        erkek_nufus: "5.402",
        kadin_nufus: "5.147",
      },
      {
        plaka_kodu: "22",
        ilce_kodu: "1385",
        il_adi: "EDİRNE",
        ilce_adi: "HAVSA",
        nufus: "18.302",
        erkek_nufus: "9.227",
        kadin_nufus: "9.075",
      },
      {
        plaka_kodu: "22",
        ilce_kodu: "1412",
        il_adi: "EDİRNE",
        ilce_adi: "İPSALA",
        nufus: "26.628",
        erkek_nufus: "13.915",
        kadin_nufus: "12.713",
      },
      {
        plaka_kodu: "22",
        ilce_kodu: "1464",
        il_adi: "EDİRNE",
        ilce_adi: "KEŞAN",
        nufus: "83.860",
        erkek_nufus: "42.402",
        kadin_nufus: "41.458",
      },
      {
        plaka_kodu: "22",
        ilce_kodu: "1502",
        il_adi: "EDİRNE",
        ilce_adi: "LALAPAŞA",
        nufus: "6.358",
        erkek_nufus: "3.376",
        kadin_nufus: "2.982",
      },
      {
        plaka_kodu: "22",
        ilce_kodu: "1523",
        il_adi: "EDİRNE",
        ilce_adi: "MERİÇ",
        nufus: "13.168",
        erkek_nufus: "6.693",
        kadin_nufus: "6.475",
      },
      {
        plaka_kodu: "22",
        ilce_kodu: "1988",
        il_adi: "EDİRNE",
        ilce_adi: "SÜLOĞLU",
        nufus: "6.890",
        erkek_nufus: "4.095",
        kadin_nufus: "2.795",
      },
      {
        plaka_kodu: "22",
        ilce_kodu: "1705",
        il_adi: "EDİRNE",
        ilce_adi: "UZUNKÖPRÜ",
        nufus: "59.934",
        erkek_nufus: "29.916",
        kadin_nufus: "30.018",
      },
    ],
    kisa_bilgi:
      "Bademli kavala kurabiyesi, enfes badem ezmesi, meyve sabunları ve peyniri ile meşhur, Trak uygarlığına ilk kez ev sahipliği yaptığı düşünülen Edirne, 1365 yılında Osmanlı’nın başkenti olmuştur.",
  },
  {
    il_adi: "Elazığ",
    plaka_kodu: "23",
    alan_kodu: "424",
    nufus: "591.497",
    bolge: "Doğu Anadolu",
    yuzolcumu: "9.383",
    nufus_artisi: "5,8%",
    erkek_nufus_yuzde: "49,80%",
    erkek_nufus: "296.641",
    kadin_nufus_yuzde: "50,20%",
    kadin_nufus: "298.997",
    nufus_yuzdesi_genel: "0,73%",
    ilceler: [
      {
        plaka_kodu: "23",
        ilce_kodu: "1298",
        il_adi: "ELAZIĞ",
        ilce_adi: "MERKEZ",
        nufus: "443.363",
        erkek_nufus: "218.460",
        kadin_nufus: "224.903",
      },
      {
        plaka_kodu: "23",
        ilce_kodu: "1110",
        il_adi: "ELAZIĞ",
        ilce_adi: "AĞIN",
        nufus: "2.746",
        erkek_nufus: "1.362",
        kadin_nufus: "1.384",
      },
      {
        plaka_kodu: "23",
        ilce_kodu: "1873",
        il_adi: "ELAZIĞ",
        ilce_adi: "ALACAKAYA",
        nufus: "5.993",
        erkek_nufus: "3.143",
        kadin_nufus: "2.850",
      },
      {
        plaka_kodu: "23",
        ilce_kodu: "1762",
        il_adi: "ELAZIĞ",
        ilce_adi: "ARICAK",
        nufus: "13.732",
        erkek_nufus: "7.034",
        kadin_nufus: "6.698",
      },
      {
        plaka_kodu: "23",
        ilce_kodu: "1173",
        il_adi: "ELAZIĞ",
        ilce_adi: "BASKİL",
        nufus: "12.100",
        erkek_nufus: "6.089",
        kadin_nufus: "6.011",
      },
      {
        plaka_kodu: "23",
        ilce_kodu: "1438",
        il_adi: "ELAZIĞ",
        ilce_adi: "KARAKOÇAN",
        nufus: "28.436",
        erkek_nufus: "14.227",
        kadin_nufus: "14.209",
      },
      {
        plaka_kodu: "23",
        ilce_kodu: "1455",
        il_adi: "ELAZIĞ",
        ilce_adi: "KEBAN",
        nufus: "6.210",
        erkek_nufus: "3.036",
        kadin_nufus: "3.174",
      },
      {
        plaka_kodu: "23",
        ilce_kodu: "1820",
        il_adi: "ELAZIĞ",
        ilce_adi: "KOVANCILAR",
        nufus: "39.464",
        erkek_nufus: "19.702",
        kadin_nufus: "19.762",
      },
      {
        plaka_kodu: "23",
        ilce_kodu: "1506",
        il_adi: "ELAZIĞ",
        ilce_adi: "MADEN",
        nufus: "9.693",
        erkek_nufus: "4.917",
        kadin_nufus: "4.776",
      },
      {
        plaka_kodu: "23",
        ilce_kodu: "1566",
        il_adi: "ELAZIĞ",
        ilce_adi: "PALU",
        nufus: "18.648",
        erkek_nufus: "9.591",
        kadin_nufus: "9.057",
      },
      {
        plaka_kodu: "23",
        ilce_kodu: "1631",
        il_adi: "ELAZIĞ",
        ilce_adi: "SİVRİCE",
        nufus: "7.703",
        erkek_nufus: "3.819",
        kadin_nufus: "3.884",
      },
    ],
    kisa_bilgi:
      "Doğu Anadolu Bölgesi'nin Yukarı Fırat Havzasında bulunan, ülkemizin önemli barajlarından biri olan Keban Barajı ile Hazar Gölü’nün ve doğa harikası Buzluk Mağarası’nın bulunduğu Elazığ, Harput’un devamı niteliğindedir.",
  },
  {
    il_adi: "Erzincan",
    plaka_kodu: "24",
    alan_kodu: "446",
    nufus: "239.223",
    bolge: "Doğu Anadolu",
    yuzolcumu: "11.815",
    nufus_artisi: "7,9%",
    erkek_nufus_yuzde: "50,99%",
    erkek_nufus: "120.348",
    kadin_nufus_yuzde: "49,01%",
    kadin_nufus: "115.686",
    nufus_yuzdesi_genel: "0,29%",
    ilceler: [
      {
        plaka_kodu: "24",
        ilce_kodu: "1318",
        il_adi: "ERZİNCAN",
        ilce_adi: "MERKEZ",
        nufus: "163.470",
        erkek_nufus: "82.230",
        kadin_nufus: "81.240",
      },
      {
        plaka_kodu: "24",
        ilce_kodu: "1243",
        il_adi: "ERZİNCAN",
        ilce_adi: "ÇAYIRLI",
        nufus: "8.383",
        erkek_nufus: "4.343",
        kadin_nufus: "4.040",
      },
      {
        plaka_kodu: "24",
        ilce_kodu: "1406",
        il_adi: "ERZİNCAN",
        ilce_adi: "İLİÇ",
        nufus: "9.109",
        erkek_nufus: "4.748",
        kadin_nufus: "4.361",
      },
      {
        plaka_kodu: "24",
        ilce_kodu: "1459",
        il_adi: "ERZİNCAN",
        ilce_adi: "KEMAH",
        nufus: "7.374",
        erkek_nufus: "3.590",
        kadin_nufus: "3.784",
      },
      {
        plaka_kodu: "24",
        ilce_kodu: "1460",
        il_adi: "ERZİNCAN",
        ilce_adi: "KEMALİYE",
        nufus: "5.033",
        erkek_nufus: "2.724",
        kadin_nufus: "2.309",
      },
      {
        plaka_kodu: "24",
        ilce_kodu: "1977",
        il_adi: "ERZİNCAN",
        ilce_adi: "OTLUKBELİ",
        nufus: "2.577",
        erkek_nufus: "1.346",
        kadin_nufus: "1.231",
      },
      {
        plaka_kodu: "24",
        ilce_kodu: "1583",
        il_adi: "ERZİNCAN",
        ilce_adi: "REFAHİYE",
        nufus: "11.367",
        erkek_nufus: "5.924",
        kadin_nufus: "5.443",
      },
      {
        plaka_kodu: "24",
        ilce_kodu: "1675",
        il_adi: "ERZİNCAN",
        ilce_adi: "TERCAN",
        nufus: "16.110",
        erkek_nufus: "8.404",
        kadin_nufus: "7.706",
      },
      {
        plaka_kodu: "24",
        ilce_kodu: "1853",
        il_adi: "ERZİNCAN",
        ilce_adi: "ÜZÜMLÜ",
        nufus: "13.928",
        erkek_nufus: "7.058",
        kadin_nufus: "6.870",
      },
    ],
    kisa_bilgi:
      "Tarihi İpek Yolu üzerinde yer alan ve ülkemizin Doğu Anadolu Yukarı Fırat bölümünde bulunan Erzincan, tarih boyunca farklı medeniyetlere ev sahipliği yapan önemli bir yer olmuştur. Altıntepe’de yer alan Urartu kalıntıları şehrin tarihi hakkında fikir verecektir.",
  },
  {
    il_adi: "Erzurum",
    plaka_kodu: "25",
    alan_kodu: "42",
    nufus: "749.754",
    bolge: "Doğu Anadolu",
    yuzolcumu: "25.006",
    nufus_artisi: "-9,5%",
    erkek_nufus_yuzde: "49,94%",
    erkek_nufus: "383.435",
    kadin_nufus_yuzde: "50,06%",
    kadin_nufus: "384.413",
    nufus_yuzdesi_genel: "0,94%",
    ilceler: [
      {
        plaka_kodu: "25",
        ilce_kodu: "1153",
        il_adi: "ERZURUM",
        ilce_adi: "AŞKALE",
        nufus: "22.170",
        erkek_nufus: "11.091",
        kadin_nufus: "11.079",
      },
      {
        plaka_kodu: "25",
        ilce_kodu: "1945",
        il_adi: "ERZURUM",
        ilce_adi: "AZİZİYE",
        nufus: "65.133",
        erkek_nufus: "34.441",
        kadin_nufus: "30.692",
      },
      {
        plaka_kodu: "25",
        ilce_kodu: "1235",
        il_adi: "ERZURUM",
        ilce_adi: "ÇAT",
        nufus: "16.239",
        erkek_nufus: "8.441",
        kadin_nufus: "7.798",
      },
      {
        plaka_kodu: "25",
        ilce_kodu: "1392",
        il_adi: "ERZURUM",
        ilce_adi: "HINIS",
        nufus: "25.480",
        erkek_nufus: "12.952",
        kadin_nufus: "12.528",
      },
      {
        plaka_kodu: "25",
        ilce_kodu: "1396",
        il_adi: "ERZURUM",
        ilce_adi: "HORASAN",
        nufus: "37.327",
        erkek_nufus: "18.961",
        kadin_nufus: "18.366",
      },
      {
        plaka_kodu: "25",
        ilce_kodu: "1416",
        il_adi: "ERZURUM",
        ilce_adi: "İSPİR",
        nufus: "14.955",
        erkek_nufus: "7.419",
        kadin_nufus: "7.536",
      },
      {
        plaka_kodu: "25",
        ilce_kodu: "1812",
        il_adi: "ERZURUM",
        ilce_adi: "KARAÇOBAN",
        nufus: "23.055",
        erkek_nufus: "11.723",
        kadin_nufus: "11.332",
      },
      {
        plaka_kodu: "25",
        ilce_kodu: "1444",
        il_adi: "ERZURUM",
        ilce_adi: "KARAYAZI",
        nufus: "26.720",
        erkek_nufus: "13.609",
        kadin_nufus: "13.111",
      },
      {
        plaka_kodu: "25",
        ilce_kodu: "1967",
        il_adi: "ERZURUM",
        ilce_adi: "KÖPRÜKÖY",
        nufus: "14.939",
        erkek_nufus: "7.694",
        kadin_nufus: "7.245",
      },
      {
        plaka_kodu: "25",
        ilce_kodu: "1540",
        il_adi: "ERZURUM",
        ilce_adi: "NARMAN",
        nufus: "12.767",
        erkek_nufus: "6.379",
        kadin_nufus: "6.388",
      },
      {
        plaka_kodu: "25",
        ilce_kodu: "1550",
        il_adi: "ERZURUM",
        ilce_adi: "OLTU",
        nufus: "30.316",
        erkek_nufus: "15.376",
        kadin_nufus: "14.940",
      },
      {
        plaka_kodu: "25",
        ilce_kodu: "1551",
        il_adi: "ERZURUM",
        ilce_adi: "OLUR",
        nufus: "6.367",
        erkek_nufus: "3.177",
        kadin_nufus: "3.190",
      },
      {
        plaka_kodu: "25",
        ilce_kodu: "2044",
        il_adi: "ERZURUM",
        ilce_adi: "PALANDÖKEN",
        nufus: "175.920",
        erkek_nufus: "86.465",
        kadin_nufus: "89.455",
      },
      {
        plaka_kodu: "25",
        ilce_kodu: "1567",
        il_adi: "ERZURUM",
        ilce_adi: "PASİNLER",
        nufus: "27.697",
        erkek_nufus: "14.091",
        kadin_nufus: "13.606",
      },
      {
        plaka_kodu: "25",
        ilce_kodu: "1865",
        il_adi: "ERZURUM",
        ilce_adi: "PAZARYOLU",
        nufus: "3.762",
        erkek_nufus: "1.897",
        kadin_nufus: "1.865",
      },
      {
        plaka_kodu: "25",
        ilce_kodu: "1657",
        il_adi: "ERZURUM",
        ilce_adi: "ŞENKAYA",
        nufus: "16.821",
        erkek_nufus: "8.440",
        kadin_nufus: "8.381",
      },
      {
        plaka_kodu: "25",
        ilce_kodu: "1674",
        il_adi: "ERZURUM",
        ilce_adi: "TEKMAN",
        nufus: "24.631",
        erkek_nufus: "12.472",
        kadin_nufus: "12.159",
      },
      {
        plaka_kodu: "25",
        ilce_kodu: "1683",
        il_adi: "ERZURUM",
        ilce_adi: "TORTUM",
        nufus: "17.545",
        erkek_nufus: "8.763",
        kadin_nufus: "8.782",
      },
      {
        plaka_kodu: "25",
        ilce_kodu: "1851",
        il_adi: "ERZURUM",
        ilce_adi: "UZUNDERE",
        nufus: "7.800",
        erkek_nufus: "3.913",
        kadin_nufus: "3.887",
      },
      {
        plaka_kodu: "25",
        ilce_kodu: "2045",
        il_adi: "ERZURUM",
        ilce_adi: "YAKUTİYE",
        nufus: "187.249",
        erkek_nufus: "89.957",
        kadin_nufus: "97.292",
      },
    ],
    kisa_bilgi:
      "23 Temmuz-7 Ağustos 1919 tarihleri arasında gerçekleştirilen ve Kurtuluş Mücadelesi'nde önemli ölçüde belirleyici kararların alındığı Erzurum Kongresi’ne ev sahipliği yapan ilin tarihimizde önemli bir yeri var. ",
  },
  {
    il_adi: "Eskişehir",
    plaka_kodu: "26",
    alan_kodu: "222",
    nufus: "906.617",
    bolge: "İç Anadolu",
    yuzolcumu: "13.960",
    nufus_artisi: "9,1%",
    erkek_nufus_yuzde: "49,83%",
    erkek_nufus: "434.112",
    kadin_nufus_yuzde: "50,17%",
    kadin_nufus: "437.075",
    nufus_yuzdesi_genel: "1,06%",
    ilceler: [
      {
        plaka_kodu: "26",
        ilce_kodu: "1759",
        il_adi: "ESKİŞEHİR",
        ilce_adi: "ALPU",
        nufus: "10.178",
        erkek_nufus: "5.353",
        kadin_nufus: "4.825",
      },
      {
        plaka_kodu: "26",
        ilce_kodu: "1777",
        il_adi: "ESKİŞEHİR",
        ilce_adi: "BEYLİKOVA",
        nufus: "6.010",
        erkek_nufus: "3.121",
        kadin_nufus: "2.889",
      },
      {
        plaka_kodu: "26",
        ilce_kodu: "1255",
        il_adi: "ESKİŞEHİR",
        ilce_adi: "ÇİFTELER",
        nufus: "14.886",
        erkek_nufus: "7.568",
        kadin_nufus: "7.318",
      },
      {
        plaka_kodu: "26",
        ilce_kodu: "1934",
        il_adi: "ESKİŞEHİR",
        ilce_adi: "GÜNYÜZÜ",
        nufus: "5.304",
        erkek_nufus: "2.657",
        kadin_nufus: "2.647",
      },
      {
        plaka_kodu: "26",
        ilce_kodu: "1939",
        il_adi: "ESKİŞEHİR",
        ilce_adi: "HAN",
        nufus: "2.045",
        erkek_nufus: "1.088",
        kadin_nufus: "957",
      },
      {
        plaka_kodu: "26",
        ilce_kodu: "1808",
        il_adi: "ESKİŞEHİR",
        ilce_adi: "İNÖNÜ",
        nufus: "6.216",
        erkek_nufus: "3.213",
        kadin_nufus: "3.003",
      },
      {
        plaka_kodu: "26",
        ilce_kodu: "1508",
        il_adi: "ESKİŞEHİR",
        ilce_adi: "MAHMUDİYE",
        nufus: "7.713",
        erkek_nufus: "3.965",
        kadin_nufus: "3.748",
      },
      {
        plaka_kodu: "26",
        ilce_kodu: "1973",
        il_adi: "ESKİŞEHİR",
        ilce_adi: "MİHALGAZİ",
        nufus: "3.030",
        erkek_nufus: "1.519",
        kadin_nufus: "1.511",
      },
      {
        plaka_kodu: "26",
        ilce_kodu: "1527",
        il_adi: "ESKİŞEHİR",
        ilce_adi: "MİHALIÇÇIK",
        nufus: "7.800",
        erkek_nufus: "4.011",
        kadin_nufus: "3.789",
      },
      {
        plaka_kodu: "26",
        ilce_kodu: "2046",
        il_adi: "ESKİŞEHİR",
        ilce_adi: "ODUNPAZARI",
        nufus: "419.114",
        erkek_nufus: "206.405",
        kadin_nufus: "212.709",
      },
      {
        plaka_kodu: "26",
        ilce_kodu: "1599",
        il_adi: "ESKİŞEHİR",
        ilce_adi: "SARICAKAYA",
        nufus: "4.611",
        erkek_nufus: "2.304",
        kadin_nufus: "2.307",
      },
      {
        plaka_kodu: "26",
        ilce_kodu: "1618",
        il_adi: "ESKİŞEHİR",
        ilce_adi: "SEYİTGAZİ",
        nufus: "12.651",
        erkek_nufus: "6.557",
        kadin_nufus: "6.094",
      },
      {
        plaka_kodu: "26",
        ilce_kodu: "1632",
        il_adi: "ESKİŞEHİR",
        ilce_adi: "SİVRİHİSAR",
        nufus: "20.217",
        erkek_nufus: "10.331",
        kadin_nufus: "9.886",
      },
      {
        plaka_kodu: "26",
        ilce_kodu: "2047",
        il_adi: "ESKİŞEHİR",
        ilce_adi: "TEPEBAŞI",
        nufus: "378.594",
        erkek_nufus: "189.669",
        kadin_nufus: "188.925",
      },
    ],
    kisa_bilgi:
      "Ortasından Porsuk Çayı geçen, 'öğrenci şehri' diye anılan, Met helvası, Nuga helvası, Haşhaşlı çöreği, Kalabak suyu ve çiböreği ile meşhur Eskişehir, ayrıca ülkemizde işlenebilir lületaşı çıkarılan tek yerdir.",
  },
  {
    il_adi: "Gaziantep",
    plaka_kodu: "27",
    alan_kodu: "342",
    nufus: "2.154.051",
    bolge: "Güneydoğu Anadolu",
    yuzolcumu: "6.803",
    nufus_artisi: "11,0%",
    erkek_nufus_yuzde: "50,47%",
    erkek_nufus: "1.023.716",
    kadin_nufus_yuzde: "49,53%",
    kadin_nufus: "1.004.847",
    nufus_yuzdesi_genel: "2,47%",
    ilceler: [
      {
        plaka_kodu: "27",
        ilce_kodu: "1139",
        il_adi: "GAZİANTEP",
        ilce_adi: "ARABAN",
        nufus: "33.165",
        erkek_nufus: "16.667",
        kadin_nufus: "16.498",
      },
      {
        plaka_kodu: "27",
        ilce_kodu: "1415",
        il_adi: "GAZİANTEP",
        ilce_adi: "İSLAHİYE",
        nufus: "67.337",
        erkek_nufus: "34.548",
        kadin_nufus: "32.789",
      },
      {
        plaka_kodu: "27",
        ilce_kodu: "1956",
        il_adi: "GAZİANTEP",
        ilce_adi: "KARKAMIŞ",
        nufus: "9.616",
        erkek_nufus: "5.044",
        kadin_nufus: "4.572",
      },
      {
        plaka_kodu: "27",
        ilce_kodu: "1546",
        il_adi: "GAZİANTEP",
        ilce_adi: "NİZİP",
        nufus: "147.865",
        erkek_nufus: "74.251",
        kadin_nufus: "73.614",
      },
      {
        plaka_kodu: "27",
        ilce_kodu: "1974",
        il_adi: "GAZİANTEP",
        ilce_adi: "NURDAĞI",
        nufus: "40.843",
        erkek_nufus: "20.646",
        kadin_nufus: "20.197",
      },
      {
        plaka_kodu: "27",
        ilce_kodu: "1549",
        il_adi: "GAZİANTEP",
        ilce_adi: "OĞUZELİ",
        nufus: "33.044",
        erkek_nufus: "17.002",
        kadin_nufus: "16.042",
      },
      {
        plaka_kodu: "27",
        ilce_kodu: "1841",
        il_adi: "GAZİANTEP",
        ilce_adi: "ŞAHİNBEY",
        nufus: "936.351",
        erkek_nufus: "471.289",
        kadin_nufus: "465.062",
      },
      {
        plaka_kodu: "27",
        ilce_kodu: "1844",
        il_adi: "GAZİANTEP",
        ilce_adi: "ŞEHİTKAMİL",
        nufus: "839.553",
        erkek_nufus: "424.422",
        kadin_nufus: "415.131",
      },
      {
        plaka_kodu: "27",
        ilce_kodu: "1720",
        il_adi: "GAZİANTEP",
        ilce_adi: "YAVUZELİ",
        nufus: "22.658",
        erkek_nufus: "11.540",
        kadin_nufus: "11.118",
      },
    ],
    kisa_bilgi:
      "1987 yılında büyükşehir unvanını alan, Mezopotomya ve Akdeniz arasında, İpek Yolu üzerinde yer alan Antep, tarihin en eski yerleşim birimlerinden biri olarak kabul edilen Dülük antik kentinin de bulunduğu şehrimizdir.",
  },
  {
    il_adi: "Giresun",
    plaka_kodu: "28",
    alan_kodu: "454",
    nufus: "450.862",
    bolge: "Karadeniz",
    yuzolcumu: "7.025",
    nufus_artisi: "1,6%",
    erkek_nufus_yuzde: "49,80%",
    erkek_nufus: "226.067",
    kadin_nufus_yuzde: "50,20%",
    kadin_nufus: "227.845",
    nufus_yuzdesi_genel: "0,55%",
    ilceler: [
      {
        plaka_kodu: "28",
        ilce_kodu: "1352",
        il_adi: "GİRESUN",
        ilce_adi: "MERKEZ",
        nufus: "143.503",
        erkek_nufus: "70.256",
        kadin_nufus: "73.247",
      },
      {
        plaka_kodu: "28",
        ilce_kodu: "1133",
        il_adi: "GİRESUN",
        ilce_adi: "ALUCRA",
        nufus: "8.892",
        erkek_nufus: "4.498",
        kadin_nufus: "4.394",
      },
      {
        plaka_kodu: "28",
        ilce_kodu: "1212",
        il_adi: "GİRESUN",
        ilce_adi: "BULANCAK",
        nufus: "69.326",
        erkek_nufus: "34.614",
        kadin_nufus: "34.712",
      },
      {
        plaka_kodu: "28",
        ilce_kodu: "1893",
        il_adi: "GİRESUN",
        ilce_adi: "ÇAMOLUK",
        nufus: "6.735",
        erkek_nufus: "3.495",
        kadin_nufus: "3.240",
      },
      {
        plaka_kodu: "28",
        ilce_kodu: "1894",
        il_adi: "GİRESUN",
        ilce_adi: "ÇANAKÇI",
        nufus: "6.111",
        erkek_nufus: "3.076",
        kadin_nufus: "3.035",
      },
      {
        plaka_kodu: "28",
        ilce_kodu: "1272",
        il_adi: "GİRESUN",
        ilce_adi: "DERELİ",
        nufus: "18.993",
        erkek_nufus: "9.695",
        kadin_nufus: "9.298",
      },
      {
        plaka_kodu: "28",
        ilce_kodu: "1912",
        il_adi: "GİRESUN",
        ilce_adi: "DOĞANKENT",
        nufus: "6.508",
        erkek_nufus: "3.340",
        kadin_nufus: "3.168",
      },
      {
        plaka_kodu: "28",
        ilce_kodu: "1320",
        il_adi: "GİRESUN",
        ilce_adi: "ESPİYE",
        nufus: "37.184",
        erkek_nufus: "19.739",
        kadin_nufus: "17.445",
      },
      {
        plaka_kodu: "28",
        ilce_kodu: "1324",
        il_adi: "GİRESUN",
        ilce_adi: "EYNESİL",
        nufus: "12.581",
        erkek_nufus: "6.036",
        kadin_nufus: "6.545",
      },
      {
        plaka_kodu: "28",
        ilce_kodu: "1361",
        il_adi: "GİRESUN",
        ilce_adi: "GÖRELE",
        nufus: "31.138",
        erkek_nufus: "15.436",
        kadin_nufus: "15.702",
      },
      {
        plaka_kodu: "28",
        ilce_kodu: "1930",
        il_adi: "GİRESUN",
        ilce_adi: "GÜCE",
        nufus: "8.098",
        erkek_nufus: "4.131",
        kadin_nufus: "3.967",
      },
      {
        plaka_kodu: "28",
        ilce_kodu: "1465",
        il_adi: "GİRESUN",
        ilce_adi: "KEŞAP",
        nufus: "19.268",
        erkek_nufus: "9.521",
        kadin_nufus: "9.747",
      },
      {
        plaka_kodu: "28",
        ilce_kodu: "1837",
        il_adi: "GİRESUN",
        ilce_adi: "PİRAZİZ",
        nufus: "14.138",
        erkek_nufus: "6.722",
        kadin_nufus: "7.416",
      },
      {
        plaka_kodu: "28",
        ilce_kodu: "1654",
        il_adi: "GİRESUN",
        ilce_adi: "ŞEBİNKARAHİSAR",
        nufus: "19.557",
        erkek_nufus: "9.902",
        kadin_nufus: "9.655",
      },
      {
        plaka_kodu: "28",
        ilce_kodu: "1678",
        il_adi: "GİRESUN",
        ilce_adi: "TİREBOLU",
        nufus: "32.494",
        erkek_nufus: "16.066",
        kadin_nufus: "16.428",
      },
      {
        plaka_kodu: "28",
        ilce_kodu: "1854",
        il_adi: "GİRESUN",
        ilce_adi: "YAĞLIDERE",
        nufus: "15.628",
        erkek_nufus: "7.853",
        kadin_nufus: "7.775",
      },
    ],
    kisa_bilgi:
      "Dünyaca meşhur, parlak kabuklu, yağ oranı yüksek ve tombul fındığı, Fiskobirlik’in genel müdürlüğüne de ev sahipliği yapması, doğal ve kültürel zenginlikleri, hâlâ sürdürülen gelenek ve görenekleriyle Giresun, keşfedilesi şehirlerimizden biri.",
  },
  {
    il_adi: "Gümüşhane",
    plaka_kodu: "29",
    alan_kodu: "456",
    nufus: "144.544",
    bolge: "Karadeniz",
    yuzolcumu: "6.668",
    nufus_artisi: "-37,8%",
    erkek_nufus_yuzde: "50,67%",
    erkek_nufus: "82.468",
    kadin_nufus_yuzde: "49,33%",
    kadin_nufus: "80.280",
    nufus_yuzdesi_genel: "0,20%",
    ilceler: [
      {
        plaka_kodu: "29",
        ilce_kodu: "1369",
        il_adi: "GÜMÜŞHANE",
        ilce_adi: "MERKEZ",
        nufus: "54.108",
        erkek_nufus: "26.775",
        kadin_nufus: "27.333",
      },
      {
        plaka_kodu: "29",
        ilce_kodu: "1458",
        il_adi: "GÜMÜŞHANE",
        ilce_adi: "KELKİT",
        nufus: "44.068",
        erkek_nufus: "22.368",
        kadin_nufus: "21.700",
      },
      {
        plaka_kodu: "29",
        ilce_kodu: "1822",
        il_adi: "GÜMÜŞHANE",
        ilce_adi: "KÖSE",
        nufus: "7.774",
        erkek_nufus: "3.932",
        kadin_nufus: "3.842",
      },
      {
        plaka_kodu: "29",
        ilce_kodu: "1971",
        il_adi: "GÜMÜŞHANE",
        ilce_adi: "KÜRTÜN",
        nufus: "12.456",
        erkek_nufus: "6.449",
        kadin_nufus: "6.007",
      },
      {
        plaka_kodu: "29",
        ilce_kodu: "1660",
        il_adi: "GÜMÜŞHANE",
        ilce_adi: "ŞİRAN",
        nufus: "20.224",
        erkek_nufus: "10.100",
        kadin_nufus: "10.124",
      },
      {
        plaka_kodu: "29",
        ilce_kodu: "1684",
        il_adi: "GÜMÜŞHANE",
        ilce_adi: "TORUL",
        nufus: "11.489",
        erkek_nufus: "5.857",
        kadin_nufus: "5.632",
      },
    ],
    kisa_bilgi:
      "Doğu Karadeniz’in iç kesiminde yer alan, Artabel Gölleri Tabiat Parkı ile Kuluca, Şiran Dağları gibi koruma altındaki doğal güzelliklere sahip Gümüşhane, kış turizmi açısından da dikkat çekiyor.",
  },
  {
    il_adi: "Hakkari",
    plaka_kodu: "30",
    alan_kodu: "438",
    nufus: "275.333",
    bolge: "Doğu Anadolu",
    yuzolcumu: "7.095",
    nufus_artisi: "-10,4%",
    erkek_nufus_yuzde: "54,84%",
    erkek_nufus: "157.107",
    kadin_nufus_yuzde: "45,16%",
    kadin_nufus: "129.363",
    nufus_yuzdesi_genel: "0,35%",
    ilceler: [
      {
        plaka_kodu: "30",
        ilce_kodu: "1377",
        il_adi: "HAKKARİ",
        ilce_adi: "MERKEZ",
        nufus: "77.606",
        erkek_nufus: "40.696",
        kadin_nufus: "36.910",
      },
      {
        plaka_kodu: "30",
        ilce_kodu: "1261",
        il_adi: "HAKKARİ",
        ilce_adi: "ÇUKURCA",
        nufus: "15.652",
        erkek_nufus: "10.159",
        kadin_nufus: "5.493",
      },
      {
        plaka_kodu: "30",
        ilce_kodu: "2107",
        il_adi: "HAKKARİ",
        ilce_adi: "DERECİK",
        nufus: "23.140",
        erkek_nufus: "12.127",
        kadin_nufus: "11.013",
      },
      {
        plaka_kodu: "30",
        ilce_kodu: "1656",
        il_adi: "HAKKARİ",
        ilce_adi: "ŞEMDİNLİ",
        nufus: "42.626",
        erkek_nufus: "22.219",
        kadin_nufus: "20.407",
      },
      {
        plaka_kodu: "30",
        ilce_kodu: "1737",
        il_adi: "HAKKARİ",
        ilce_adi: "YÜKSEKOVA",
        nufus: "119.194",
        erkek_nufus: "61.412",
        kadin_nufus: "57.782",
      },
    ],
    kisa_bilgi:
      "Güneyinde Irak, doğusunda İran ile komşu olan Hakkari, tarih boyunca Urartu, Pers, Arap ardından Selçuklu hakimiyetinde bulunup, 16. yy’da ise Osmanlı İmparatorluğuna dahil edildi. ",
  },
  {
    il_adi: "Hatay",
    plaka_kodu: "31",
    alan_kodu: "326",
    nufus: "1.686.043",
    bolge: "Güneydoğu Anadolu",
    yuzolcumu: "5.524",
    nufus_artisi: "9,1%",
    erkek_nufus_yuzde: "50,28%",
    erkek_nufus: "809.484",
    kadin_nufus_yuzde: "49,72%",
    kadin_nufus: "800.372",
    nufus_yuzdesi_genel: "1,96%",
    ilceler: [
      {
        plaka_kodu: "31",
        ilce_kodu: "1131",
        il_adi: "HATAY",
        ilce_adi: "ALTINÖZÜ",
        nufus: "60.861",
        erkek_nufus: "31.213",
        kadin_nufus: "29.648",
      },
      {
        plaka_kodu: "31",
        ilce_kodu: "2080",
        il_adi: "HATAY",
        ilce_adi: "ANTAKYA",
        nufus: "393.634",
        erkek_nufus: "197.227",
        kadin_nufus: "196.407",
      },
      {
        plaka_kodu: "31",
        ilce_kodu: "2081",
        il_adi: "HATAY",
        ilce_adi: "ARSUZ",
        nufus: "99.480",
        erkek_nufus: "51.017",
        kadin_nufus: "48.463",
      },
      {
        plaka_kodu: "31",
        ilce_kodu: "1887",
        il_adi: "HATAY",
        ilce_adi: "BELEN",
        nufus: "34.134",
        erkek_nufus: "17.259",
        kadin_nufus: "16.875",
      },
      {
        plaka_kodu: "31",
        ilce_kodu: "2082",
        il_adi: "HATAY",
        ilce_adi: "DEFNE",
        nufus: "162.199",
        erkek_nufus: "79.891",
        kadin_nufus: "82.308",
      },
      {
        plaka_kodu: "31",
        ilce_kodu: "1289",
        il_adi: "HATAY",
        ilce_adi: "DÖRTYOL",
        nufus: "127.989",
        erkek_nufus: "64.323",
        kadin_nufus: "63.666",
      },
      {
        plaka_kodu: "31",
        ilce_kodu: "1792",
        il_adi: "HATAY",
        ilce_adi: "ERZİN",
        nufus: "41.677",
        erkek_nufus: "20.964",
        kadin_nufus: "20.713",
      },
      {
        plaka_kodu: "31",
        ilce_kodu: "1382",
        il_adi: "HATAY",
        ilce_adi: "HASSA",
        nufus: "56.952",
        erkek_nufus: "29.085",
        kadin_nufus: "27.867",
      },
      {
        plaka_kodu: "31",
        ilce_kodu: "1413",
        il_adi: "HATAY",
        ilce_adi: "İSKENDERUN",
        nufus: "250.976",
        erkek_nufus: "126.008",
        kadin_nufus: "124.968",
      },
      {
        plaka_kodu: "31",
        ilce_kodu: "1468",
        il_adi: "HATAY",
        ilce_adi: "KIRIKHAN",
        nufus: "119.854",
        erkek_nufus: "60.202",
        kadin_nufus: "59.652",
      },
      {
        plaka_kodu: "31",
        ilce_kodu: "1970",
        il_adi: "HATAY",
        ilce_adi: "KUMLU",
        nufus: "13.384",
        erkek_nufus: "6.931",
        kadin_nufus: "6.453",
      },
      {
        plaka_kodu: "31",
        ilce_kodu: "2083",
        il_adi: "HATAY",
        ilce_adi: "PAYAS",
        nufus: "43.495",
        erkek_nufus: "21.939",
        kadin_nufus: "21.556",
      },
      {
        plaka_kodu: "31",
        ilce_kodu: "1585",
        il_adi: "HATAY",
        ilce_adi: "REYHANLI",
        nufus: "105.309",
        erkek_nufus: "53.208",
        kadin_nufus: "52.101",
      },
      {
        plaka_kodu: "31",
        ilce_kodu: "1591",
        il_adi: "HATAY",
        ilce_adi: "SAMANDAĞ",
        nufus: "123.999",
        erkek_nufus: "60.740",
        kadin_nufus: "63.259",
      },
      {
        plaka_kodu: "31",
        ilce_kodu: "1721",
        il_adi: "HATAY",
        ilce_adi: "YAYLADAĞI",
        nufus: "36.769",
        erkek_nufus: "20.190",
        kadin_nufus: "16.579",
      },
    ],
    kisa_bilgi:
      "Doğusunda ve güneyinde Suriye, batısında Akdeniz, kuzeybatısında Adana, kuzeyinde Osmaniye ve kuzeydoğusunda Gaziantep’in yer aldığı Hatay, arkeolojik olarak da oldukça değerli ve eski bir yerleşim yeridir.",
  },
  {
    il_adi: "Isparta",
    plaka_kodu: "32",
    alan_kodu: "246",
    nufus: "445.325",
    bolge: "Akdeniz",
    yuzolcumu: "8.946",
    nufus_artisi: "-0,8%",
    erkek_nufus_yuzde: "49,77%",
    erkek_nufus: "219.681",
    kadin_nufus_yuzde: "50,23%",
    kadin_nufus: "221.731",
    nufus_yuzdesi_genel: "0,54%",
    ilceler: [
      {
        plaka_kodu: "32",
        ilce_kodu: "1401",
        il_adi: "ISPARTA",
        ilce_adi: "MERKEZ",
        nufus: "266.982",
        erkek_nufus: "131.538",
        kadin_nufus: "135.444",
      },
      {
        plaka_kodu: "32",
        ilce_kodu: "1755",
        il_adi: "ISPARTA",
        ilce_adi: "AKSU",
        nufus: "4.233",
        erkek_nufus: "2.106",
        kadin_nufus: "2.127",
      },
      {
        plaka_kodu: "32",
        ilce_kodu: "1154",
        il_adi: "ISPARTA",
        ilce_adi: "ATABEY",
        nufus: "5.790",
        erkek_nufus: "2.846",
        kadin_nufus: "2.944",
      },
      {
        plaka_kodu: "32",
        ilce_kodu: "1297",
        il_adi: "ISPARTA",
        ilce_adi: "EĞİRDİR",
        nufus: "31.308",
        erkek_nufus: "15.794",
        kadin_nufus: "15.514",
      },
      {
        plaka_kodu: "32",
        ilce_kodu: "1341",
        il_adi: "ISPARTA",
        ilce_adi: "GELENDOST",
        nufus: "15.059",
        erkek_nufus: "7.437",
        kadin_nufus: "7.622",
      },
      {
        plaka_kodu: "32",
        ilce_kodu: "1929",
        il_adi: "ISPARTA",
        ilce_adi: "GÖNEN",
        nufus: "7.003",
        erkek_nufus: "3.522",
        kadin_nufus: "3.481",
      },
      {
        plaka_kodu: "32",
        ilce_kodu: "1456",
        il_adi: "ISPARTA",
        ilce_adi: "KEÇİBORLU",
        nufus: "13.984",
        erkek_nufus: "7.134",
        kadin_nufus: "6.850",
      },
      {
        plaka_kodu: "32",
        ilce_kodu: "1615",
        il_adi: "ISPARTA",
        ilce_adi: "SENİRKENT",
        nufus: "10.977",
        erkek_nufus: "5.347",
        kadin_nufus: "5.630",
      },
      {
        plaka_kodu: "32",
        ilce_kodu: "1648",
        il_adi: "ISPARTA",
        ilce_adi: "SÜTÇÜLER",
        nufus: "10.081",
        erkek_nufus: "5.077",
        kadin_nufus: "5.004",
      },
      {
        plaka_kodu: "32",
        ilce_kodu: "1651",
        il_adi: "ISPARTA",
        ilce_adi: "ŞARKİKARAAĞAÇ",
        nufus: "25.109",
        erkek_nufus: "12.282",
        kadin_nufus: "12.827",
      },
      {
        plaka_kodu: "32",
        ilce_kodu: "1699",
        il_adi: "ISPARTA",
        ilce_adi: "ULUBORLU",
        nufus: "6.383",
        erkek_nufus: "3.302",
        kadin_nufus: "3.081",
      },
      {
        plaka_kodu: "32",
        ilce_kodu: "1717",
        il_adi: "ISPARTA",
        ilce_adi: "YALVAÇ",
        nufus: "46.177",
        erkek_nufus: "22.999",
        kadin_nufus: "23.178",
      },
      {
        plaka_kodu: "32",
        ilce_kodu: "2001",
        il_adi: "ISPARTA",
        ilce_adi: "YENİŞARBADEMLİ",
        nufus: "2.592",
        erkek_nufus: "1.315",
        kadin_nufus: "1.277",
      },
    ],
    kisa_bilgi:
      "Lezzetli, uzun saplı, sert meyveli kirazı, gülü ve halısıyla meşhur, ayrıca ülkemizde en çok elma üretiminin yapıldığı Isparta, Göller bölgesinde yer alıyor. ",
  },
  {
    il_adi: "Mersin",
    plaka_kodu: "33",
    alan_kodu: "324",
    nufus: "1.916.432",
    bolge: "Akdeniz",
    yuzolcumu: "16.010",
    nufus_artisi: "13,3%",
    erkek_nufus_yuzde: "49,91%",
    erkek_nufus: "905.520",
    kadin_nufus_yuzde: "50,09%",
    kadin_nufus: "908.948",
    nufus_yuzdesi_genel: "2,21%",
    ilceler: [
      {
        plaka_kodu: "33",
        ilce_kodu: "2064",
        il_adi: "MERSİN",
        ilce_adi: "AKDENİZ",
        nufus: "255.682",
        erkek_nufus: "130.209",
        kadin_nufus: "125.473",
      },
      {
        plaka_kodu: "33",
        ilce_kodu: "1135",
        il_adi: "MERSİN",
        ilce_adi: "ANAMUR",
        nufus: "66.828",
        erkek_nufus: "33.786",
        kadin_nufus: "33.042",
      },
      {
        plaka_kodu: "33",
        ilce_kodu: "1766",
        il_adi: "MERSİN",
        ilce_adi: "AYDINCIK",
        nufus: "11.314",
        erkek_nufus: "5.735",
        kadin_nufus: "5.579",
      },
      {
        plaka_kodu: "33",
        ilce_kodu: "1779",
        il_adi: "MERSİN",
        ilce_adi: "BOZYAZI",
        nufus: "26.910",
        erkek_nufus: "13.553",
        kadin_nufus: "13.357",
      },
      {
        plaka_kodu: "33",
        ilce_kodu: "1892",
        il_adi: "MERSİN",
        ilce_adi: "ÇAMLIYAYLA",
        nufus: "8.208",
        erkek_nufus: "4.246",
        kadin_nufus: "3.962",
      },
      {
        plaka_kodu: "33",
        ilce_kodu: "1311",
        il_adi: "MERSİN",
        ilce_adi: "ERDEMLİ",
        nufus: "147.512",
        erkek_nufus: "73.612",
        kadin_nufus: "73.900",
      },
      {
        plaka_kodu: "33",
        ilce_kodu: "1366",
        il_adi: "MERSİN",
        ilce_adi: "GÜLNAR",
        nufus: "27.557",
        erkek_nufus: "14.879",
        kadin_nufus: "12.678",
      },
      {
        plaka_kodu: "33",
        ilce_kodu: "2065",
        il_adi: "MERSİN",
        ilce_adi: "MEZİTLİ",
        nufus: "218.816",
        erkek_nufus: "106.285",
        kadin_nufus: "112.531",
      },
      {
        plaka_kodu: "33",
        ilce_kodu: "1536",
        il_adi: "MERSİN",
        ilce_adi: "MUT",
        nufus: "62.803",
        erkek_nufus: "31.375",
        kadin_nufus: "31.428",
      },
      {
        plaka_kodu: "33",
        ilce_kodu: "1621",
        il_adi: "MERSİN",
        ilce_adi: "SİLİFKE",
        nufus: "127.849",
        erkek_nufus: "65.107",
        kadin_nufus: "62.742",
      },
      {
        plaka_kodu: "33",
        ilce_kodu: "1665",
        il_adi: "MERSİN",
        ilce_adi: "TARSUS",
        nufus: "347.314",
        erkek_nufus: "175.096",
        kadin_nufus: "172.218",
      },
      {
        plaka_kodu: "33",
        ilce_kodu: "2066",
        il_adi: "MERSİN",
        ilce_adi: "TOROSLAR",
        nufus: "315.408",
        erkek_nufus: "158.363",
        kadin_nufus: "157.045",
      },
      {
        plaka_kodu: "33",
        ilce_kodu: "2067",
        il_adi: "MERSİN",
        ilce_adi: "YENİŞEHİR",
        nufus: "274.944",
        erkek_nufus: "132.146",
        kadin_nufus: "142.798",
      },
    ],
    kisa_bilgi:
      "Türkiye'nin güneyinde bulunan Mersin, ülkenin en büyük limanı (Mersin Limanı) ile bir liman kentidir. Mersin Serbest Bölgesi, tekstil firmaları yoğunlukta olmak üzere pek çok şirkete ev sahipliği yapar. ",
  },
  {
    il_adi: "İstanbul",
    plaka_kodu: "34",
    alan_kodu: "212-216",
    nufus: "15.907.951",
    bolge: "Marmara",
    yuzolcumu: "5.461",
    nufus_artisi: "4,2%",
    erkek_nufus_yuzde: "50,06%",
    erkek_nufus: "7.542.231",
    kadin_nufus_yuzde: "49,94%",
    kadin_nufus: "7.525.493",
    nufus_yuzdesi_genel: "18,37%",
    ilceler: [
      {
        plaka_kodu: "34",
        ilce_kodu: "1103",
        il_adi: "İSTANBUL",
        ilce_adi: "ADALAR",
        nufus: "16.372",
        erkek_nufus: "8.419",
        kadin_nufus: "7.953",
      },
      {
        plaka_kodu: "34",
        ilce_kodu: "2048",
        il_adi: "İSTANBUL",
        ilce_adi: "ARNAVUTKÖY",
        nufus: "312.023",
        erkek_nufus: "160.540",
        kadin_nufus: "151.483",
      },
      {
        plaka_kodu: "34",
        ilce_kodu: "2049",
        il_adi: "İSTANBUL",
        ilce_adi: "ATAŞEHİR",
        nufus: "427.217",
        erkek_nufus: "209.502",
        kadin_nufus: "217.715",
      },
      {
        plaka_kodu: "34",
        ilce_kodu: "2003",
        il_adi: "İSTANBUL",
        ilce_adi: "AVCILAR",
        nufus: "457.981",
        erkek_nufus: "230.029",
        kadin_nufus: "227.952",
      },
      {
        plaka_kodu: "34",
        ilce_kodu: "2004",
        il_adi: "İSTANBUL",
        ilce_adi: "BAĞCILAR",
        nufus: "744.351",
        erkek_nufus: "378.073",
        kadin_nufus: "366.278",
      },
      {
        plaka_kodu: "34",
        ilce_kodu: "2005",
        il_adi: "İSTANBUL",
        ilce_adi: "BAHÇELİEVLER",
        nufus: "605.300",
        erkek_nufus: "305.869",
        kadin_nufus: "299.431",
      },
      {
        plaka_kodu: "34",
        ilce_kodu: "1166",
        il_adi: "İSTANBUL",
        ilce_adi: "BAKIRKÖY",
        nufus: "228.759",
        erkek_nufus: "106.582",
        kadin_nufus: "122.177",
      },
      {
        plaka_kodu: "34",
        ilce_kodu: "2050",
        il_adi: "İSTANBUL",
        ilce_adi: "BAŞAKŞEHİR",
        nufus: "503.243",
        erkek_nufus: "252.731",
        kadin_nufus: "250.512",
      },
      {
        plaka_kodu: "34",
        ilce_kodu: "1886",
        il_adi: "İSTANBUL",
        ilce_adi: "BAYRAMPAŞA",
        nufus: "274.884",
        erkek_nufus: "138.461",
        kadin_nufus: "136.423",
      },
      {
        plaka_kodu: "34",
        ilce_kodu: "1183",
        il_adi: "İSTANBUL",
        ilce_adi: "BEŞİKTAŞ",
        nufus: "178.938",
        erkek_nufus: "81.777",
        kadin_nufus: "97.161",
      },
      {
        plaka_kodu: "34",
        ilce_kodu: "1185",
        il_adi: "İSTANBUL",
        ilce_adi: "BEYKOZ",
        nufus: "248.595",
        erkek_nufus: "122.991",
        kadin_nufus: "125.604",
      },
      {
        plaka_kodu: "34",
        ilce_kodu: "2051",
        il_adi: "İSTANBUL",
        ilce_adi: "BEYLİKDÜZÜ",
        nufus: "398.122",
        erkek_nufus: "194.742",
        kadin_nufus: "203.380",
      },
      {
        plaka_kodu: "34",
        ilce_kodu: "1186",
        il_adi: "İSTANBUL",
        ilce_adi: "BEYOĞLU",
        nufus: "233.322",
        erkek_nufus: "120.151",
        kadin_nufus: "113.171",
      },
      {
        plaka_kodu: "34",
        ilce_kodu: "1782",
        il_adi: "İSTANBUL",
        ilce_adi: "BÜYÜKÇEKMECE",
        nufus: "269.160",
        erkek_nufus: "133.114",
        kadin_nufus: "136.046",
      },
      {
        plaka_kodu: "34",
        ilce_kodu: "1237",
        il_adi: "İSTANBUL",
        ilce_adi: "ÇATALCA",
        nufus: "76.131",
        erkek_nufus: "39.046",
        kadin_nufus: "37.085",
      },
      {
        plaka_kodu: "34",
        ilce_kodu: "2052",
        il_adi: "İSTANBUL",
        ilce_adi: "ÇEKMEKÖY",
        nufus: "288.585",
        erkek_nufus: "143.789",
        kadin_nufus: "144.796",
      },
      {
        plaka_kodu: "34",
        ilce_kodu: "2016",
        il_adi: "İSTANBUL",
        ilce_adi: "ESENLER",
        nufus: "447.116",
        erkek_nufus: "230.017",
        kadin_nufus: "217.099",
      },
      {
        plaka_kodu: "34",
        ilce_kodu: "2053",
        il_adi: "İSTANBUL",
        ilce_adi: "ESENYURT",
        nufus: "977.489",
        erkek_nufus: "500.994",
        kadin_nufus: "476.495",
      },
      {
        plaka_kodu: "34",
        ilce_kodu: "1325",
        il_adi: "İSTANBUL",
        ilce_adi: "EYÜPSULTAN",
        nufus: "417.360",
        erkek_nufus: "208.754",
        kadin_nufus: "208.606",
      },
      {
        plaka_kodu: "34",
        ilce_kodu: "1327",
        il_adi: "İSTANBUL",
        ilce_adi: "FATİH",
        nufus: "382.990",
        erkek_nufus: "190.991",
        kadin_nufus: "191.999",
      },
      {
        plaka_kodu: "34",
        ilce_kodu: "1336",
        il_adi: "İSTANBUL",
        ilce_adi: "GAZİOSMANPAŞA",
        nufus: "493.096",
        erkek_nufus: "247.587",
        kadin_nufus: "245.509",
      },
      {
        plaka_kodu: "34",
        ilce_kodu: "2010",
        il_adi: "İSTANBUL",
        ilce_adi: "GÜNGÖREN",
        nufus: "283.083",
        erkek_nufus: "143.719",
        kadin_nufus: "139.364",
      },
      {
        plaka_kodu: "34",
        ilce_kodu: "1421",
        il_adi: "İSTANBUL",
        ilce_adi: "KADIKÖY",
        nufus: "485.233",
        erkek_nufus: "218.661",
        kadin_nufus: "266.572",
      },
      {
        plaka_kodu: "34",
        ilce_kodu: "1810",
        il_adi: "İSTANBUL",
        ilce_adi: "KAĞITHANE",
        nufus: "454.550",
        erkek_nufus: "229.412",
        kadin_nufus: "225.138",
      },
      {
        plaka_kodu: "34",
        ilce_kodu: "1449",
        il_adi: "İSTANBUL",
        ilce_adi: "KARTAL",
        nufus: "480.738",
        erkek_nufus: "237.136",
        kadin_nufus: "243.602",
      },
      {
        plaka_kodu: "34",
        ilce_kodu: "1823",
        il_adi: "İSTANBUL",
        ilce_adi: "KÜÇÜKÇEKMECE",
        nufus: "805.930",
        erkek_nufus: "403.820",
        kadin_nufus: "402.110",
      },
      {
        plaka_kodu: "34",
        ilce_kodu: "2012",
        il_adi: "İSTANBUL",
        ilce_adi: "MALTEPE",
        nufus: "525.566",
        erkek_nufus: "262.663",
        kadin_nufus: "262.903",
      },
      {
        plaka_kodu: "34",
        ilce_kodu: "1835",
        il_adi: "İSTANBUL",
        ilce_adi: "PENDİK",
        nufus: "741.895",
        erkek_nufus: "374.807",
        kadin_nufus: "367.088",
      },
      {
        plaka_kodu: "34",
        ilce_kodu: "2054",
        il_adi: "İSTANBUL",
        ilce_adi: "SANCAKTEPE",
        nufus: "474.668",
        erkek_nufus: "240.230",
        kadin_nufus: "234.438",
      },
      {
        plaka_kodu: "34",
        ilce_kodu: "1604",
        il_adi: "İSTANBUL",
        ilce_adi: "SARIYER",
        nufus: "349.968",
        erkek_nufus: "172.832",
        kadin_nufus: "177.136",
      },
      {
        plaka_kodu: "34",
        ilce_kodu: "1622",
        il_adi: "İSTANBUL",
        ilce_adi: "SİLİVRİ",
        nufus: "209.014",
        erkek_nufus: "118.642",
        kadin_nufus: "90.372",
      },
      {
        plaka_kodu: "34",
        ilce_kodu: "2014",
        il_adi: "İSTANBUL",
        ilce_adi: "SULTANBEYLİ",
        nufus: "349.485",
        erkek_nufus: "178.935",
        kadin_nufus: "170.550",
      },
      {
        plaka_kodu: "34",
        ilce_kodu: "2055",
        il_adi: "İSTANBUL",
        ilce_adi: "SULTANGAZİ",
        nufus: "543.380",
        erkek_nufus: "277.601",
        kadin_nufus: "265.779",
      },
      {
        plaka_kodu: "34",
        ilce_kodu: "1659",
        il_adi: "İSTANBUL",
        ilce_adi: "ŞİLE",
        nufus: "41.627",
        erkek_nufus: "21.558",
        kadin_nufus: "20.069",
      },
      {
        plaka_kodu: "34",
        ilce_kodu: "1663",
        il_adi: "İSTANBUL",
        ilce_adi: "ŞİŞLİ",
        nufus: "284.294",
        erkek_nufus: "138.611",
        kadin_nufus: "145.683",
      },
      {
        plaka_kodu: "34",
        ilce_kodu: "2015",
        il_adi: "İSTANBUL",
        ilce_adi: "TUZLA",
        nufus: "284.443",
        erkek_nufus: "145.258",
        kadin_nufus: "139.185",
      },
      {
        plaka_kodu: "34",
        ilce_kodu: "1852",
        il_adi: "İSTANBUL",
        ilce_adi: "ÜMRANİYE",
        nufus: "726.758",
        erkek_nufus: "363.386",
        kadin_nufus: "363.372",
      },
      {
        plaka_kodu: "34",
        ilce_kodu: "1708",
        il_adi: "İSTANBUL",
        ilce_adi: "ÜSKÜDAR",
        nufus: "525.395",
        erkek_nufus: "255.022",
        kadin_nufus: "270.373",
      },
      {
        plaka_kodu: "34",
        ilce_kodu: "1739",
        il_adi: "İSTANBUL",
        ilce_adi: "ZEYTİNBURNU",
        nufus: "293.839",
        erkek_nufus: "147.234",
        kadin_nufus: "146.605",
      },
    ],
    kisa_bilgi:
      "2010 Avrupa Kültür Başkenti, tarihi 300 bin yıl öncesine uzanan, üç tarafını Marmara Denizi, Boğaziçi ve Haliç'in sardığı bir yarım ada üzerinde yer alan, Roma, Bizans, Latin ve Osmanlı Türklerine başkentlik etmiş, Türkiye'nin en kalabalık şehri İstanbul, hem tarih boyunca, hem günümüzde pek çok alanda 'başkent' özelliğini korumuştur.",
  },
  {
    il_adi: "İzmir",
    plaka_kodu: "35",
    alan_kodu: "232",
    nufus: "4.462.056",
    bolge: "Ege",
    yuzolcumu: "11.891",
    nufus_artisi: "8,2%",
    erkek_nufus_yuzde: "49,82%",
    erkek_nufus: "2.152.585",
    kadin_nufus_yuzde: "50,18%",
    kadin_nufus: "2.167.934",
    nufus_yuzdesi_genel: "5,27%",
    ilceler: [
      {
        plaka_kodu: "35",
        ilce_kodu: "1128",
        il_adi: "İZMİR",
        ilce_adi: "ALİAĞA",
        nufus: "103.364",
        erkek_nufus: "56.673",
        kadin_nufus: "46.691",
      },
      {
        plaka_kodu: "35",
        ilce_kodu: "2006",
        il_adi: "İZMİR",
        ilce_adi: "BALÇOVA",
        nufus: "80.513",
        erkek_nufus: "39.422",
        kadin_nufus: "41.091",
      },
      {
        plaka_kodu: "35",
        ilce_kodu: "1178",
        il_adi: "İZMİR",
        ilce_adi: "BAYINDIR",
        nufus: "40.049",
        erkek_nufus: "19.937",
        kadin_nufus: "20.112",
      },
      {
        plaka_kodu: "35",
        ilce_kodu: "2056",
        il_adi: "İZMİR",
        ilce_adi: "BAYRAKLI",
        nufus: "296.839",
        erkek_nufus: "147.606",
        kadin_nufus: "149.233",
      },
      {
        plaka_kodu: "35",
        ilce_kodu: "1181",
        il_adi: "İZMİR",
        ilce_adi: "BERGAMA",
        nufus: "104.980",
        erkek_nufus: "52.773",
        kadin_nufus: "52.207",
      },
      {
        plaka_kodu: "35",
        ilce_kodu: "1776",
        il_adi: "İZMİR",
        ilce_adi: "BEYDAĞ",
        nufus: "12.197",
        erkek_nufus: "6.128",
        kadin_nufus: "6.069",
      },
      {
        plaka_kodu: "35",
        ilce_kodu: "1203",
        il_adi: "İZMİR",
        ilce_adi: "BORNOVA",
        nufus: "452.867",
        erkek_nufus: "224.889",
        kadin_nufus: "227.978",
      },
      {
        plaka_kodu: "35",
        ilce_kodu: "1780",
        il_adi: "İZMİR",
        ilce_adi: "BUCA",
        nufus: "517.963",
        erkek_nufus: "257.169",
        kadin_nufus: "260.794",
      },
      {
        plaka_kodu: "35",
        ilce_kodu: "1251",
        il_adi: "İZMİR",
        ilce_adi: "ÇEŞME",
        nufus: "48.167",
        erkek_nufus: "24.477",
        kadin_nufus: "23.690",
      },
      {
        plaka_kodu: "35",
        ilce_kodu: "2007",
        il_adi: "İZMİR",
        ilce_adi: "ÇİĞLİ",
        nufus: "209.951",
        erkek_nufus: "104.609",
        kadin_nufus: "105.342",
      },
      {
        plaka_kodu: "35",
        ilce_kodu: "1280",
        il_adi: "İZMİR",
        ilce_adi: "DİKİLİ",
        nufus: "46.587",
        erkek_nufus: "23.254",
        kadin_nufus: "23.333",
      },
      {
        plaka_kodu: "35",
        ilce_kodu: "1334",
        il_adi: "İZMİR",
        ilce_adi: "FOÇA",
        nufus: "33.611",
        erkek_nufus: "18.642",
        kadin_nufus: "14.969",
      },
      {
        plaka_kodu: "35",
        ilce_kodu: "2009",
        il_adi: "İZMİR",
        ilce_adi: "GAZİEMİR",
        nufus: "137.856",
        erkek_nufus: "69.308",
        kadin_nufus: "68.548",
      },
      {
        plaka_kodu: "35",
        ilce_kodu: "2018",
        il_adi: "İZMİR",
        ilce_adi: "GÜZELBAHÇE",
        nufus: "37.572",
        erkek_nufus: "18.481",
        kadin_nufus: "19.091",
      },
      {
        plaka_kodu: "35",
        ilce_kodu: "2057",
        il_adi: "İZMİR",
        ilce_adi: "KARABAĞLAR",
        nufus: "478.788",
        erkek_nufus: "235.862",
        kadin_nufus: "242.926",
      },
      {
        plaka_kodu: "35",
        ilce_kodu: "1432",
        il_adi: "İZMİR",
        ilce_adi: "KARABURUN",
        nufus: "11.927",
        erkek_nufus: "6.179",
        kadin_nufus: "5.748",
      },
      {
        plaka_kodu: "35",
        ilce_kodu: "1448",
        il_adi: "İZMİR",
        ilce_adi: "KARŞIYAKA",
        nufus: "347.023",
        erkek_nufus: "162.189",
        kadin_nufus: "184.834",
      },
      {
        plaka_kodu: "35",
        ilce_kodu: "1461",
        il_adi: "İZMİR",
        ilce_adi: "KEMALPAŞA",
        nufus: "112.049",
        erkek_nufus: "56.978",
        kadin_nufus: "55.071",
      },
      {
        plaka_kodu: "35",
        ilce_kodu: "1467",
        il_adi: "İZMİR",
        ilce_adi: "KINIK",
        nufus: "28.513",
        erkek_nufus: "14.191",
        kadin_nufus: "14.322",
      },
      {
        plaka_kodu: "35",
        ilce_kodu: "1477",
        il_adi: "İZMİR",
        ilce_adi: "KİRAZ",
        nufus: "43.674",
        erkek_nufus: "22.172",
        kadin_nufus: "21.502",
      },
      {
        plaka_kodu: "35",
        ilce_kodu: "1819",
        il_adi: "İZMİR",
        ilce_adi: "KONAK",
        nufus: "336.545",
        erkek_nufus: "164.439",
        kadin_nufus: "172.106",
      },
      {
        plaka_kodu: "35",
        ilce_kodu: "1826",
        il_adi: "İZMİR",
        ilce_adi: "MENDERES",
        nufus: "104.147",
        erkek_nufus: "52.432",
        kadin_nufus: "51.715",
      },
      {
        plaka_kodu: "35",
        ilce_kodu: "1521",
        il_adi: "İZMİR",
        ilce_adi: "MENEMEN",
        nufus: "193.229",
        erkek_nufus: "98.451",
        kadin_nufus: "94.778",
      },
      {
        plaka_kodu: "35",
        ilce_kodu: "2013",
        il_adi: "İZMİR",
        ilce_adi: "NARLIDERE",
        nufus: "63.438",
        erkek_nufus: "31.253",
        kadin_nufus: "32.185",
      },
      {
        plaka_kodu: "35",
        ilce_kodu: "1563",
        il_adi: "İZMİR",
        ilce_adi: "ÖDEMİŞ",
        nufus: "132.769",
        erkek_nufus: "66.077",
        kadin_nufus: "66.692",
      },
      {
        plaka_kodu: "35",
        ilce_kodu: "1611",
        il_adi: "İZMİR",
        ilce_adi: "SEFERİHİSAR",
        nufus: "52.507",
        erkek_nufus: "26.270",
        kadin_nufus: "26.237",
      },
      {
        plaka_kodu: "35",
        ilce_kodu: "1612",
        il_adi: "İZMİR",
        ilce_adi: "SELÇUK",
        nufus: "37.689",
        erkek_nufus: "18.771",
        kadin_nufus: "18.918",
      },
      {
        plaka_kodu: "35",
        ilce_kodu: "1677",
        il_adi: "İZMİR",
        ilce_adi: "TİRE",
        nufus: "86.758",
        erkek_nufus: "42.773",
        kadin_nufus: "43.985",
      },
      {
        plaka_kodu: "35",
        ilce_kodu: "1682",
        il_adi: "İZMİR",
        ilce_adi: "TORBALI",
        nufus: "201.476",
        erkek_nufus: "101.543",
        kadin_nufus: "99.933",
      },
      {
        plaka_kodu: "35",
        ilce_kodu: "1703",
        il_adi: "İZMİR",
        ilce_adi: "URLA",
        nufus: "72.741",
        erkek_nufus: "36.339",
        kadin_nufus: "36.402",
      },
    ],
    kisa_bilgi:
      "Latince adı Smyrna olan İzmir, Ege Bölgesi'nin ortasında ve İzmir Körfezi çevresinde yer alır. Konak, kentin merkez ilçesidir ve burada gezilecek, görülecek çok şey vardır.",
  },
  {
    il_adi: "Kars",
    plaka_kodu: "36",
    alan_kodu: "474",
    nufus: "274.829",
    bolge: "Doğu Anadolu",
    yuzolcumu: "10.193",
    nufus_artisi: "-22,5%",
    erkek_nufus_yuzde: "51,76%",
    erkek_nufus: "149.510",
    kadin_nufus_yuzde: "48,24%",
    kadin_nufus: "139.368",
    nufus_yuzdesi_genel: "0,35%",
    ilceler: [
      {
        plaka_kodu: "36",
        ilce_kodu: "1447",
        il_adi: "KARS",
        ilce_adi: "MERKEZ",
        nufus: "117.312",
        erkek_nufus: "59.523",
        kadin_nufus: "57.789",
      },
      {
        plaka_kodu: "36",
        ilce_kodu: "1756",
        il_adi: "KARS",
        ilce_adi: "AKYAKA",
        nufus: "10.353",
        erkek_nufus: "5.649",
        kadin_nufus: "4.704",
      },
      {
        plaka_kodu: "36",
        ilce_kodu: "1149",
        il_adi: "KARS",
        ilce_adi: "ARPAÇAY",
        nufus: "15.922",
        erkek_nufus: "8.246",
        kadin_nufus: "7.676",
      },
      {
        plaka_kodu: "36",
        ilce_kodu: "1279",
        il_adi: "KARS",
        ilce_adi: "DİGOR",
        nufus: "21.088",
        erkek_nufus: "11.010",
        kadin_nufus: "10.078",
      },
      {
        plaka_kodu: "36",
        ilce_kodu: "1424",
        il_adi: "KARS",
        ilce_adi: "KAĞIZMAN",
        nufus: "45.079",
        erkek_nufus: "22.926",
        kadin_nufus: "22.153",
      },
      {
        plaka_kodu: "36",
        ilce_kodu: "1601",
        il_adi: "KARS",
        ilce_adi: "SARIKAMIŞ",
        nufus: "39.486",
        erkek_nufus: "20.714",
        kadin_nufus: "18.772",
      },
      {
        plaka_kodu: "36",
        ilce_kodu: "1614",
        il_adi: "KARS",
        ilce_adi: "SELİM",
        nufus: "22.267",
        erkek_nufus: "11.416",
        kadin_nufus: "10.851",
      },
      {
        plaka_kodu: "36",
        ilce_kodu: "1645",
        il_adi: "KARS",
        ilce_adi: "SUSUZ",
        nufus: "9.570",
        erkek_nufus: "4.912",
        kadin_nufus: "4.658",
      },
    ],
    kisa_bilgi:
      "Ülkemizin en doğusunda, Ermenistan’la komşu Kars, çok eski kültürlere ev sahipliği yapmış, pek çok kıymetli halk ozanının yetiştiği önemli bir ilimiz.",
  },
  {
    il_adi: "Kastamonu",
    plaka_kodu: "37",
    alan_kodu: "366",
    nufus: "378.115",
    bolge: "Karadeniz",
    yuzolcumu: "13.064",
    nufus_artisi: "6,7%",
    erkek_nufus_yuzde: "49,93%",
    erkek_nufus: "191.431",
    kadin_nufus_yuzde: "50,07%",
    kadin_nufus: "191.942",
    nufus_yuzdesi_genel: "0,45%",
    ilceler: [
      {
        plaka_kodu: "37",
        ilce_kodu: "1450",
        il_adi: "KASTAMONU",
        ilce_adi: "MERKEZ",
        nufus: "152.541",
        erkek_nufus: "75.307",
        kadin_nufus: "77.234",
      },
      {
        plaka_kodu: "37",
        ilce_kodu: "1101",
        il_adi: "KASTAMONU",
        ilce_adi: "ABANA",
        nufus: "4.027",
        erkek_nufus: "2.038",
        kadin_nufus: "1.989",
      },
      {
        plaka_kodu: "37",
        ilce_kodu: "1867",
        il_adi: "KASTAMONU",
        ilce_adi: "AĞLI",
        nufus: "3.006",
        erkek_nufus: "1.518",
        kadin_nufus: "1.488",
      },
      {
        plaka_kodu: "37",
        ilce_kodu: "1140",
        il_adi: "KASTAMONU",
        ilce_adi: "ARAÇ",
        nufus: "17.920",
        erkek_nufus: "8.971",
        kadin_nufus: "8.949",
      },
      {
        plaka_kodu: "37",
        ilce_kodu: "1162",
        il_adi: "KASTAMONU",
        ilce_adi: "AZDAVAY",
        nufus: "7.290",
        erkek_nufus: "3.708",
        kadin_nufus: "3.582",
      },
      {
        plaka_kodu: "37",
        ilce_kodu: "1208",
        il_adi: "KASTAMONU",
        ilce_adi: "BOZKURT",
        nufus: "9.170",
        erkek_nufus: "4.675",
        kadin_nufus: "4.495",
      },
      {
        plaka_kodu: "37",
        ilce_kodu: "1221",
        il_adi: "KASTAMONU",
        ilce_adi: "CİDE",
        nufus: "22.136",
        erkek_nufus: "11.072",
        kadin_nufus: "11.064",
      },
      {
        plaka_kodu: "37",
        ilce_kodu: "1238",
        il_adi: "KASTAMONU",
        ilce_adi: "ÇATALZEYTİN",
        nufus: "7.229",
        erkek_nufus: "3.609",
        kadin_nufus: "3.620",
      },
      {
        plaka_kodu: "37",
        ilce_kodu: "1264",
        il_adi: "KASTAMONU",
        ilce_adi: "DADAY",
        nufus: "8.073",
        erkek_nufus: "4.025",
        kadin_nufus: "4.048",
      },
      {
        plaka_kodu: "37",
        ilce_kodu: "1277",
        il_adi: "KASTAMONU",
        ilce_adi: "DEVREKANİ",
        nufus: "12.132",
        erkek_nufus: "6.085",
        kadin_nufus: "6.047",
      },
      {
        plaka_kodu: "37",
        ilce_kodu: "1915",
        il_adi: "KASTAMONU",
        ilce_adi: "DOĞANYURT",
        nufus: "5.428",
        erkek_nufus: "2.751",
        kadin_nufus: "2.677",
      },
      {
        plaka_kodu: "37",
        ilce_kodu: "1940",
        il_adi: "KASTAMONU",
        ilce_adi: "HANÖNÜ",
        nufus: "4.134",
        erkek_nufus: "2.073",
        kadin_nufus: "2.061",
      },
      {
        plaka_kodu: "37",
        ilce_kodu: "1805",
        il_adi: "KASTAMONU",
        ilce_adi: "İHSANGAZİ",
        nufus: "5.292",
        erkek_nufus: "2.525",
        kadin_nufus: "2.767",
      },
      {
        plaka_kodu: "37",
        ilce_kodu: "1410",
        il_adi: "KASTAMONU",
        ilce_adi: "İNEBOLU",
        nufus: "20.675",
        erkek_nufus: "10.174",
        kadin_nufus: "10.501",
      },
      {
        plaka_kodu: "37",
        ilce_kodu: "1499",
        il_adi: "KASTAMONU",
        ilce_adi: "KÜRE",
        nufus: "5.431",
        erkek_nufus: "2.799",
        kadin_nufus: "2.632",
      },
      {
        plaka_kodu: "37",
        ilce_kodu: "1836",
        il_adi: "KASTAMONU",
        ilce_adi: "PINARBAŞI",
        nufus: "5.688",
        erkek_nufus: "2.971",
        kadin_nufus: "2.717",
      },
      {
        plaka_kodu: "37",
        ilce_kodu: "1984",
        il_adi: "KASTAMONU",
        ilce_adi: "SEYDİLER",
        nufus: "4.289",
        erkek_nufus: "2.158",
        kadin_nufus: "2.131",
      },
      {
        plaka_kodu: "37",
        ilce_kodu: "1845",
        il_adi: "KASTAMONU",
        ilce_adi: "ŞENPAZAR",
        nufus: "4.304",
        erkek_nufus: "2.221",
        kadin_nufus: "2.083",
      },
      {
        plaka_kodu: "37",
        ilce_kodu: "1666",
        il_adi: "KASTAMONU",
        ilce_adi: "TAŞKÖPRÜ",
        nufus: "37.119",
        erkek_nufus: "18.222",
        kadin_nufus: "18.897",
      },
      {
        plaka_kodu: "37",
        ilce_kodu: "1685",
        il_adi: "KASTAMONU",
        ilce_adi: "TOSYA",
        nufus: "39.708",
        erkek_nufus: "19.214",
        kadin_nufus: "20.494",
      },
    ],
    kisa_bilgi:
      "Karadeniz bölgesinde bulunan Kastamonu, tarihi boyunca önemli bir medeniyetler merkezi olmuş. Bu nedenle ilde pek çok değerli tarihi eser saymak mümkün.",
  },
  {
    il_adi: "Kayseri",
    plaka_kodu: "38",
    alan_kodu: "352",
    nufus: "1.441.523",
    bolge: "İç Anadolu",
    yuzolcumu: "16.970",
    nufus_artisi: "5,0%",
    erkek_nufus_yuzde: "50,13%",
    erkek_nufus: "696.658",
    kadin_nufus_yuzde: "49,87%",
    kadin_nufus: "693.022",
    nufus_yuzdesi_genel: "1,69%",
    ilceler: [
      {
        plaka_kodu: "38",
        ilce_kodu: "1752",
        il_adi: "KAYSERİ",
        ilce_adi: "AKKIŞLA",
        nufus: "5.804",
        erkek_nufus: "2.943",
        kadin_nufus: "2.861",
      },
      {
        plaka_kodu: "38",
        ilce_kodu: "1218",
        il_adi: "KAYSERİ",
        ilce_adi: "BÜNYAN",
        nufus: "30.099",
        erkek_nufus: "17.210",
        kadin_nufus: "12.889",
      },
      {
        plaka_kodu: "38",
        ilce_kodu: "1275",
        il_adi: "KAYSERİ",
        ilce_adi: "DEVELİ",
        nufus: "66.507",
        erkek_nufus: "33.291",
        kadin_nufus: "33.216",
      },
      {
        plaka_kodu: "38",
        ilce_kodu: "1330",
        il_adi: "KAYSERİ",
        ilce_adi: "FELAHİYE",
        nufus: "5.536",
        erkek_nufus: "2.796",
        kadin_nufus: "2.740",
      },
      {
        plaka_kodu: "38",
        ilce_kodu: "1936",
        il_adi: "KAYSERİ",
        ilce_adi: "HACILAR",
        nufus: "12.471",
        erkek_nufus: "6.339",
        kadin_nufus: "6.132",
      },
      {
        plaka_kodu: "38",
        ilce_kodu: "1409",
        il_adi: "KAYSERİ",
        ilce_adi: "İNCESU",
        nufus: "28.755",
        erkek_nufus: "14.632",
        kadin_nufus: "14.123",
      },
      {
        plaka_kodu: "38",
        ilce_kodu: "1863",
        il_adi: "KAYSERİ",
        ilce_adi: "KOCASİNAN",
        nufus: "404.780",
        erkek_nufus: "201.860",
        kadin_nufus: "202.920",
      },
      {
        plaka_kodu: "38",
        ilce_kodu: "1864",
        il_adi: "KAYSERİ",
        ilce_adi: "MELİKGAZİ",
        nufus: "589.852",
        erkek_nufus: "294.102",
        kadin_nufus: "295.750",
      },
      {
        plaka_kodu: "38",
        ilce_kodu: "1978",
        il_adi: "KAYSERİ",
        ilce_adi: "ÖZVATAN",
        nufus: "3.800",
        erkek_nufus: "1.926",
        kadin_nufus: "1.874",
      },
      {
        plaka_kodu: "38",
        ilce_kodu: "1576",
        il_adi: "KAYSERİ",
        ilce_adi: "PINARBAŞI",
        nufus: "21.903",
        erkek_nufus: "11.517",
        kadin_nufus: "10.386",
      },
      {
        plaka_kodu: "38",
        ilce_kodu: "1603",
        il_adi: "KAYSERİ",
        ilce_adi: "SARIOĞLAN",
        nufus: "13.777",
        erkek_nufus: "6.924",
        kadin_nufus: "6.853",
      },
      {
        plaka_kodu: "38",
        ilce_kodu: "1605",
        il_adi: "KAYSERİ",
        ilce_adi: "SARIZ",
        nufus: "9.321",
        erkek_nufus: "4.791",
        kadin_nufus: "4.530",
      },
      {
        plaka_kodu: "38",
        ilce_kodu: "1846",
        il_adi: "KAYSERİ",
        ilce_adi: "TALAS",
        nufus: "168.783",
        erkek_nufus: "83.840",
        kadin_nufus: "84.943",
      },
      {
        plaka_kodu: "38",
        ilce_kodu: "1680",
        il_adi: "KAYSERİ",
        ilce_adi: "TOMARZA",
        nufus: "21.630",
        erkek_nufus: "10.977",
        kadin_nufus: "10.653",
      },
      {
        plaka_kodu: "38",
        ilce_kodu: "1715",
        il_adi: "KAYSERİ",
        ilce_adi: "YAHYALI",
        nufus: "35.674",
        erkek_nufus: "18.035",
        kadin_nufus: "17.639",
      },
      {
        plaka_kodu: "38",
        ilce_kodu: "1727",
        il_adi: "KAYSERİ",
        ilce_adi: "YEŞİLHİSAR",
        nufus: "15.665",
        erkek_nufus: "7.878",
        kadin_nufus: "7.787",
      },
    ],
    kisa_bilgi:
      "İç Anadolu'nun güney bölümü ile Toros Dağlarının birbirine yaklaştığı, Orta Kızılırmak bölümünde yer alan Kayseri, değişik uygarlıkların kalıntılarına ev sahipliği yapan önemli bir ilimizdir.",
  },
  {
    il_adi: "Kırklareli",
    plaka_kodu: "39",
    alan_kodu: "288",
    nufus: "369.347",
    bolge: "Marmara",
    yuzolcumu: "6.459",
    nufus_artisi: "8,1%",
    erkek_nufus_yuzde: "51,32%",
    erkek_nufus: "185.181",
    kadin_nufus_yuzde: "48,68%",
    kadin_nufus: "175.679",
    nufus_yuzdesi_genel: "0,44%",
    ilceler: [
      {
        plaka_kodu: "39",
        ilce_kodu: "1471",
        il_adi: "KIRKLARELİ",
        ilce_adi: "MERKEZ",
        nufus: "105.525",
        erkek_nufus: "53.431",
        kadin_nufus: "52.094",
      },
      {
        plaka_kodu: "39",
        ilce_kodu: "1163",
        il_adi: "KIRKLARELİ",
        ilce_adi: "BABAESKİ",
        nufus: "46.624",
        erkek_nufus: "23.564",
        kadin_nufus: "23.060",
      },
      {
        plaka_kodu: "39",
        ilce_kodu: "1270",
        il_adi: "KIRKLARELİ",
        ilce_adi: "DEMİRKÖY",
        nufus: "8.871",
        erkek_nufus: "4.822",
        kadin_nufus: "4.049",
      },
      {
        plaka_kodu: "39",
        ilce_kodu: "1480",
        il_adi: "KIRKLARELİ",
        ilce_adi: "KOFÇAZ",
        nufus: "2.251",
        erkek_nufus: "1.192",
        kadin_nufus: "1.059",
      },
      {
        plaka_kodu: "39",
        ilce_kodu: "1505",
        il_adi: "KIRKLARELİ",
        ilce_adi: "LÜLEBURGAZ",
        nufus: "153.027",
        erkek_nufus: "77.264",
        kadin_nufus: "75.763",
      },
      {
        plaka_kodu: "39",
        ilce_kodu: "1572",
        il_adi: "KIRKLARELİ",
        ilce_adi: "PEHLİVANKÖY",
        nufus: "3.445",
        erkek_nufus: "1.764",
        kadin_nufus: "1.681",
      },
      {
        plaka_kodu: "39",
        ilce_kodu: "1577",
        il_adi: "KIRKLARELİ",
        ilce_adi: "PINARHİSAR",
        nufus: "17.806",
        erkek_nufus: "9.442",
        kadin_nufus: "8.364",
      },
      {
        plaka_kodu: "39",
        ilce_kodu: "1714",
        il_adi: "KIRKLARELİ",
        ilce_adi: "VİZE",
        nufus: "28.814",
        erkek_nufus: "14.965",
        kadin_nufus: "13.849",
      },
    ],
    kisa_bilgi:
      "Marmara bölgesinin Trakya kesiminde bulunan Kırklareli, sınır ili konumunda olup, Bulgaristan’la komşudur. 1924 yılından bu yana il statüsünde bulunan Kırklareli, Anadolu ve Avrupa arasında geçiş konumunda bulunduğu için pek çok arkeolojik, doğal ve tarihi sit alanlarına sahip.",
  },
  {
    il_adi: "Kırşehir",
    plaka_kodu: "40",
    alan_kodu: "386",
    nufus: "244.519",
    bolge: "İç Anadolu",
    yuzolcumu: "6.584",
    nufus_artisi: "6,5%",
    erkek_nufus_yuzde: "49,73%",
    erkek_nufus: "120.278",
    kadin_nufus_yuzde: "50,27%",
    kadin_nufus: "121.590",
    nufus_yuzdesi_genel: "0,29%",
    ilceler: [
      {
        plaka_kodu: "40",
        ilce_kodu: "1472",
        il_adi: "KIRŞEHİR",
        ilce_adi: "MERKEZ",
        nufus: "160.737",
        erkek_nufus: "79.366",
        kadin_nufus: "81.371",
      },
      {
        plaka_kodu: "40",
        ilce_kodu: "1869",
        il_adi: "KIRŞEHİR",
        ilce_adi: "AKÇAKENT",
        nufus: "3.566",
        erkek_nufus: "1.769",
        kadin_nufus: "1.797",
      },
      {
        plaka_kodu: "40",
        ilce_kodu: "1754",
        il_adi: "KIRŞEHİR",
        ilce_adi: "AKPINAR",
        nufus: "7.094",
        erkek_nufus: "3.569",
        kadin_nufus: "3.525",
      },
      {
        plaka_kodu: "40",
        ilce_kodu: "1890",
        il_adi: "KIRŞEHİR",
        ilce_adi: "BOZTEPE",
        nufus: "5.197",
        erkek_nufus: "2.644",
        kadin_nufus: "2.553",
      },
      {
        plaka_kodu: "40",
        ilce_kodu: "1254",
        il_adi: "KIRŞEHİR",
        ilce_adi: "ÇİÇEKDAĞI",
        nufus: "13.505",
        erkek_nufus: "6.625",
        kadin_nufus: "6.880",
      },
      {
        plaka_kodu: "40",
        ilce_kodu: "1429",
        il_adi: "KIRŞEHİR",
        ilce_adi: "KAMAN",
        nufus: "34.626",
        erkek_nufus: "17.262",
        kadin_nufus: "17.364",
      },
      {
        plaka_kodu: "40",
        ilce_kodu: "1529",
        il_adi: "KIRŞEHİR",
        ilce_adi: "MUCUR",
        nufus: "18.219",
        erkek_nufus: "9.066",
        kadin_nufus: "9.153",
      },
    ],
    kisa_bilgi:
      "İç Anadolu bölgemizdeki Hititler dönemine dek uzanan tarihi ile Kırşehir aynı zamanda, Ahi Evran tarafından kurulan ve Anadolu’dan Orta Asya’ya dek esnaf ve zanaatkarları tek çatı altında toplamayı amaçlayan Ahilik Teşkilatı’nın da merkezi konumundadır.",
  },
  {
    il_adi: "Kocaeli",
    plaka_kodu: "41",
    alan_kodu: "262",
    nufus: "2.079.072",
    bolge: "Marmara",
    yuzolcumu: "3.397",
    nufus_artisi: "22,2%",
    erkek_nufus_yuzde: "50,53%",
    erkek_nufus: "963.326",
    kadin_nufus_yuzde: "49,47%",
    kadin_nufus: "943.065",
    nufus_yuzdesi_genel: "2,47%",
    ilceler: [
      {
        plaka_kodu: "41",
        ilce_kodu: "2058",
        il_adi: "KOCAELİ",
        ilce_adi: "BAŞİSKELE",
        nufus: "111.641",
        erkek_nufus: "56.229",
        kadin_nufus: "55.412",
      },
      {
        plaka_kodu: "41",
        ilce_kodu: "2059",
        il_adi: "KOCAELİ",
        ilce_adi: "ÇAYIROVA",
        nufus: "144.825",
        erkek_nufus: "73.664",
        kadin_nufus: "71.161",
      },
      {
        plaka_kodu: "41",
        ilce_kodu: "2060",
        il_adi: "KOCAELİ",
        ilce_adi: "DARICA",
        nufus: "219.546",
        erkek_nufus: "111.225",
        kadin_nufus: "108.321",
      },
      {
        plaka_kodu: "41",
        ilce_kodu: "2030",
        il_adi: "KOCAELİ",
        ilce_adi: "DERİNCE",
        nufus: "144.287",
        erkek_nufus: "72.198",
        kadin_nufus: "72.089",
      },
      {
        plaka_kodu: "41",
        ilce_kodu: "2061",
        il_adi: "KOCAELİ",
        ilce_adi: "DİLOVASI",
        nufus: "51.866",
        erkek_nufus: "26.811",
        kadin_nufus: "25.055",
      },
      {
        plaka_kodu: "41",
        ilce_kodu: "1338",
        il_adi: "KOCAELİ",
        ilce_adi: "GEBZE",
        nufus: "399.558",
        erkek_nufus: "203.079",
        kadin_nufus: "196.479",
      },
      {
        plaka_kodu: "41",
        ilce_kodu: "1355",
        il_adi: "KOCAELİ",
        ilce_adi: "GÖLCÜK",
        nufus: "172.802",
        erkek_nufus: "87.110",
        kadin_nufus: "85.692",
      },
      {
        plaka_kodu: "41",
        ilce_kodu: "2062",
        il_adi: "KOCAELİ",
        ilce_adi: "İZMİT",
        nufus: "371.002",
        erkek_nufus: "183.229",
        kadin_nufus: "187.773",
      },
      {
        plaka_kodu: "41",
        ilce_kodu: "1430",
        il_adi: "KOCAELİ",
        ilce_adi: "KANDIRA",
        nufus: "52.930",
        erkek_nufus: "30.027",
        kadin_nufus: "22.903",
      },
      {
        plaka_kodu: "41",
        ilce_kodu: "1440",
        il_adi: "KOCAELİ",
        ilce_adi: "KARAMÜRSEL",
        nufus: "58.936",
        erkek_nufus: "29.440",
        kadin_nufus: "29.496",
      },
      {
        plaka_kodu: "41",
        ilce_kodu: "2063",
        il_adi: "KOCAELİ",
        ilce_adi: "KARTEPE",
        nufus: "131.416",
        erkek_nufus: "66.828",
        kadin_nufus: "64.588",
      },
      {
        plaka_kodu: "41",
        ilce_kodu: "1821",
        il_adi: "KOCAELİ",
        ilce_adi: "KÖRFEZ",
        nufus: "174.632",
        erkek_nufus: "87.935",
        kadin_nufus: "86.697",
      },
    ],
    kisa_bilgi:
      "Türkiye'nin en kalabalık illerinden, 1993 yılında büyükşehir unvanını alan, Marmara Bölgesi'nin Çatalca-Kocaeli Bölümü'nde bulunan Kocaeli, ülkenin sanayi sektöründe çok önemli bir konuma sahiptir.",
  },
  {
    il_adi: "Konya",
    plaka_kodu: "42",
    alan_kodu: "332",
    nufus: "2.296.347",
    bolge: "İç Anadolu",
    yuzolcumu: "40.838",
    nufus_artisi: "8,5%",
    erkek_nufus_yuzde: "49,62%",
    erkek_nufus: "1.094.441",
    kadin_nufus_yuzde: "50,38%",
    kadin_nufus: "1.111.168",
    nufus_yuzdesi_genel: "2,69%",
    ilceler: [
      {
        plaka_kodu: "42",
        ilce_kodu: "1868",
        il_adi: "KONYA",
        ilce_adi: "AHIRLI",
        nufus: "4.550",
        erkek_nufus: "2.236",
        kadin_nufus: "2.314",
      },
      {
        plaka_kodu: "42",
        ilce_kodu: "1753",
        il_adi: "KONYA",
        ilce_adi: "AKÖREN",
        nufus: "5.784",
        erkek_nufus: "2.975",
        kadin_nufus: "2.809",
      },
      {
        plaka_kodu: "42",
        ilce_kodu: "1122",
        il_adi: "KONYA",
        ilce_adi: "AKŞEHİR",
        nufus: "93.830",
        erkek_nufus: "46.017",
        kadin_nufus: "47.813",
      },
      {
        plaka_kodu: "42",
        ilce_kodu: "1760",
        il_adi: "KONYA",
        ilce_adi: "ALTINEKİN",
        nufus: "14.178",
        erkek_nufus: "7.227",
        kadin_nufus: "6.951",
      },
      {
        plaka_kodu: "42",
        ilce_kodu: "1188",
        il_adi: "KONYA",
        ilce_adi: "BEYŞEHİR",
        nufus: "77.137",
        erkek_nufus: "38.386",
        kadin_nufus: "38.751",
      },
      {
        plaka_kodu: "42",
        ilce_kodu: "1207",
        il_adi: "KONYA",
        ilce_adi: "BOZKIR",
        nufus: "25.600",
        erkek_nufus: "12.417",
        kadin_nufus: "13.183",
      },
      {
        plaka_kodu: "42",
        ilce_kodu: "1222",
        il_adi: "KONYA",
        ilce_adi: "CİHANBEYLİ",
        nufus: "51.434",
        erkek_nufus: "25.782",
        kadin_nufus: "25.652",
      },
      {
        plaka_kodu: "42",
        ilce_kodu: "1902",
        il_adi: "KONYA",
        ilce_adi: "ÇELTİK",
        nufus: "9.635",
        erkek_nufus: "4.916",
        kadin_nufus: "4.719",
      },
      {
        plaka_kodu: "42",
        ilce_kodu: "1262",
        il_adi: "KONYA",
        ilce_adi: "ÇUMRA",
        nufus: "67.631",
        erkek_nufus: "33.906",
        kadin_nufus: "33.725",
      },
      {
        plaka_kodu: "42",
        ilce_kodu: "1907",
        il_adi: "KONYA",
        ilce_adi: "DERBENT",
        nufus: "4.086",
        erkek_nufus: "1.972",
        kadin_nufus: "2.114",
      },
      {
        plaka_kodu: "42",
        ilce_kodu: "1789",
        il_adi: "KONYA",
        ilce_adi: "DEREBUCAK",
        nufus: "5.937",
        erkek_nufus: "2.962",
        kadin_nufus: "2.975",
      },
      {
        plaka_kodu: "42",
        ilce_kodu: "1285",
        il_adi: "KONYA",
        ilce_adi: "DOĞANHİSAR",
        nufus: "15.134",
        erkek_nufus: "7.271",
        kadin_nufus: "7.863",
      },
      {
        plaka_kodu: "42",
        ilce_kodu: "1920",
        il_adi: "KONYA",
        ilce_adi: "EMİRGAZİ",
        nufus: "7.876",
        erkek_nufus: "4.051",
        kadin_nufus: "3.825",
      },
      {
        plaka_kodu: "42",
        ilce_kodu: "1312",
        il_adi: "KONYA",
        ilce_adi: "EREĞLİ",
        nufus: "149.333",
        erkek_nufus: "74.905",
        kadin_nufus: "74.428",
      },
      {
        plaka_kodu: "42",
        ilce_kodu: "1933",
        il_adi: "KONYA",
        ilce_adi: "GÜNEYSINIR",
        nufus: "9.278",
        erkek_nufus: "4.637",
        kadin_nufus: "4.641",
      },
      {
        plaka_kodu: "42",
        ilce_kodu: "1375",
        il_adi: "KONYA",
        ilce_adi: "HADİM",
        nufus: "11.171",
        erkek_nufus: "5.612",
        kadin_nufus: "5.559",
      },
      {
        plaka_kodu: "42",
        ilce_kodu: "1937",
        il_adi: "KONYA",
        ilce_adi: "HALKAPINAR",
        nufus: "3.883",
        erkek_nufus: "1.932",
        kadin_nufus: "1.951",
      },
      {
        plaka_kodu: "42",
        ilce_kodu: "1804",
        il_adi: "KONYA",
        ilce_adi: "HÜYÜK",
        nufus: "15.405",
        erkek_nufus: "7.542",
        kadin_nufus: "7.863",
      },
      {
        plaka_kodu: "42",
        ilce_kodu: "1400",
        il_adi: "KONYA",
        ilce_adi: "ILGIN",
        nufus: "53.638",
        erkek_nufus: "26.495",
        kadin_nufus: "27.143",
      },
      {
        plaka_kodu: "42",
        ilce_kodu: "1422",
        il_adi: "KONYA",
        ilce_adi: "KADINHANI",
        nufus: "31.336",
        erkek_nufus: "15.772",
        kadin_nufus: "15.564",
      },
      {
        plaka_kodu: "42",
        ilce_kodu: "1441",
        il_adi: "KONYA",
        ilce_adi: "KARAPINAR",
        nufus: "50.055",
        erkek_nufus: "25.321",
        kadin_nufus: "24.734",
      },
      {
        plaka_kodu: "42",
        ilce_kodu: "1814",
        il_adi: "KONYA",
        ilce_adi: "KARATAY",
        nufus: "362.177",
        erkek_nufus: "182.088",
        kadin_nufus: "180.089",
      },
      {
        plaka_kodu: "42",
        ilce_kodu: "1491",
        il_adi: "KONYA",
        ilce_adi: "KULU",
        nufus: "51.702",
        erkek_nufus: "25.867",
        kadin_nufus: "25.835",
      },
      {
        plaka_kodu: "42",
        ilce_kodu: "1827",
        il_adi: "KONYA",
        ilce_adi: "MERAM",
        nufus: "345.360",
        erkek_nufus: "171.121",
        kadin_nufus: "174.239",
      },
      {
        plaka_kodu: "42",
        ilce_kodu: "1598",
        il_adi: "KONYA",
        ilce_adi: "SARAYÖNÜ",
        nufus: "27.738",
        erkek_nufus: "13.865",
        kadin_nufus: "13.873",
      },
      {
        plaka_kodu: "42",
        ilce_kodu: "1839",
        il_adi: "KONYA",
        ilce_adi: "SELÇUKLU",
        nufus: "682.514",
        erkek_nufus: "336.290",
        kadin_nufus: "346.224",
      },
      {
        plaka_kodu: "42",
        ilce_kodu: "1617",
        il_adi: "KONYA",
        ilce_adi: "SEYDİŞEHİR",
        nufus: "65.381",
        erkek_nufus: "32.777",
        kadin_nufus: "32.604",
      },
      {
        plaka_kodu: "42",
        ilce_kodu: "1848",
        il_adi: "KONYA",
        ilce_adi: "TAŞKENT",
        nufus: "5.780",
        erkek_nufus: "2.873",
        kadin_nufus: "2.907",
      },
      {
        plaka_kodu: "42",
        ilce_kodu: "1990",
        il_adi: "KONYA",
        ilce_adi: "TUZLUKÇU",
        nufus: "6.234",
        erkek_nufus: "3.033",
        kadin_nufus: "3.201",
      },
      {
        plaka_kodu: "42",
        ilce_kodu: "1994",
        il_adi: "KONYA",
        ilce_adi: "YALIHÜYÜK",
        nufus: "1.532",
        erkek_nufus: "752",
        kadin_nufus: "780",
      },
      {
        plaka_kodu: "42",
        ilce_kodu: "1735",
        il_adi: "KONYA",
        ilce_adi: "YUNAK",
        nufus: "21.688",
        erkek_nufus: "10.923",
        kadin_nufus: "10.765",
      },
    ],
    kisa_bilgi:
      "M.Ö. 7. bin yıldan beri yerleşim yeri olan ve çeşitli kültürlere, medeniyetlere evsahipliği yapan Konya, yüzölçüm olarak ülkemizin en büyük ilidir.",
  },
  {
    il_adi: "Kütahya",
    plaka_kodu: "43",
    alan_kodu: "274",
    nufus: "580.701",
    bolge: "İç Anadolu",
    yuzolcumu: "577.941",
    nufus_artisi: "3,6%",
    erkek_nufus_yuzde: "49,26%",
    erkek_nufus: "284.675",
    kadin_nufus_yuzde: "50,74%",
    kadin_nufus: "293.266",
    nufus_yuzdesi_genel: "0,70%",
    ilceler: [
      {
        plaka_kodu: "43",
        ilce_kodu: "1500",
        il_adi: "KÜTAHYA",
        ilce_adi: "MERKEZ",
        nufus: "277.270",
        erkek_nufus: "136.730",
        kadin_nufus: "140.540",
      },
      {
        plaka_kodu: "43",
        ilce_kodu: "1132",
        il_adi: "KÜTAHYA",
        ilce_adi: "ALTINTAŞ",
        nufus: "15.506",
        erkek_nufus: "7.780",
        kadin_nufus: "7.726",
      },
      {
        plaka_kodu: "43",
        ilce_kodu: "1764",
        il_adi: "KÜTAHYA",
        ilce_adi: "ASLANAPA",
        nufus: "8.535",
        erkek_nufus: "4.258",
        kadin_nufus: "4.277",
      },
      {
        plaka_kodu: "43",
        ilce_kodu: "1898",
        il_adi: "KÜTAHYA",
        ilce_adi: "ÇAVDARHİSAR",
        nufus: "5.892",
        erkek_nufus: "2.846",
        kadin_nufus: "3.046",
      },
      {
        plaka_kodu: "43",
        ilce_kodu: "1288",
        il_adi: "KÜTAHYA",
        ilce_adi: "DOMANİÇ",
        nufus: "14.225",
        erkek_nufus: "6.972",
        kadin_nufus: "7.253",
      },
      {
        plaka_kodu: "43",
        ilce_kodu: "1790",
        il_adi: "KÜTAHYA",
        ilce_adi: "DUMLUPINAR",
        nufus: "2.944",
        erkek_nufus: "1.484",
        kadin_nufus: "1.460",
      },
      {
        plaka_kodu: "43",
        ilce_kodu: "1304",
        il_adi: "KÜTAHYA",
        ilce_adi: "EMET",
        nufus: "19.380",
        erkek_nufus: "9.276",
        kadin_nufus: "10.104",
      },
      {
        plaka_kodu: "43",
        ilce_kodu: "1339",
        il_adi: "KÜTAHYA",
        ilce_adi: "GEDİZ",
        nufus: "50.102",
        erkek_nufus: "24.244",
        kadin_nufus: "25.858",
      },
      {
        plaka_kodu: "43",
        ilce_kodu: "1802",
        il_adi: "KÜTAHYA",
        ilce_adi: "HİSARCIK",
        nufus: "11.491",
        erkek_nufus: "5.526",
        kadin_nufus: "5.965",
      },
      {
        plaka_kodu: "43",
        ilce_kodu: "1979",
        il_adi: "KÜTAHYA",
        ilce_adi: "PAZARLAR",
        nufus: "4.813",
        erkek_nufus: "2.369",
        kadin_nufus: "2.444",
      },
      {
        plaka_kodu: "43",
        ilce_kodu: "1625",
        il_adi: "KÜTAHYA",
        ilce_adi: "SİMAV",
        nufus: "61.845",
        erkek_nufus: "30.286",
        kadin_nufus: "31.559",
      },
      {
        plaka_kodu: "43",
        ilce_kodu: "1843",
        il_adi: "KÜTAHYA",
        ilce_adi: "ŞAPHANE",
        nufus: "5.805",
        erkek_nufus: "2.771",
        kadin_nufus: "3.034",
      },
      {
        plaka_kodu: "43",
        ilce_kodu: "1671",
        il_adi: "KÜTAHYA",
        ilce_adi: "TAVŞANLI",
        nufus: "100.832",
        erkek_nufus: "50.197",
        kadin_nufus: "50.635",
      },
    ],
    kisa_bilgi:
      "Ülkemizin İç Batı Anadolu bölümünde bulunan, porselenleri, tertemiz doğası, maden yatakları ile önemli bir ilimiz olan Kütahya, jeolojik oluşum açısından da oldukça eski dönemlere dek uzanır.",
  },
  {
    il_adi: "Malatya",
    plaka_kodu: "44",
    alan_kodu: "422",
    nufus: "812.580",
    bolge: "Doğu Anadolu",
    yuzolcumu: "12259",
    nufus_artisi: "4,8%",
    erkek_nufus_yuzde: "49,79%",
    erkek_nufus: "396.877",
    kadin_nufus_yuzde: "50,21%",
    kadin_nufus: "400.159",
    nufus_yuzdesi_genel: "0,97%",
    ilceler: [
      {
        plaka_kodu: "44",
        ilce_kodu: "1114",
        il_adi: "MALATYA",
        ilce_adi: "AKÇADAĞ",
        nufus: "28.283",
        erkek_nufus: "15.648",
        kadin_nufus: "12.635",
      },
      {
        plaka_kodu: "44",
        ilce_kodu: "1143",
        il_adi: "MALATYA",
        ilce_adi: "ARAPGİR",
        nufus: "10.119",
        erkek_nufus: "5.236",
        kadin_nufus: "4.883",
      },
      {
        plaka_kodu: "44",
        ilce_kodu: "1148",
        il_adi: "MALATYA",
        ilce_adi: "ARGUVAN",
        nufus: "7.105",
        erkek_nufus: "3.638",
        kadin_nufus: "3.467",
      },
      {
        plaka_kodu: "44",
        ilce_kodu: "1772",
        il_adi: "MALATYA",
        ilce_adi: "BATTALGAZİ",
        nufus: "304.750",
        erkek_nufus: "150.100",
        kadin_nufus: "154.650",
      },
      {
        plaka_kodu: "44",
        ilce_kodu: "1265",
        il_adi: "MALATYA",
        ilce_adi: "DARENDE",
        nufus: "24.923",
        erkek_nufus: "12.521",
        kadin_nufus: "12.402",
      },
      {
        plaka_kodu: "44",
        ilce_kodu: "1286",
        il_adi: "MALATYA",
        ilce_adi: "DOĞANŞEHİR",
        nufus: "38.136",
        erkek_nufus: "19.413",
        kadin_nufus: "18.723",
      },
      {
        plaka_kodu: "44",
        ilce_kodu: "1914",
        il_adi: "MALATYA",
        ilce_adi: "DOĞANYOL",
        nufus: "3.794",
        erkek_nufus: "1.946",
        kadin_nufus: "1.848",
      },
      {
        plaka_kodu: "44",
        ilce_kodu: "1390",
        il_adi: "MALATYA",
        ilce_adi: "HEKİMHAN",
        nufus: "16.381",
        erkek_nufus: "8.337",
        kadin_nufus: "8.044",
      },
      {
        plaka_kodu: "44",
        ilce_kodu: "1953",
        il_adi: "MALATYA",
        ilce_adi: "KALE",
        nufus: "5.503",
        erkek_nufus: "2.742",
        kadin_nufus: "2.761",
      },
      {
        plaka_kodu: "44",
        ilce_kodu: "1969",
        il_adi: "MALATYA",
        ilce_adi: "KULUNCAK",
        nufus: "7.244",
        erkek_nufus: "3.656",
        kadin_nufus: "3.588",
      },
      {
        plaka_kodu: "44",
        ilce_kodu: "1582",
        il_adi: "MALATYA",
        ilce_adi: "PÜTÜRGE",
        nufus: "12.976",
        erkek_nufus: "6.417",
        kadin_nufus: "6.559",
      },
      {
        plaka_kodu: "44",
        ilce_kodu: "1995",
        il_adi: "MALATYA",
        ilce_adi: "YAZIHAN",
        nufus: "12.429",
        erkek_nufus: "6.427",
        kadin_nufus: "6.002",
      },
      {
        plaka_kodu: "44",
        ilce_kodu: "1729",
        il_adi: "MALATYA",
        ilce_adi: "YEŞİLYURT",
        nufus: "337.049",
        erkek_nufus: "167.526",
        kadin_nufus: "169.523",
      },
    ],
    kisa_bilgi:
      "Doğu Anadolu Bölgesi'nin Yukarı Fırat Bölümü'nde yer alan Malatya, kayısılarıyla dünya çapında bir üne sahiptir. Bu vesileyle her sene Malatya Fuarı ve Uluslararası Kültür, Sanat ve Kayısı Festivali yapılmaktadır.",
  },
  {
    il_adi: "Manisa",
    plaka_kodu: "45",
    alan_kodu: "236",
    nufus: "1.468.279",
    bolge: "Ege",
    yuzolcumu: "13.339",
    nufus_artisi: "8,0%",
    erkek_nufus_yuzde: "50,39%",
    erkek_nufus: "720.337",
    kadin_nufus_yuzde: "49,61%",
    kadin_nufus: "709.306",
    nufus_yuzdesi_genel: "1,74%",
    ilceler: [
      {
        plaka_kodu: "45",
        ilce_kodu: "1751",
        il_adi: "MANİSA",
        ilce_adi: "AHMETLİ",
        nufus: "16.807",
        erkek_nufus: "8.401",
        kadin_nufus: "8.406",
      },
      {
        plaka_kodu: "45",
        ilce_kodu: "1118",
        il_adi: "MANİSA",
        ilce_adi: "AKHİSAR",
        nufus: "176.000",
        erkek_nufus: "88.452",
        kadin_nufus: "87.548",
      },
      {
        plaka_kodu: "45",
        ilce_kodu: "1127",
        il_adi: "MANİSA",
        ilce_adi: "ALAŞEHİR",
        nufus: "105.380",
        erkek_nufus: "53.876",
        kadin_nufus: "51.504",
      },
      {
        plaka_kodu: "45",
        ilce_kodu: "1269",
        il_adi: "MANİSA",
        ilce_adi: "DEMİRCİ",
        nufus: "37.021",
        erkek_nufus: "17.639",
        kadin_nufus: "19.382",
      },
      {
        plaka_kodu: "45",
        ilce_kodu: "1793",
        il_adi: "MANİSA",
        ilce_adi: "GÖLMARMARA",
        nufus: "15.197",
        erkek_nufus: "7.661",
        kadin_nufus: "7.536",
      },
      {
        plaka_kodu: "45",
        ilce_kodu: "1362",
        il_adi: "MANİSA",
        ilce_adi: "GÖRDES",
        nufus: "26.858",
        erkek_nufus: "13.221",
        kadin_nufus: "13.637",
      },
      {
        plaka_kodu: "45",
        ilce_kodu: "1470",
        il_adi: "MANİSA",
        ilce_adi: "KIRKAĞAÇ",
        nufus: "37.699",
        erkek_nufus: "18.907",
        kadin_nufus: "18.792",
      },
      {
        plaka_kodu: "45",
        ilce_kodu: "1965",
        il_adi: "MANİSA",
        ilce_adi: "KÖPRÜBAŞI",
        nufus: "12.811",
        erkek_nufus: "6.337",
        kadin_nufus: "6.474",
      },
      {
        plaka_kodu: "45",
        ilce_kodu: "1489",
        il_adi: "MANİSA",
        ilce_adi: "KULA",
        nufus: "43.421",
        erkek_nufus: "21.419",
        kadin_nufus: "22.002",
      },
      {
        plaka_kodu: "45",
        ilce_kodu: "1590",
        il_adi: "MANİSA",
        ilce_adi: "SALİHLİ",
        nufus: "164.710",
        erkek_nufus: "81.969",
        kadin_nufus: "82.741",
      },
      {
        plaka_kodu: "45",
        ilce_kodu: "1600",
        il_adi: "MANİSA",
        ilce_adi: "SARIGÖL",
        nufus: "35.766",
        erkek_nufus: "17.746",
        kadin_nufus: "18.020",
      },
      {
        plaka_kodu: "45",
        ilce_kodu: "1606",
        il_adi: "MANİSA",
        ilce_adi: "SARUHANLI",
        nufus: "56.166",
        erkek_nufus: "28.272",
        kadin_nufus: "27.894",
      },
      {
        plaka_kodu: "45",
        ilce_kodu: "1613",
        il_adi: "MANİSA",
        ilce_adi: "SELENDİ",
        nufus: "19.505",
        erkek_nufus: "9.741",
        kadin_nufus: "9.764",
      },
      {
        plaka_kodu: "45",
        ilce_kodu: "1634",
        il_adi: "MANİSA",
        ilce_adi: "SOMA",
        nufus: "111.218",
        erkek_nufus: "55.752",
        kadin_nufus: "55.466",
      },
      {
        plaka_kodu: "45",
        ilce_kodu: "2086",
        il_adi: "MANİSA",
        ilce_adi: "ŞEHZADELER",
        nufus: "167.661",
        erkek_nufus: "85.253",
        kadin_nufus: "82.408",
      },
      {
        plaka_kodu: "45",
        ilce_kodu: "1689",
        il_adi: "MANİSA",
        ilce_adi: "TURGUTLU",
        nufus: "172.413",
        erkek_nufus: "86.677",
        kadin_nufus: "85.736",
      },
      {
        plaka_kodu: "45",
        ilce_kodu: "2087",
        il_adi: "MANİSA",
        ilce_adi: "YUNUSEMRE",
        nufus: "257.993",
        erkek_nufus: "129.885",
        kadin_nufus: "128.108",
      },
    ],
    kisa_bilgi:
      "Ege bölgesinin ortasında bulunan, 2012 yılında büyükşehir unvanını alan Manisa, bölgenin İzmir'den sonra ikinci büyük sanayi ve ticaret merkezidir. ",
  },
  {
    il_adi: "Kahramanmaraş",
    plaka_kodu: "46",
    alan_kodu: "344",
    nufus: "1.177.436",
    bolge: "Akdeniz",
    yuzolcumu: "14.520",
    nufus_artisi: "5,2%",
    erkek_nufus_yuzde: "50,70%",
    erkek_nufus: "580.410",
    kadin_nufus_yuzde: "49,30%",
    kadin_nufus: "564.441",
    nufus_yuzdesi_genel: "1,40%",
    ilceler: [
      {
        plaka_kodu: "46",
        ilce_kodu: "1107",
        il_adi: "KAHRAMANMARAŞ",
        ilce_adi: "AFŞİN",
        nufus: "80.138",
        erkek_nufus: "40.759",
        kadin_nufus: "39.379",
      },
      {
        plaka_kodu: "46",
        ilce_kodu: "1136",
        il_adi: "KAHRAMANMARAŞ",
        ilce_adi: "ANDIRIN",
        nufus: "31.765",
        erkek_nufus: "16.163",
        kadin_nufus: "15.602",
      },
      {
        plaka_kodu: "46",
        ilce_kodu: "1785",
        il_adi: "KAHRAMANMARAŞ",
        ilce_adi: "ÇAĞLAYANCERİT",
        nufus: "22.893",
        erkek_nufus: "11.760",
        kadin_nufus: "11.133",
      },
      {
        plaka_kodu: "46",
        ilce_kodu: "2084",
        il_adi: "KAHRAMANMARAŞ",
        ilce_adi: "DULKADİROĞLU",
        nufus: "223.532",
        erkek_nufus: "114.316",
        kadin_nufus: "109.216",
      },
      {
        plaka_kodu: "46",
        ilce_kodu: "1919",
        il_adi: "KAHRAMANMARAŞ",
        ilce_adi: "EKİNÖZÜ",
        nufus: "10.631",
        erkek_nufus: "5.603",
        kadin_nufus: "5.028",
      },
      {
        plaka_kodu: "46",
        ilce_kodu: "1299",
        il_adi: "KAHRAMANMARAŞ",
        ilce_adi: "ELBİSTAN",
        nufus: "141.977",
        erkek_nufus: "72.335",
        kadin_nufus: "69.642",
      },
      {
        plaka_kodu: "46",
        ilce_kodu: "1353",
        il_adi: "KAHRAMANMARAŞ",
        ilce_adi: "GÖKSUN",
        nufus: "51.255",
        erkek_nufus: "25.954",
        kadin_nufus: "25.301",
      },
      {
        plaka_kodu: "46",
        ilce_kodu: "1975",
        il_adi: "KAHRAMANMARAŞ",
        ilce_adi: "NURHAK",
        nufus: "12.323",
        erkek_nufus: "6.389",
        kadin_nufus: "5.934",
      },
      {
        plaka_kodu: "46",
        ilce_kodu: "2085",
        il_adi: "KAHRAMANMARAŞ",
        ilce_adi: "ONİKİŞUBAT",
        nufus: "448.317",
        erkek_nufus: "224.415",
        kadin_nufus: "223.902",
      },
      {
        plaka_kodu: "46",
        ilce_kodu: "1570",
        il_adi: "KAHRAMANMARAŞ",
        ilce_adi: "PAZARCIK",
        nufus: "69.879",
        erkek_nufus: "35.207",
        kadin_nufus: "34.672",
      },
      {
        plaka_kodu: "46",
        ilce_kodu: "1694",
        il_adi: "KAHRAMANMARAŞ",
        ilce_adi: "TÜRKOĞLU",
        nufus: "78.588",
        erkek_nufus: "41.875",
        kadin_nufus: "36.713",
      },
    ],
    kisa_bilgi:
      "2012 yılında Büyükşehir unvanı alan, dövme dondurmasıyla dünyaca meşhur Kahramanmaraş’a, Kurtuluş Savaşı’ndaki direnişi nedeniyle İstiklal Madalyası verilmiştir. ",
  },
  {
    il_adi: "Mardin",
    plaka_kodu: "47",
    alan_kodu: "482",
    nufus: "870.374",
    bolge: "Güneydoğu Anadolu",
    yuzolcumu: "8.780",
    nufus_artisi: "8,8%",
    erkek_nufus_yuzde: "50,29%",
    erkek_nufus: "417.000",
    kadin_nufus_yuzde: "49,71%",
    kadin_nufus: "412.195",
    nufus_yuzdesi_genel: "1,01%",
    ilceler: [
      {
        plaka_kodu: "47",
        ilce_kodu: "2088",
        il_adi: "MARDİN",
        ilce_adi: "ARTUKLU",
        nufus: "186.622",
        erkek_nufus: "93.822",
        kadin_nufus: "92.800",
      },
      {
        plaka_kodu: "47",
        ilce_kodu: "1787",
        il_adi: "MARDİN",
        ilce_adi: "DARGEÇİT",
        nufus: "27.307",
        erkek_nufus: "13.463",
        kadin_nufus: "13.844",
      },
      {
        plaka_kodu: "47",
        ilce_kodu: "1273",
        il_adi: "MARDİN",
        ilce_adi: "DERİK",
        nufus: "62.203",
        erkek_nufus: "31.008",
        kadin_nufus: "31.195",
      },
      {
        plaka_kodu: "47",
        ilce_kodu: "1474",
        il_adi: "MARDİN",
        ilce_adi: "KIZILTEPE",
        nufus: "263.938",
        erkek_nufus: "132.780",
        kadin_nufus: "131.158",
      },
      {
        plaka_kodu: "47",
        ilce_kodu: "1519",
        il_adi: "MARDİN",
        ilce_adi: "MAZIDAĞI",
        nufus: "36.773",
        erkek_nufus: "18.695",
        kadin_nufus: "18.078",
      },
      {
        plaka_kodu: "47",
        ilce_kodu: "1526",
        il_adi: "MARDİN",
        ilce_adi: "MİDYAT",
        nufus: "118.625",
        erkek_nufus: "59.124",
        kadin_nufus: "59.501",
      },
      {
        plaka_kodu: "47",
        ilce_kodu: "1547",
        il_adi: "MARDİN",
        ilce_adi: "NUSAYBİN",
        nufus: "113.728",
        erkek_nufus: "56.381",
        kadin_nufus: "57.347",
      },
      {
        plaka_kodu: "47",
        ilce_kodu: "1564",
        il_adi: "MARDİN",
        ilce_adi: "ÖMERLİ",
        nufus: "13.970",
        erkek_nufus: "7.174",
        kadin_nufus: "6.796",
      },
      {
        plaka_kodu: "47",
        ilce_kodu: "1609",
        il_adi: "MARDİN",
        ilce_adi: "SAVUR",
        nufus: "25.496",
        erkek_nufus: "12.695",
        kadin_nufus: "12.801",
      },
      {
        plaka_kodu: "47",
        ilce_kodu: "2002",
        il_adi: "MARDİN",
        ilce_adi: "YEŞİLLİ",
        nufus: "14.095",
        erkek_nufus: "7.146",
        kadin_nufus: "6.949",
      },
    ],
    kisa_bilgi:
      "Suriye ile sınır komşusu olan, İpek Yolu üzerinde yer alan Mardin, Yukarı Mezopotamya’nın en eski şehirlerinden biridir ve tarih boyunca çeşitli kültürlere ev sahipliği yapmıştır.",
  },
  {
    il_adi: "Muğla",
    plaka_kodu: "48",
    alan_kodu: "252",
    nufus: "1.048.185",
    bolge: "Ege",
    yuzolcumu: "12654",
    nufus_artisi: "26,1%",
    erkek_nufus_yuzde: "50,97%",
    erkek_nufus: "493.140",
    kadin_nufus_yuzde: "49,03%",
    kadin_nufus: "474.347",
    nufus_yuzdesi_genel: "1,18%",
    ilceler: [
      {
        plaka_kodu: "48",
        ilce_kodu: "1197",
        il_adi: "MUĞLA",
        ilce_adi: "BODRUM",
        nufus: "187.284",
        erkek_nufus: "95.069",
        kadin_nufus: "92.215",
      },
      {
        plaka_kodu: "48",
        ilce_kodu: "1742",
        il_adi: "MUĞLA",
        ilce_adi: "DALAMAN",
        nufus: "44.386",
        erkek_nufus: "22.882",
        kadin_nufus: "21.504",
      },
      {
        plaka_kodu: "48",
        ilce_kodu: "1266",
        il_adi: "MUĞLA",
        ilce_adi: "DATÇA",
        nufus: "24.519",
        erkek_nufus: "12.438",
        kadin_nufus: "12.081",
      },
      {
        plaka_kodu: "48",
        ilce_kodu: "1331",
        il_adi: "MUĞLA",
        ilce_adi: "FETHİYE",
        nufus: "170.379",
        erkek_nufus: "85.266",
        kadin_nufus: "85.113",
      },
      {
        plaka_kodu: "48",
        ilce_kodu: "1958",
        il_adi: "MUĞLA",
        ilce_adi: "KAVAKLIDERE",
        nufus: "10.842",
        erkek_nufus: "5.485",
        kadin_nufus: "5.357",
      },
      {
        plaka_kodu: "48",
        ilce_kodu: "1488",
        il_adi: "MUĞLA",
        ilce_adi: "KÖYCEĞİZ",
        nufus: "38.425",
        erkek_nufus: "19.419",
        kadin_nufus: "19.006",
      },
      {
        plaka_kodu: "48",
        ilce_kodu: "1517",
        il_adi: "MUĞLA",
        ilce_adi: "MARMARİS",
        nufus: "95.849",
        erkek_nufus: "49.920",
        kadin_nufus: "45.929",
      },
      {
        plaka_kodu: "48",
        ilce_kodu: "2089",
        il_adi: "MUĞLA",
        ilce_adi: "MENTEŞE",
        nufus: "118.443",
        erkek_nufus: "59.506",
        kadin_nufus: "58.937",
      },
      {
        plaka_kodu: "48",
        ilce_kodu: "1528",
        il_adi: "MUĞLA",
        ilce_adi: "MİLAS",
        nufus: "145.275",
        erkek_nufus: "73.628",
        kadin_nufus: "71.647",
      },
      {
        plaka_kodu: "48",
        ilce_kodu: "1831",
        il_adi: "MUĞLA",
        ilce_adi: "ORTACA",
        nufus: "52.929",
        erkek_nufus: "26.452",
        kadin_nufus: "26.477",
      },
      {
        plaka_kodu: "48",
        ilce_kodu: "2090",
        il_adi: "MUĞLA",
        ilce_adi: "SEYDİKEMER",
        nufus: "61.427",
        erkek_nufus: "31.975",
        kadin_nufus: "29.452",
      },
      {
        plaka_kodu: "48",
        ilce_kodu: "1695",
        il_adi: "MUĞLA",
        ilce_adi: "ULA",
        nufus: "26.306",
        erkek_nufus: "13.265",
        kadin_nufus: "13.041",
      },
      {
        plaka_kodu: "48",
        ilce_kodu: "1719",
        il_adi: "MUĞLA",
        ilce_adi: "YATAĞAN",
        nufus: "45.077",
        erkek_nufus: "22.656",
        kadin_nufus: "22.421",
      },
    ],
    kisa_bilgi:
      "Fethiye, Bodrum, Marmaris gibi turistik beldeleriyle meşhur ilin topraklarının büyük kısmı Ege’de, küçük bir kısmı ise Akdeniz’de yer alıyor.",
  },
  {
    il_adi: "Muş",
    plaka_kodu: "49",
    alan_kodu: "436",
    nufus: "399.202",
    bolge: "Doğu Anadolu",
    yuzolcumu: "8.650",
    nufus_artisi: "-15,0%",
    erkek_nufus_yuzde: "51,09%",
    erkek_nufus: "208.431",
    kadin_nufus_yuzde: "48,91%",
    kadin_nufus: "199.561",
    nufus_yuzdesi_genel: "0,50%",
    ilceler: [
      {
        plaka_kodu: "49",
        ilce_kodu: "1534",
        il_adi: "MUŞ",
        ilce_adi: "MERKEZ",
        nufus: "198.578",
        erkek_nufus: "101.036",
        kadin_nufus: "97.542",
      },
      {
        plaka_kodu: "49",
        ilce_kodu: "1213",
        il_adi: "MUŞ",
        ilce_adi: "BULANIK",
        nufus: "77.592",
        erkek_nufus: "39.643",
        kadin_nufus: "37.949",
      },
      {
        plaka_kodu: "49",
        ilce_kodu: "1801",
        il_adi: "MUŞ",
        ilce_adi: "HASKÖY",
        nufus: "25.827",
        erkek_nufus: "13.153",
        kadin_nufus: "12.674",
      },
      {
        plaka_kodu: "49",
        ilce_kodu: "1964",
        il_adi: "MUŞ",
        ilce_adi: "KORKUT",
        nufus: "24.057",
        erkek_nufus: "12.272",
        kadin_nufus: "11.785",
      },
      {
        plaka_kodu: "49",
        ilce_kodu: "1510",
        il_adi: "MUŞ",
        ilce_adi: "MALAZGİRT",
        nufus: "48.192",
        erkek_nufus: "24.588",
        kadin_nufus: "23.604",
      },
      {
        plaka_kodu: "49",
        ilce_kodu: "1711",
        il_adi: "MUŞ",
        ilce_adi: "VARTO",
        nufus: "30.982",
        erkek_nufus: "15.683",
        kadin_nufus: "15.299",
      },
    ],
    kisa_bilgi:
      "Doğu Anadolu bölgesinde bulunan ilimiz, M.Ö. 2000’e uzanan tarihi, kendine özgü lalesi ve merkeze 8 km mesafede bulunan Güzeltepe Kayak Merkezi ile dikkat çekiyor.",
  },
  {
    il_adi: "Nevşehir",
    plaka_kodu: "50",
    alan_kodu: "384",
    nufus: "310.011",
    bolge: "İç Anadolu",
    yuzolcumu: "5.485",
    nufus_artisi: "6,5%",
    erkek_nufus_yuzde: "49,42%",
    erkek_nufus: "147.438",
    kadin_nufus_yuzde: "50,58%",
    kadin_nufus: "150.901",
    nufus_yuzdesi_genel: "0,36%",
    ilceler: [
      {
        plaka_kodu: "50",
        ilce_kodu: "1543",
        il_adi: "NEVŞEHİR",
        ilce_adi: "MERKEZ",
        nufus: "153.117",
        erkek_nufus: "75.295",
        kadin_nufus: "77.822",
      },
      {
        plaka_kodu: "50",
        ilce_kodu: "1749",
        il_adi: "NEVŞEHİR",
        ilce_adi: "ACIGÖL",
        nufus: "19.211",
        erkek_nufus: "9.830",
        kadin_nufus: "9.381",
      },
      {
        plaka_kodu: "50",
        ilce_kodu: "1155",
        il_adi: "NEVŞEHİR",
        ilce_adi: "AVANOS",
        nufus: "33.047",
        erkek_nufus: "16.417",
        kadin_nufus: "16.630",
      },
      {
        plaka_kodu: "50",
        ilce_kodu: "1274",
        il_adi: "NEVŞEHİR",
        ilce_adi: "DERİNKUYU",
        nufus: "20.786",
        erkek_nufus: "10.486",
        kadin_nufus: "10.300",
      },
      {
        plaka_kodu: "50",
        ilce_kodu: "1367",
        il_adi: "NEVŞEHİR",
        ilce_adi: "GÜLŞEHİR",
        nufus: "21.504",
        erkek_nufus: "10.502",
        kadin_nufus: "11.002",
      },
      {
        plaka_kodu: "50",
        ilce_kodu: "1374",
        il_adi: "NEVŞEHİR",
        ilce_adi: "HACIBEKTAŞ",
        nufus: "10.933",
        erkek_nufus: "5.308",
        kadin_nufus: "5.625",
      },
      {
        plaka_kodu: "50",
        ilce_kodu: "1485",
        il_adi: "NEVŞEHİR",
        ilce_adi: "KOZAKLI",
        nufus: "13.044",
        erkek_nufus: "6.504",
        kadin_nufus: "6.540",
      },
      {
        plaka_kodu: "50",
        ilce_kodu: "1707",
        il_adi: "NEVŞEHİR",
        ilce_adi: "ÜRGÜP",
        nufus: "36.361",
        erkek_nufus: "17.847",
        kadin_nufus: "18.514",
      },
    ],
    kisa_bilgi:
      "Nevşehir, ülkemizin en önemli turistik merkezlerinden biri olan, peri bacaları, muhteşem güzelliği ve M.Ö. 3000’lere ulaşan tarihiyle UNESCO Dünya Mirası Listesinde yer alan Kapadokya’nın merkezinde bulunuyor.",
  },
  {
    il_adi: "Niğde",
    plaka_kodu: "51",
    alan_kodu: "388",
    nufus: "365.419",
    bolge: "İç Anadolu",
    yuzolcumu: "7.234",
    nufus_artisi: "4,6%",
    erkek_nufus_yuzde: "50,21%",
    erkek_nufus: "183.109",
    kadin_nufus_yuzde: "49,79%",
    kadin_nufus: "181.598",
    nufus_yuzdesi_genel: "0,44%",
    ilceler: [
      {
        plaka_kodu: "51",
        ilce_kodu: "1544",
        il_adi: "NİĞDE",
        ilce_adi: "MERKEZ",
        nufus: "234.118",
        erkek_nufus: "117.001",
        kadin_nufus: "117.117",
      },
      {
        plaka_kodu: "51",
        ilce_kodu: "1876",
        il_adi: "NİĞDE",
        ilce_adi: "ALTUNHİSAR",
        nufus: "11.934",
        erkek_nufus: "6.038",
        kadin_nufus: "5.896",
      },
      {
        plaka_kodu: "51",
        ilce_kodu: "1201",
        il_adi: "NİĞDE",
        ilce_adi: "BOR",
        nufus: "60.478",
        erkek_nufus: "30.137",
        kadin_nufus: "30.341",
      },
      {
        plaka_kodu: "51",
        ilce_kodu: "1225",
        il_adi: "NİĞDE",
        ilce_adi: "ÇAMARDI",
        nufus: "11.940",
        erkek_nufus: "5.830",
        kadin_nufus: "6.110",
      },
      {
        plaka_kodu: "51",
        ilce_kodu: "1904",
        il_adi: "NİĞDE",
        ilce_adi: "ÇİFTLİK",
        nufus: "26.206",
        erkek_nufus: "13.543",
        kadin_nufus: "12.663",
      },
      {
        plaka_kodu: "51",
        ilce_kodu: "1700",
        il_adi: "NİĞDE",
        ilce_adi: "ULUKIŞLA",
        nufus: "19.049",
        erkek_nufus: "9.667",
        kadin_nufus: "9.382",
      },
    ],
    kisa_bilgi:
      "Kapadokya bölgesinin bu güzel turistik şehri, üretimine 1962 yılında başlanan gazozu ve elmasıyla meşhur olmasının yanında, pek çok medeniyetin hüküm sürdüğü 6000 yıllık koca bir tarihi zenginliğe sahip. ",
  },
  {
    il_adi: "Ordu",
    plaka_kodu: "52",
    alan_kodu: "452",
    nufus: "763.190",
    bolge: "Karadeniz",
    yuzolcumu: "5.861",
    nufus_artisi: "3,0%",
    erkek_nufus_yuzde: "50,08%",
    erkek_nufus: "386.547",
    kadin_nufus_yuzde: "49,92%",
    kadin_nufus: "385.385",
    nufus_yuzdesi_genel: "0,94%",
    ilceler: [
      {
        plaka_kodu: "52",
        ilce_kodu: "1119",
        il_adi: "ORDU",
        ilce_adi: "AKKUŞ",
        nufus: "21.684",
        erkek_nufus: "11.239",
        kadin_nufus: "10.445",
      },
      {
        plaka_kodu: "52",
        ilce_kodu: "2103",
        il_adi: "ORDU",
        ilce_adi: "ALTINORDU",
        nufus: "229.214",
        erkek_nufus: "111.589",
        kadin_nufus: "117.625",
      },
      {
        plaka_kodu: "52",
        ilce_kodu: "1158",
        il_adi: "ORDU",
        ilce_adi: "AYBASTI",
        nufus: "21.682",
        erkek_nufus: "10.706",
        kadin_nufus: "10.976",
      },
      {
        plaka_kodu: "52",
        ilce_kodu: "1891",
        il_adi: "ORDU",
        ilce_adi: "ÇAMAŞ",
        nufus: "8.434",
        erkek_nufus: "4.327",
        kadin_nufus: "4.107",
      },
      {
        plaka_kodu: "52",
        ilce_kodu: "1897",
        il_adi: "ORDU",
        ilce_adi: "ÇATALPINAR",
        nufus: "13.217",
        erkek_nufus: "6.636",
        kadin_nufus: "6.581",
      },
      {
        plaka_kodu: "52",
        ilce_kodu: "1900",
        il_adi: "ORDU",
        ilce_adi: "ÇAYBAŞI",
        nufus: "12.159",
        erkek_nufus: "6.221",
        kadin_nufus: "5.938",
      },
      {
        plaka_kodu: "52",
        ilce_kodu: "1328",
        il_adi: "ORDU",
        ilce_adi: "FATSA",
        nufus: "124.255",
        erkek_nufus: "61.783",
        kadin_nufus: "62.472",
      },
      {
        plaka_kodu: "52",
        ilce_kodu: "1358",
        il_adi: "ORDU",
        ilce_adi: "GÖLKÖY",
        nufus: "27.076",
        erkek_nufus: "13.766",
        kadin_nufus: "13.310",
      },
      {
        plaka_kodu: "52",
        ilce_kodu: "1795",
        il_adi: "ORDU",
        ilce_adi: "GÜLYALI",
        nufus: "8.221",
        erkek_nufus: "4.143",
        kadin_nufus: "4.078",
      },
      {
        plaka_kodu: "52",
        ilce_kodu: "1797",
        il_adi: "ORDU",
        ilce_adi: "GÜRGENTEPE",
        nufus: "13.112",
        erkek_nufus: "6.632",
        kadin_nufus: "6.480",
      },
      {
        plaka_kodu: "52",
        ilce_kodu: "1947",
        il_adi: "ORDU",
        ilce_adi: "İKİZCE",
        nufus: "13.804",
        erkek_nufus: "7.142",
        kadin_nufus: "6.662",
      },
      {
        plaka_kodu: "52",
        ilce_kodu: "1950",
        il_adi: "ORDU",
        ilce_adi: "KABADÜZ",
        nufus: "7.587",
        erkek_nufus: "4.123",
        kadin_nufus: "3.464",
      },
      {
        plaka_kodu: "52",
        ilce_kodu: "1951",
        il_adi: "ORDU",
        ilce_adi: "KABATAŞ",
        nufus: "10.328",
        erkek_nufus: "5.160",
        kadin_nufus: "5.168",
      },
      {
        plaka_kodu: "52",
        ilce_kodu: "1482",
        il_adi: "ORDU",
        ilce_adi: "KORGAN",
        nufus: "27.856",
        erkek_nufus: "14.106",
        kadin_nufus: "13.750",
      },
      {
        plaka_kodu: "52",
        ilce_kodu: "1493",
        il_adi: "ORDU",
        ilce_adi: "KUMRU",
        nufus: "29.228",
        erkek_nufus: "14.726",
        kadin_nufus: "14.502",
      },
      {
        plaka_kodu: "52",
        ilce_kodu: "1525",
        il_adi: "ORDU",
        ilce_adi: "MESUDİYE",
        nufus: "13.846",
        erkek_nufus: "7.168",
        kadin_nufus: "6.678",
      },
      {
        plaka_kodu: "52",
        ilce_kodu: "1573",
        il_adi: "ORDU",
        ilce_adi: "PERŞEMBE",
        nufus: "30.616",
        erkek_nufus: "15.973",
        kadin_nufus: "14.643",
      },
      {
        plaka_kodu: "52",
        ilce_kodu: "1696",
        il_adi: "ORDU",
        ilce_adi: "ULUBEY",
        nufus: "17.861",
        erkek_nufus: "9.221",
        kadin_nufus: "8.640",
      },
      {
        plaka_kodu: "52",
        ilce_kodu: "1706",
        il_adi: "ORDU",
        ilce_adi: "ÜNYE",
        nufus: "130.692",
        erkek_nufus: "65.039",
        kadin_nufus: "65.653",
      },
    ],
    kisa_bilgi:
      "Karadeniz kıyısında bulunan; Tokat, Sivas, Samsun ve Giresun ile komşu olan Ordu, 1920 yılında il statüsüne kavuştu. Ülkemiz fındık üretiminin yaklaşık yarısını karşılayan ilde nüfusun çoğunluğunun geçimi de fındığa dayanıyor. Ordu’da yer alan önemli fabrikalardan birkaçı şöyle; Doğuş Çay, Gürsoy Fındık, Sagra Çikolata Fabrikası gibi.",
  },
  {
    il_adi: "Rize",
    plaka_kodu: "53",
    alan_kodu: "464",
    nufus: "344.016",
    bolge: "Karadeniz",
    yuzolcumu: "3.835",
    nufus_artisi: "-4,8%",
    erkek_nufus_yuzde: "49,95%",
    erkek_nufus: "174.130",
    kadin_nufus_yuzde: "50,05%",
    kadin_nufus: "174.478",
    nufus_yuzdesi_genel: "0,43%",
    ilceler: [
      {
        plaka_kodu: "53",
        ilce_kodu: "1586",
        il_adi: "RİZE",
        ilce_adi: "MERKEZ",
        nufus: "150.414",
        erkek_nufus: "73.772",
        kadin_nufus: "76.642",
      },
      {
        plaka_kodu: "53",
        ilce_kodu: "1146",
        il_adi: "RİZE",
        ilce_adi: "ARDEŞEN",
        nufus: "42.542",
        erkek_nufus: "21.250",
        kadin_nufus: "21.292",
      },
      {
        plaka_kodu: "53",
        ilce_kodu: "1228",
        il_adi: "RİZE",
        ilce_adi: "ÇAMLIHEMŞİN",
        nufus: "6.929",
        erkek_nufus: "3.510",
        kadin_nufus: "3.419",
      },
      {
        plaka_kodu: "53",
        ilce_kodu: "1241",
        il_adi: "RİZE",
        ilce_adi: "ÇAYELİ",
        nufus: "43.478",
        erkek_nufus: "21.842",
        kadin_nufus: "21.636",
      },
      {
        plaka_kodu: "53",
        ilce_kodu: "1908",
        il_adi: "RİZE",
        ilce_adi: "DEREPAZARI",
        nufus: "7.057",
        erkek_nufus: "3.705",
        kadin_nufus: "3.352",
      },
      {
        plaka_kodu: "53",
        ilce_kodu: "1332",
        il_adi: "RİZE",
        ilce_adi: "FINDIKLI",
        nufus: "16.487",
        erkek_nufus: "8.257",
        kadin_nufus: "8.230",
      },
      {
        plaka_kodu: "53",
        ilce_kodu: "1796",
        il_adi: "RİZE",
        ilce_adi: "GÜNEYSU",
        nufus: "16.066",
        erkek_nufus: "8.056",
        kadin_nufus: "8.010",
      },
      {
        plaka_kodu: "53",
        ilce_kodu: "1943",
        il_adi: "RİZE",
        ilce_adi: "HEMŞİN",
        nufus: "2.482",
        erkek_nufus: "1.243",
        kadin_nufus: "1.239",
      },
      {
        plaka_kodu: "53",
        ilce_kodu: "1405",
        il_adi: "RİZE",
        ilce_adi: "İKİZDERE",
        nufus: "6.409",
        erkek_nufus: "3.600",
        kadin_nufus: "2.809",
      },
      {
        plaka_kodu: "53",
        ilce_kodu: "1949",
        il_adi: "RİZE",
        ilce_adi: "İYİDERE",
        nufus: "8.609",
        erkek_nufus: "4.350",
        kadin_nufus: "4.259",
      },
      {
        plaka_kodu: "53",
        ilce_kodu: "1428",
        il_adi: "RİZE",
        ilce_adi: "KALKANDERE",
        nufus: "13.511",
        erkek_nufus: "8.076",
        kadin_nufus: "5.435",
      },
      {
        plaka_kodu: "53",
        ilce_kodu: "1569",
        il_adi: "RİZE",
        ilce_adi: "PAZAR",
        nufus: "31.678",
        erkek_nufus: "15.769",
        kadin_nufus: "15.909",
      },
    ],
    kisa_bilgi:
      "Ülkemizin en çok yağış alan bu ili, herkesin bildiği gibi çayıyla, Anzer balıyla, yaylalarıyla meşhur. Muhteşem bir doğal güzelliği olan ve mutlaka görülmesi gereken illerimizden biri olan Rize, çok çeşitli doğa sporlarını yapmanıza da olanak tanıyor. Fırtına, Hemşin, Büyükdere, Taşlıdere gibi akarsularında kano ya da rafting yapabilir, yürüyüşleriniz sırasında Kaçkar Dağları Milli Parkı içinde bulunan Palovit Şelalesi’ni, Çayeli’ndeki Ağaran Şelalesi’ni fotoğraflayabilir, Çağrankaya, Petran, Handüzü, Demirkapı, Anzer, Samistal, Palovit gibi doğa harikası yaylalarında çadır kurabilirsiniz.",
  },
  {
    il_adi: "Sakarya",
    plaka_kodu: "54",
    alan_kodu: "264",
    nufus: "1.080.080",
    bolge: "Marmara",
    yuzolcumu: "4824",
    nufus_artisi: "17,9%",
    erkek_nufus_yuzde: "50,03%",
    erkek_nufus: "505.645",
    kadin_nufus_yuzde: "49,97%",
    kadin_nufus: "505.055",
    nufus_yuzdesi_genel: "1,23%",
    ilceler: [
      {
        plaka_kodu: "54",
        ilce_kodu: "2068",
        il_adi: "SAKARYA",
        ilce_adi: "ADAPAZARI",
        nufus: "280.214",
        erkek_nufus: "139.535",
        kadin_nufus: "140.679",
      },
      {
        plaka_kodu: "54",
        ilce_kodu: "1123",
        il_adi: "SAKARYA",
        ilce_adi: "AKYAZI",
        nufus: "93.087",
        erkek_nufus: "46.540",
        kadin_nufus: "46.547",
      },
      {
        plaka_kodu: "54",
        ilce_kodu: "2069",
        il_adi: "SAKARYA",
        ilce_adi: "ARİFİYE",
        nufus: "47.851",
        erkek_nufus: "25.154",
        kadin_nufus: "22.697",
      },
      {
        plaka_kodu: "54",
        ilce_kodu: "2070",
        il_adi: "SAKARYA",
        ilce_adi: "ERENLER",
        nufus: "91.602",
        erkek_nufus: "45.767",
        kadin_nufus: "45.835",
      },
      {
        plaka_kodu: "54",
        ilce_kodu: "1925",
        il_adi: "SAKARYA",
        ilce_adi: "FERİZLİ",
        nufus: "27.652",
        erkek_nufus: "14.645",
        kadin_nufus: "13.007",
      },
      {
        plaka_kodu: "54",
        ilce_kodu: "1351",
        il_adi: "SAKARYA",
        ilce_adi: "GEYVE",
        nufus: "50.332",
        erkek_nufus: "25.597",
        kadin_nufus: "24.735",
      },
      {
        plaka_kodu: "54",
        ilce_kodu: "1391",
        il_adi: "SAKARYA",
        ilce_adi: "HENDEK",
        nufus: "88.105",
        erkek_nufus: "43.660",
        kadin_nufus: "44.445",
      },
      {
        plaka_kodu: "54",
        ilce_kodu: "1955",
        il_adi: "SAKARYA",
        ilce_adi: "KARAPÜRÇEK",
        nufus: "13.181",
        erkek_nufus: "6.695",
        kadin_nufus: "6.486",
      },
      {
        plaka_kodu: "54",
        ilce_kodu: "1442",
        il_adi: "SAKARYA",
        ilce_adi: "KARASU",
        nufus: "68.844",
        erkek_nufus: "34.426",
        kadin_nufus: "34.418",
      },
      {
        plaka_kodu: "54",
        ilce_kodu: "1453",
        il_adi: "SAKARYA",
        ilce_adi: "KAYNARCA",
        nufus: "24.385",
        erkek_nufus: "12.521",
        kadin_nufus: "11.864",
      },
      {
        plaka_kodu: "54",
        ilce_kodu: "1818",
        il_adi: "SAKARYA",
        ilce_adi: "KOCAALİ",
        nufus: "22.732",
        erkek_nufus: "11.523",
        kadin_nufus: "11.209",
      },
      {
        plaka_kodu: "54",
        ilce_kodu: "1833",
        il_adi: "SAKARYA",
        ilce_adi: "PAMUKOVA",
        nufus: "30.028",
        erkek_nufus: "15.063",
        kadin_nufus: "14.965",
      },
      {
        plaka_kodu: "54",
        ilce_kodu: "1595",
        il_adi: "SAKARYA",
        ilce_adi: "SAPANCA",
        nufus: "44.001",
        erkek_nufus: "21.721",
        kadin_nufus: "22.280",
      },
      {
        plaka_kodu: "54",
        ilce_kodu: "2071",
        il_adi: "SAKARYA",
        ilce_adi: "SERDİVAN",
        nufus: "157.698",
        erkek_nufus: "77.732",
        kadin_nufus: "79.966",
      },
      {
        plaka_kodu: "54",
        ilce_kodu: "1986",
        il_adi: "SAKARYA",
        ilce_adi: "SÖĞÜTLÜ",
        nufus: "14.303",
        erkek_nufus: "7.291",
        kadin_nufus: "7.012",
      },
      {
        plaka_kodu: "54",
        ilce_kodu: "1847",
        il_adi: "SAKARYA",
        ilce_adi: "TARAKLI",
        nufus: "6.861",
        erkek_nufus: "3.496",
        kadin_nufus: "3.365",
      },
    ],
    kisa_bilgi:
      "Marmara Bölgesi'nin Çatalca-Kocaeli bölümünde yer alan Sakarya, önemli kara ve demiryollarının kavşak noktasında bulunuyor.",
  },
  {
    il_adi: "Samsun",
    plaka_kodu: "55",
    alan_kodu: "362",
    nufus: "1.368.488",
    bolge: "Karadeniz",
    yuzolcumu: "9.725",
    nufus_artisi: "-2,0%",
    erkek_nufus_yuzde: "49,57%",
    erkek_nufus: "662.086",
    kadin_nufus_yuzde: "50,43%",
    kadin_nufus: "673.630",
    nufus_yuzdesi_genel: "1,23%",
    ilceler: [
      {
        plaka_kodu: "55",
        ilce_kodu: "1125",
        il_adi: "SAMSUN",
        ilce_adi: "ALAÇAM",
        nufus: "24.860",
        erkek_nufus: "12.377",
        kadin_nufus: "12.483",
      },
      {
        plaka_kodu: "55",
        ilce_kodu: "1763",
        il_adi: "SAMSUN",
        ilce_adi: "ASARCIK",
        nufus: "16.278",
        erkek_nufus: "8.148",
        kadin_nufus: "8.130",
      },
      {
        plaka_kodu: "55",
        ilce_kodu: "2072",
        il_adi: "SAMSUN",
        ilce_adi: "ATAKUM",
        nufus: "238.702",
        erkek_nufus: "114.783",
        kadin_nufus: "123.919",
      },
      {
        plaka_kodu: "55",
        ilce_kodu: "1879",
        il_adi: "SAMSUN",
        ilce_adi: "AYVACIK",
        nufus: "19.436",
        erkek_nufus: "10.145",
        kadin_nufus: "9.291",
      },
      {
        plaka_kodu: "55",
        ilce_kodu: "1164",
        il_adi: "SAMSUN",
        ilce_adi: "BAFRA",
        nufus: "142.341",
        erkek_nufus: "70.722",
        kadin_nufus: "71.619",
      },
      {
        plaka_kodu: "55",
        ilce_kodu: "2073",
        il_adi: "SAMSUN",
        ilce_adi: "CANİK",
        nufus: "99.369",
        erkek_nufus: "49.532",
        kadin_nufus: "49.837",
      },
      {
        plaka_kodu: "55",
        ilce_kodu: "1234",
        il_adi: "SAMSUN",
        ilce_adi: "ÇARŞAMBA",
        nufus: "140.439",
        erkek_nufus: "69.794",
        kadin_nufus: "70.645",
      },
      {
        plaka_kodu: "55",
        ilce_kodu: "1386",
        il_adi: "SAMSUN",
        ilce_adi: "HAVZA",
        nufus: "38.872",
        erkek_nufus: "19.122",
        kadin_nufus: "19.750",
      },
      {
        plaka_kodu: "55",
        ilce_kodu: "2074",
        il_adi: "SAMSUN",
        ilce_adi: "İLKADIM",
        nufus: "340.421",
        erkek_nufus: "168.060",
        kadin_nufus: "172.361",
      },
      {
        plaka_kodu: "55",
        ilce_kodu: "1452",
        il_adi: "SAMSUN",
        ilce_adi: "KAVAK",
        nufus: "21.260",
        erkek_nufus: "10.689",
        kadin_nufus: "10.571",
      },
      {
        plaka_kodu: "55",
        ilce_kodu: "1501",
        il_adi: "SAMSUN",
        ilce_adi: "LADİK",
        nufus: "16.320",
        erkek_nufus: "8.055",
        kadin_nufus: "8.265",
      },
      {
        plaka_kodu: "55",
        ilce_kodu: "1830",
        il_adi: "SAMSUN",
        ilce_adi: "19 MAYIS",
        nufus: "26.366",
        erkek_nufus: "13.340",
        kadin_nufus: "13.026",
      },
      {
        plaka_kodu: "55",
        ilce_kodu: "1838",
        il_adi: "SAMSUN",
        ilce_adi: "SALIPAZARI",
        nufus: "19.305",
        erkek_nufus: "9.969",
        kadin_nufus: "9.336",
      },
      {
        plaka_kodu: "55",
        ilce_kodu: "1849",
        il_adi: "SAMSUN",
        ilce_adi: "TEKKEKÖY",
        nufus: "55.369",
        erkek_nufus: "28.068",
        kadin_nufus: "27.301",
      },
      {
        plaka_kodu: "55",
        ilce_kodu: "1676",
        il_adi: "SAMSUN",
        ilce_adi: "TERME",
        nufus: "71.366",
        erkek_nufus: "35.529",
        kadin_nufus: "35.837",
      },
      {
        plaka_kodu: "55",
        ilce_kodu: "1712",
        il_adi: "SAMSUN",
        ilce_adi: "VEZİRKÖPRÜ",
        nufus: "91.978",
        erkek_nufus: "45.538",
        kadin_nufus: "46.440",
      },
      {
        plaka_kodu: "55",
        ilce_kodu: "1993",
        il_adi: "SAMSUN",
        ilce_adi: "YAKAKENT",
        nufus: "8.592",
        erkek_nufus: "4.201",
        kadin_nufus: "4.391",
      },
    ],
    kisa_bilgi:
      "Orta Karadeniz bölümünde yer alan ve Karadeniz bölgesinin en kalabalık şehri olan Samsun, 1993 yılından bu yana büyükşehir unvanına sahiptir. İl ayrıca Mustafa Kemal Atatürk'ün 19 Mayıs 1919'da Milli Mücadele’yi başlatmak üzere ilk adımı attığı yer olarak tarihimizde de çok önemli bir yere sahiptir.",
  },
  {
    il_adi: "Siirt",
    plaka_kodu: "56",
    alan_kodu: "484",
    nufus: "331.311",
    bolge: "Güneydoğu Anadolu",
    yuzolcumu: "5.717",
    nufus_artisi: "-2,1%",
    erkek_nufus_yuzde: "51,61%",
    erkek_nufus: "171.189",
    kadin_nufus_yuzde: "48,39%",
    kadin_nufus: "160.481",
    nufus_yuzdesi_genel: "0,40%",
    ilceler: [
      {
        plaka_kodu: "56",
        ilce_kodu: "1620",
        il_adi: "SİİRT",
        ilce_adi: "MERKEZ",
        nufus: "172.824",
        erkek_nufus: "87.388",
        kadin_nufus: "85.436",
      },
      {
        plaka_kodu: "56",
        ilce_kodu: "1179",
        il_adi: "SİİRT",
        ilce_adi: "BAYKAN",
        nufus: "24.594",
        erkek_nufus: "12.539",
        kadin_nufus: "12.055",
      },
      {
        plaka_kodu: "56",
        ilce_kodu: "1317",
        il_adi: "SİİRT",
        ilce_adi: "ERUH",
        nufus: "18.101",
        erkek_nufus: "9.335",
        kadin_nufus: "8.766",
      },
      {
        plaka_kodu: "56",
        ilce_kodu: "1495",
        il_adi: "SİİRT",
        ilce_adi: "KURTALAN",
        nufus: "60.592",
        erkek_nufus: "30.908",
        kadin_nufus: "29.684",
      },
      {
        plaka_kodu: "56",
        ilce_kodu: "1575",
        il_adi: "SİİRT",
        ilce_adi: "PERVARİ",
        nufus: "30.276",
        erkek_nufus: "15.416",
        kadin_nufus: "14.860",
      },
      {
        plaka_kodu: "56",
        ilce_kodu: "1662",
        il_adi: "SİİRT",
        ilce_adi: "ŞİRVAN",
        nufus: "21.321",
        erkek_nufus: "10.914",
        kadin_nufus: "10.407",
      },
      {
        plaka_kodu: "56",
        ilce_kodu: "1878",
        il_adi: "SİİRT",
        ilce_adi: "TİLLO",
        nufus: "4.272",
        erkek_nufus: "2.381",
        kadin_nufus: "1.891",
      },
    ],
    kisa_bilgi:
      "Güneydoğu Anadolu bölgesinde yer alan Siirt, Asurlulardan kalan 3500 yıllık Akabe Yolu’nun bulunduğu şehrimizdir. Dünyada var olan üç Akabe yolundan biridir burası.",
  },
  {
    il_adi: "Sinop",
    plaka_kodu: "57",
    alan_kodu: "368",
    nufus: "220.799",
    bolge: "Karadeniz",
    yuzolcumu: "5.717",
    nufus_artisi: "10,9%",
    erkek_nufus_yuzde: "49,74%",
    erkek_nufus: "109.296",
    kadin_nufus_yuzde: "50,26%",
    kadin_nufus: "110.437",
    nufus_yuzdesi_genel: "0,27%",
    ilceler: [
      {
        plaka_kodu: "57",
        ilce_kodu: "1627",
        il_adi: "SİNOP",
        ilce_adi: "MERKEZ",
        nufus: "68.012",
        erkek_nufus: "33.548",
        kadin_nufus: "34.464",
      },
      {
        plaka_kodu: "57",
        ilce_kodu: "1156",
        il_adi: "SİNOP",
        ilce_adi: "AYANCIK",
        nufus: "23.739",
        erkek_nufus: "11.862",
        kadin_nufus: "11.877",
      },
      {
        plaka_kodu: "57",
        ilce_kodu: "1204",
        il_adi: "SİNOP",
        ilce_adi: "BOYABAT",
        nufus: "44.004",
        erkek_nufus: "21.788",
        kadin_nufus: "22.216",
      },
      {
        plaka_kodu: "57",
        ilce_kodu: "1910",
        il_adi: "SİNOP",
        ilce_adi: "DİKMEN",
        nufus: "4.753",
        erkek_nufus: "2.446",
        kadin_nufus: "2.307",
      },
      {
        plaka_kodu: "57",
        ilce_kodu: "1290",
        il_adi: "SİNOP",
        ilce_adi: "DURAĞAN",
        nufus: "16.940",
        erkek_nufus: "8.475",
        kadin_nufus: "8.465",
      },
      {
        plaka_kodu: "57",
        ilce_kodu: "1314",
        il_adi: "SİNOP",
        ilce_adi: "ERFELEK",
        nufus: "12.003",
        erkek_nufus: "6.010",
        kadin_nufus: "5.993",
      },
      {
        plaka_kodu: "57",
        ilce_kodu: "1349",
        il_adi: "SİNOP",
        ilce_adi: "GERZE",
        nufus: "27.027",
        erkek_nufus: "13.455",
        kadin_nufus: "13.572",
      },
      {
        plaka_kodu: "57",
        ilce_kodu: "1981",
        il_adi: "SİNOP",
        ilce_adi: "SARAYDÜZÜ",
        nufus: "5.926",
        erkek_nufus: "3.015",
        kadin_nufus: "2.911",
      },
      {
        plaka_kodu: "57",
        ilce_kodu: "1693",
        il_adi: "SİNOP",
        ilce_adi: "TÜRKELİ",
        nufus: "16.004",
        erkek_nufus: "7.873",
        kadin_nufus: "8.131",
      },
    ],
    kisa_bilgi:
      "Ülkemizin en kuzey ucunda bulunan Sinop, 6 bin yıllık geçmişi, farklı medeniyetlerden kalan tarihi eserleri, enfes doğasıyla turistlerin ilgi noktasıdır",
  },
  {
    il_adi: "Sivas",
    plaka_kodu: "58",
    alan_kodu: "346",
    nufus: "634.924",
    bolge: "İç Anadolu",
    yuzolcumu: "28.164",
    nufus_artisi: "-1,9%",
    erkek_nufus_yuzde: "50,04%",
    erkek_nufus: "323.575",
    kadin_nufus_yuzde: "49,96%",
    kadin_nufus: "323.033",
    nufus_yuzdesi_genel: "0,79%",
    ilceler: [
      {
        plaka_kodu: "58",
        ilce_kodu: "1628",
        il_adi: "SİVAS",
        ilce_adi: "MERKEZ",
        nufus: "388.079",
        erkek_nufus: "193.680",
        kadin_nufus: "194.399",
      },
      {
        plaka_kodu: "58",
        ilce_kodu: "1870",
        il_adi: "SİVAS",
        ilce_adi: "AKINCILAR",
        nufus: "4.957",
        erkek_nufus: "2.537",
        kadin_nufus: "2.420",
      },
      {
        plaka_kodu: "58",
        ilce_kodu: "1875",
        il_adi: "SİVAS",
        ilce_adi: "ALTINYAYLA",
        nufus: "8.739",
        erkek_nufus: "4.436",
        kadin_nufus: "4.303",
      },
      {
        plaka_kodu: "58",
        ilce_kodu: "1282",
        il_adi: "SİVAS",
        ilce_adi: "DİVRİĞİ",
        nufus: "16.177",
        erkek_nufus: "8.366",
        kadin_nufus: "7.811",
      },
      {
        plaka_kodu: "58",
        ilce_kodu: "1913",
        il_adi: "SİVAS",
        ilce_adi: "DOĞANŞAR",
        nufus: "2.686",
        erkek_nufus: "1.338",
        kadin_nufus: "1.348",
      },
      {
        plaka_kodu: "58",
        ilce_kodu: "1342",
        il_adi: "SİVAS",
        ilce_adi: "GEMEREK",
        nufus: "21.810",
        erkek_nufus: "10.898",
        kadin_nufus: "10.912",
      },
      {
        plaka_kodu: "58",
        ilce_kodu: "1927",
        il_adi: "SİVAS",
        ilce_adi: "GÖLOVA",
        nufus: "3.168",
        erkek_nufus: "1.625",
        kadin_nufus: "1.543",
      },
      {
        plaka_kodu: "58",
        ilce_kodu: "1373",
        il_adi: "SİVAS",
        ilce_adi: "GÜRÜN",
        nufus: "18.286",
        erkek_nufus: "9.220",
        kadin_nufus: "9.066",
      },
      {
        plaka_kodu: "58",
        ilce_kodu: "1376",
        il_adi: "SİVAS",
        ilce_adi: "HAFİK",
        nufus: "9.155",
        erkek_nufus: "4.568",
        kadin_nufus: "4.587",
      },
      {
        plaka_kodu: "58",
        ilce_kodu: "1407",
        il_adi: "SİVAS",
        ilce_adi: "İMRANLI",
        nufus: "7.352",
        erkek_nufus: "3.898",
        kadin_nufus: "3.454",
      },
      {
        plaka_kodu: "58",
        ilce_kodu: "1431",
        il_adi: "SİVAS",
        ilce_adi: "KANGAL",
        nufus: "20.137",
        erkek_nufus: "10.449",
        kadin_nufus: "9.688",
      },
      {
        plaka_kodu: "58",
        ilce_kodu: "1484",
        il_adi: "SİVAS",
        ilce_adi: "KOYULHİSAR",
        nufus: "12.497",
        erkek_nufus: "6.218",
        kadin_nufus: "6.279",
      },
      {
        plaka_kodu: "58",
        ilce_kodu: "1646",
        il_adi: "SİVAS",
        ilce_adi: "SUŞEHRİ",
        nufus: "25.404",
        erkek_nufus: "12.496",
        kadin_nufus: "12.908",
      },
      {
        plaka_kodu: "58",
        ilce_kodu: "1650",
        il_adi: "SİVAS",
        ilce_adi: "ŞARKIŞLA",
        nufus: "37.708",
        erkek_nufus: "18.709",
        kadin_nufus: "18.999",
      },
      {
        plaka_kodu: "58",
        ilce_kodu: "1991",
        il_adi: "SİVAS",
        ilce_adi: "ULAŞ",
        nufus: "8.005",
        erkek_nufus: "4.188",
        kadin_nufus: "3.817",
      },
      {
        plaka_kodu: "58",
        ilce_kodu: "1731",
        il_adi: "SİVAS",
        ilce_adi: "YILDIZELİ",
        nufus: "30.313",
        erkek_nufus: "15.383",
        kadin_nufus: "14.930",
      },
      {
        plaka_kodu: "58",
        ilce_kodu: "1738",
        il_adi: "SİVAS",
        ilce_adi: "ZARA",
        nufus: "21.648",
        erkek_nufus: "10.945",
        kadin_nufus: "10.703",
      },
    ],
    kisa_bilgi:
      "Anadolu’nun en eski yerleşim yerlerinden biri olan, tarihi İpek Yolu güzergahlarının kesiştiği yerde bulunan il, 4 Eylül 1919'da, Sivas Erkek Lisesi'nde toplanan kongre nedeniyle Milli Mücadele döneminde önemli bir yere sahip olmuştur. ",
  },
  {
    il_adi: "Tekirdağ",
    plaka_kodu: "59",
    alan_kodu: "282",
    nufus: "1.142.451",
    bolge: "Marmara",
    yuzolcumu: "6.190",
    nufus_artisi: "25,8%",
    erkek_nufus_yuzde: "51,26%",
    erkek_nufus: "527.975",
    kadin_nufus_yuzde: "48,74%",
    kadin_nufus: "501.952",
    nufus_yuzdesi_genel: "1,26%",
    ilceler: [
      {
        plaka_kodu: "59",
        ilce_kodu: "1250",
        il_adi: "TEKİRDAĞ",
        ilce_adi: "ÇERKEZKÖY",
        nufus: "196.736",
        erkek_nufus: "101.592",
        kadin_nufus: "95.144",
      },
      {
        plaka_kodu: "59",
        ilce_kodu: "1258",
        il_adi: "TEKİRDAĞ",
        ilce_adi: "ÇORLU",
        nufus: "284.907",
        erkek_nufus: "145.330",
        kadin_nufus: "139.577",
      },
      {
        plaka_kodu: "59",
        ilce_kodu: "2094",
        il_adi: "TEKİRDAĞ",
        ilce_adi: "ERGENE",
        nufus: "66.028",
        erkek_nufus: "34.313",
        kadin_nufus: "31.715",
      },
      {
        plaka_kodu: "59",
        ilce_kodu: "1388",
        il_adi: "TEKİRDAĞ",
        ilce_adi: "HAYRABOLU",
        nufus: "31.245",
        erkek_nufus: "16.129",
        kadin_nufus: "15.116",
      },
      {
        plaka_kodu: "59",
        ilce_kodu: "2095",
        il_adi: "TEKİRDAĞ",
        ilce_adi: "KAPAKLI",
        nufus: "130.813",
        erkek_nufus: "67.830",
        kadin_nufus: "62.983",
      },
      {
        plaka_kodu: "59",
        ilce_kodu: "1511",
        il_adi: "TEKİRDAĞ",
        ilce_adi: "MALKARA",
        nufus: "51.616",
        erkek_nufus: "26.335",
        kadin_nufus: "25.281",
      },
      {
        plaka_kodu: "59",
        ilce_kodu: "1825",
        il_adi: "TEKİRDAĞ",
        ilce_adi: "MARMARAEREĞLİSİ",
        nufus: "28.336",
        erkek_nufus: "14.729",
        kadin_nufus: "13.607",
      },
      {
        plaka_kodu: "59",
        ilce_kodu: "1538",
        il_adi: "TEKİRDAĞ",
        ilce_adi: "MURATLI",
        nufus: "29.716",
        erkek_nufus: "15.160",
        kadin_nufus: "14.556",
      },
      {
        plaka_kodu: "59",
        ilce_kodu: "1596",
        il_adi: "TEKİRDAĞ",
        ilce_adi: "SARAY",
        nufus: "50.394",
        erkek_nufus: "25.718",
        kadin_nufus: "24.676",
      },
      {
        plaka_kodu: "59",
        ilce_kodu: "2096",
        il_adi: "TEKİRDAĞ",
        ilce_adi: "SÜLEYMANPAŞA",
        nufus: "210.547",
        erkek_nufus: "106.592",
        kadin_nufus: "103.955",
      },
      {
        plaka_kodu: "59",
        ilce_kodu: "1652",
        il_adi: "TEKİRDAĞ",
        ilce_adi: "ŞARKÖY",
        nufus: "33.062",
        erkek_nufus: "16.627",
        kadin_nufus: "16.435",
      },
    ],
    kisa_bilgi:
      "2014 yılından bu yana büyükşehir belediyeciliği ile yönetilen Tekirdağ’ın tarihi, Kalkolitik Çağ'a kadar uzanıyor. Giderek gelişen ve ülkemizin en yaşanılası şehirlerinden biri olarak gösterilebilecek ilde 2008 yılında hizmete açılan Tekira Alışveriş Merkezi de bulunmakta. ",
  },
  {
    il_adi: "Tokat",
    plaka_kodu: "60",
    alan_kodu: "356",
    nufus: "596.454",
    bolge: "İç Anadolu",
    yuzolcumu: "10.042",
    nufus_artisi: "-10,2%",
    erkek_nufus_yuzde: "49,94%",
    erkek_nufus: "305.977",
    kadin_nufus_yuzde: "50,06%",
    kadin_nufus: "306.669",
    nufus_yuzdesi_genel: "0,75%",
    ilceler: [
      {
        plaka_kodu: "60",
        ilce_kodu: "1679",
        il_adi: "TOKAT",
        ilce_adi: "MERKEZ",
        nufus: "204.907",
        erkek_nufus: "102.227",
        kadin_nufus: "102.680",
      },
      {
        plaka_kodu: "60",
        ilce_kodu: "1129",
        il_adi: "TOKAT",
        ilce_adi: "ALMUS",
        nufus: "23.868",
        erkek_nufus: "12.022",
        kadin_nufus: "11.846",
      },
      {
        plaka_kodu: "60",
        ilce_kodu: "1151",
        il_adi: "TOKAT",
        ilce_adi: "ARTOVA",
        nufus: "7.681",
        erkek_nufus: "3.795",
        kadin_nufus: "3.886",
      },
      {
        plaka_kodu: "60",
        ilce_kodu: "1883",
        il_adi: "TOKAT",
        ilce_adi: "BAŞÇİFTLİK",
        nufus: "7.479",
        erkek_nufus: "3.814",
        kadin_nufus: "3.665",
      },
      {
        plaka_kodu: "60",
        ilce_kodu: "1308",
        il_adi: "TOKAT",
        ilce_adi: "ERBAA",
        nufus: "99.815",
        erkek_nufus: "49.531",
        kadin_nufus: "50.284",
      },
      {
        plaka_kodu: "60",
        ilce_kodu: "1545",
        il_adi: "TOKAT",
        ilce_adi: "NİKSAR",
        nufus: "63.486",
        erkek_nufus: "31.565",
        kadin_nufus: "31.921",
      },
      {
        plaka_kodu: "60",
        ilce_kodu: "1834",
        il_adi: "TOKAT",
        ilce_adi: "PAZAR",
        nufus: "12.937",
        erkek_nufus: "6.430",
        kadin_nufus: "6.507",
      },
      {
        plaka_kodu: "60",
        ilce_kodu: "1584",
        il_adi: "TOKAT",
        ilce_adi: "REŞADİYE",
        nufus: "35.304",
        erkek_nufus: "17.571",
        kadin_nufus: "17.733",
      },
      {
        plaka_kodu: "60",
        ilce_kodu: "1987",
        il_adi: "TOKAT",
        ilce_adi: "SULUSARAY",
        nufus: "6.769",
        erkek_nufus: "3.378",
        kadin_nufus: "3.391",
      },
      {
        plaka_kodu: "60",
        ilce_kodu: "1690",
        il_adi: "TOKAT",
        ilce_adi: "TURHAL",
        nufus: "78.294",
        erkek_nufus: "38.209",
        kadin_nufus: "40.085",
      },
      {
        plaka_kodu: "60",
        ilce_kodu: "1858",
        il_adi: "TOKAT",
        ilce_adi: "YEŞİLYURT",
        nufus: "8.478",
        erkek_nufus: "4.221",
        kadin_nufus: "4.257",
      },
      {
        plaka_kodu: "60",
        ilce_kodu: "1740",
        il_adi: "TOKAT",
        ilce_adi: "ZİLE",
        nufus: "53.549",
        erkek_nufus: "26.594",
        kadin_nufus: "26.955",
      },
    ],
    kisa_bilgi:
      "Yeşilırmak  havzası  üzerinde  kurulmuş  olan, yaylaları, gölleri, mesire yerleri, tarihi atmosferi, doğa sporlarına imkân tanıyan dağları, tepeleriyle Tokat, pek çok ilklerin şehridir. Örneğin Roma İmparatoru Jul Sezar'ın Veni-Vidi-Viciyi söylediği yer olarak gösterilen Zile Kalesi ile Kazıklı Voyvoda III. Vlad'ın tutsak olarak kaldığı kale olan Tokat Kalesi buradadır. İl ayrıca 90 yıllık bir müzeye ev sahipliği yapar, onlarca el sanatını barındırır ve Anadolu’nun ilk başkentine ev sahipliği yapmıştır.",
  },
  {
    il_adi: "Trabzon",
    plaka_kodu: "61",
    alan_kodu: "462",
    nufus: "818.023",
    bolge: "Karadeniz",
    yuzolcumu: "4.628",
    nufus_artisi: "1,6%",
    erkek_nufus_yuzde: "49,43%",
    erkek_nufus: "399.377",
    kadin_nufus_yuzde: "50,57%",
    kadin_nufus: "408.526",
    nufus_yuzdesi_genel: "0,99%",
    ilceler: [
      {
        plaka_kodu: "61",
        ilce_kodu: "1113",
        il_adi: "TRABZON",
        ilce_adi: "AKÇAABAT",
        nufus: "128.348",
        erkek_nufus: "63.604",
        kadin_nufus: "64.744",
      },
      {
        plaka_kodu: "61",
        ilce_kodu: "1141",
        il_adi: "TRABZON",
        ilce_adi: "ARAKLI",
        nufus: "48.382",
        erkek_nufus: "24.338",
        kadin_nufus: "24.044",
      },
      {
        plaka_kodu: "61",
        ilce_kodu: "1150",
        il_adi: "TRABZON",
        ilce_adi: "ARSİN",
        nufus: "31.548",
        erkek_nufus: "15.653",
        kadin_nufus: "15.895",
      },
      {
        plaka_kodu: "61",
        ilce_kodu: "1775",
        il_adi: "TRABZON",
        ilce_adi: "BEŞİKDÜZÜ",
        nufus: "23.594",
        erkek_nufus: "11.862",
        kadin_nufus: "11.732",
      },
      {
        plaka_kodu: "61",
        ilce_kodu: "1896",
        il_adi: "TRABZON",
        ilce_adi: "ÇARŞIBAŞI",
        nufus: "15.395",
        erkek_nufus: "7.891",
        kadin_nufus: "7.504",
      },
      {
        plaka_kodu: "61",
        ilce_kodu: "1244",
        il_adi: "TRABZON",
        ilce_adi: "ÇAYKARA",
        nufus: "13.550",
        erkek_nufus: "6.780",
        kadin_nufus: "6.770",
      },
      {
        plaka_kodu: "61",
        ilce_kodu: "1909",
        il_adi: "TRABZON",
        ilce_adi: "DERNEKPAZARI",
        nufus: "3.884",
        erkek_nufus: "1.897",
        kadin_nufus: "1.987",
      },
      {
        plaka_kodu: "61",
        ilce_kodu: "1917",
        il_adi: "TRABZON",
        ilce_adi: "DÜZKÖY",
        nufus: "13.575",
        erkek_nufus: "6.711",
        kadin_nufus: "6.864",
      },
      {
        plaka_kodu: "61",
        ilce_kodu: "1942",
        il_adi: "TRABZON",
        ilce_adi: "HAYRAT",
        nufus: "7.581",
        erkek_nufus: "3.876",
        kadin_nufus: "3.705",
      },
      {
        plaka_kodu: "61",
        ilce_kodu: "1966",
        il_adi: "TRABZON",
        ilce_adi: "KÖPRÜBAŞI",
        nufus: "4.371",
        erkek_nufus: "2.259",
        kadin_nufus: "2.112",
      },
      {
        plaka_kodu: "61",
        ilce_kodu: "1507",
        il_adi: "TRABZON",
        ilce_adi: "MAÇKA",
        nufus: "24.869",
        erkek_nufus: "12.667",
        kadin_nufus: "12.202",
      },
      {
        plaka_kodu: "61",
        ilce_kodu: "1548",
        il_adi: "TRABZON",
        ilce_adi: "OF",
        nufus: "44.212",
        erkek_nufus: "22.391",
        kadin_nufus: "21.821",
      },
      {
        plaka_kodu: "61",
        ilce_kodu: "2097",
        il_adi: "TRABZON",
        ilce_adi: "ORTAHİSAR",
        nufus: "334.228",
        erkek_nufus: "164.073",
        kadin_nufus: "170.155",
      },
      {
        plaka_kodu: "61",
        ilce_kodu: "1647",
        il_adi: "TRABZON",
        ilce_adi: "SÜRMENE",
        nufus: "26.161",
        erkek_nufus: "13.060",
        kadin_nufus: "13.101",
      },
      {
        plaka_kodu: "61",
        ilce_kodu: "1842",
        il_adi: "TRABZON",
        ilce_adi: "ŞALPAZARI",
        nufus: "10.519",
        erkek_nufus: "5.166",
        kadin_nufus: "5.353",
      },
      {
        plaka_kodu: "61",
        ilce_kodu: "1681",
        il_adi: "TRABZON",
        ilce_adi: "TONYA",
        nufus: "13.754",
        erkek_nufus: "6.683",
        kadin_nufus: "7.071",
      },
      {
        plaka_kodu: "61",
        ilce_kodu: "1709",
        il_adi: "TRABZON",
        ilce_adi: "VAKFIKEBİR",
        nufus: "27.081",
        erkek_nufus: "13.340",
        kadin_nufus: "13.741",
      },
      {
        plaka_kodu: "61",
        ilce_kodu: "1732",
        il_adi: "TRABZON",
        ilce_adi: "YOMRA",
        nufus: "45.632",
        erkek_nufus: "22.796",
        kadin_nufus: "22.836",
      },
    ],
    kisa_bilgi:
      "Rize, Giresun, Gümüşhane ve Bayburt illeriyle çevrili Trabzon’un tarihi en azından 4000 yıl öncesine dek uzanıyor. Şenlikleriyle dikkati çeken, her sene pek çok turisti ağırlayan ve doğa sporlarına imkân tanıyan yaylalar, şehrin sosyo-kültürel yapısında büyük bir öneme sahip. ",
  },
  {
    il_adi: "Tunceli",
    plaka_kodu: "62",
    alan_kodu: "428",
    nufus: "84.366",
    bolge: "Doğu Anadolu",
    yuzolcumu: "7.582",
    nufus_artisi: "8,6%",
    erkek_nufus_yuzde: "55,69%",
    erkek_nufus: "49.118",
    kadin_nufus_yuzde: "44,31%",
    kadin_nufus: "39.080",
    nufus_yuzdesi_genel: "0,11%",
    ilceler: [
      {
        plaka_kodu: "62",
        ilce_kodu: "1688",
        il_adi: "TUNCELİ",
        ilce_adi: "MERKEZ",
        nufus: "39.610",
        erkek_nufus: "20.641",
        kadin_nufus: "18.969",
      },
      {
        plaka_kodu: "62",
        ilce_kodu: "1247",
        il_adi: "TUNCELİ",
        ilce_adi: "ÇEMİŞGEZEK",
        nufus: "7.418",
        erkek_nufus: "3.814",
        kadin_nufus: "3.604",
      },
      {
        plaka_kodu: "62",
        ilce_kodu: "1397",
        il_adi: "TUNCELİ",
        ilce_adi: "HOZAT",
        nufus: "5.590",
        erkek_nufus: "3.158",
        kadin_nufus: "2.432",
      },
      {
        plaka_kodu: "62",
        ilce_kodu: "1518",
        il_adi: "TUNCELİ",
        ilce_adi: "MAZGİRT",
        nufus: "7.357",
        erkek_nufus: "3.979",
        kadin_nufus: "3.378",
      },
      {
        plaka_kodu: "62",
        ilce_kodu: "1541",
        il_adi: "TUNCELİ",
        ilce_adi: "NAZIMİYE",
        nufus: "3.011",
        erkek_nufus: "1.698",
        kadin_nufus: "1.313",
      },
      {
        plaka_kodu: "62",
        ilce_kodu: "1562",
        il_adi: "TUNCELİ",
        ilce_adi: "OVACIK",
        nufus: "6.366",
        erkek_nufus: "3.539",
        kadin_nufus: "2.827",
      },
      {
        plaka_kodu: "62",
        ilce_kodu: "1574",
        il_adi: "TUNCELİ",
        ilce_adi: "PERTEK",
        nufus: "11.011",
        erkek_nufus: "5.724",
        kadin_nufus: "5.287",
      },
      {
        plaka_kodu: "62",
        ilce_kodu: "1581",
        il_adi: "TUNCELİ",
        ilce_adi: "PÜLÜMÜR",
        nufus: "3.282",
        erkek_nufus: "1.870",
        kadin_nufus: "1.412",
      },
    ],
    kisa_bilgi:
      "Yerleşimin M.Ö. 5500-3500 yıllarına dek uzandığı, Doğu Anadolu’nun Yukarı Fırat bölümünde bulunan Tunceli, ülkemizin en az nüfusu olan illerinden biri.  Munzur’la birleşerek Keban Barajı'na dökülen Mercan Irmağı, Mercan Dağları, Munzur Suyu şehrin karakteristik güzelliklerinden birkaç tanesi",
  },
  {
    il_adi: "Şanlıurfa",
    plaka_kodu: "63",
    alan_kodu: "414",
    nufus: "2.170.110",
    bolge: "Güneydoğu Anadolu",
    yuzolcumu: "19.242",
    nufus_artisi: "12,6%",
    erkek_nufus_yuzde: "50,43%",
    erkek_nufus: "1.026.719",
    kadin_nufus_yuzde: "49,57%",
    kadin_nufus: "1.009.090",
    nufus_yuzdesi_genel: "2,48%",
    ilceler: [
      {
        plaka_kodu: "63",
        ilce_kodu: "1115",
        il_adi: "ŞANLIURFA",
        ilce_adi: "AKÇAKALE",
        nufus: "120.834",
        erkek_nufus: "61.468",
        kadin_nufus: "59.366",
      },
      {
        plaka_kodu: "63",
        ilce_kodu: "1194",
        il_adi: "ŞANLIURFA",
        ilce_adi: "BİRECİK",
        nufus: "94.608",
        erkek_nufus: "47.419",
        kadin_nufus: "47.189",
      },
      {
        plaka_kodu: "63",
        ilce_kodu: "1209",
        il_adi: "ŞANLIURFA",
        ilce_adi: "BOZOVA",
        nufus: "53.878",
        erkek_nufus: "27.120",
        kadin_nufus: "26.758",
      },
      {
        plaka_kodu: "63",
        ilce_kodu: "1220",
        il_adi: "ŞANLIURFA",
        ilce_adi: "CEYLANPINAR",
        nufus: "89.871",
        erkek_nufus: "45.212",
        kadin_nufus: "44.659",
      },
      {
        plaka_kodu: "63",
        ilce_kodu: "2091",
        il_adi: "ŞANLIURFA",
        ilce_adi: "EYYÜBİYE",
        nufus: "386.852",
        erkek_nufus: "195.218",
        kadin_nufus: "191.634",
      },
      {
        plaka_kodu: "63",
        ilce_kodu: "1378",
        il_adi: "ŞANLIURFA",
        ilce_adi: "HALFETİ",
        nufus: "41.663",
        erkek_nufus: "20.377",
        kadin_nufus: "21.286",
      },
      {
        plaka_kodu: "63",
        ilce_kodu: "2092",
        il_adi: "ŞANLIURFA",
        ilce_adi: "HALİLİYE",
        nufus: "392.661",
        erkek_nufus: "197.131",
        kadin_nufus: "195.530",
      },
      {
        plaka_kodu: "63",
        ilce_kodu: "1800",
        il_adi: "ŞANLIURFA",
        ilce_adi: "HARRAN",
        nufus: "94.207",
        erkek_nufus: "47.142",
        kadin_nufus: "47.065",
      },
      {
        plaka_kodu: "63",
        ilce_kodu: "1393",
        il_adi: "ŞANLIURFA",
        ilce_adi: "HİLVAN",
        nufus: "42.766",
        erkek_nufus: "21.696",
        kadin_nufus: "21.070",
      },
      {
        plaka_kodu: "63",
        ilce_kodu: "2093",
        il_adi: "ŞANLIURFA",
        ilce_adi: "KARAKÖPRÜ",
        nufus: "252.151",
        erkek_nufus: "128.108",
        kadin_nufus: "124.043",
      },
      {
        plaka_kodu: "63",
        ilce_kodu: "1630",
        il_adi: "ŞANLIURFA",
        ilce_adi: "SİVEREK",
        nufus: "266.971",
        erkek_nufus: "135.154",
        kadin_nufus: "131.817",
      },
      {
        plaka_kodu: "63",
        ilce_kodu: "1643",
        il_adi: "ŞANLIURFA",
        ilce_adi: "SURUÇ",
        nufus: "101.178",
        erkek_nufus: "51.281",
        kadin_nufus: "49.897",
      },
      {
        plaka_kodu: "63",
        ilce_kodu: "1713",
        il_adi: "ŞANLIURFA",
        ilce_adi: "VİRANŞEHİR",
        nufus: "205.380",
        erkek_nufus: "101.689",
        kadin_nufus: "103.691",
      },
    ],
    kisa_bilgi:
      "Mardin, Gaziantep, Adıyaman, Diyarbakır illeri ve güneyde Suriye ile sınır komşusu olan, çok eski çağlara dek uzanan zengin tarihiyle Şanlıurfa (Kürtçe: Riha, Arapça: الرها ve Süryanice: Urhoy), ülkemizin en kalabalık şehirlerinden biridir. Kozmopolit yapısıyla çok zengin bir kültürü olan Urfa, -sayıları giderek azalsa da-, Türkiye Ezidilerinin çoğunluğuna evsahipliği yapar. Urfa, sahip olduğu doğal zenginlikleriyle de dikkati çeker.",
  },
  {
    il_adi: "Uşak",
    plaka_kodu: "64",
    alan_kodu: "276",
    nufus: "375.454",
    bolge: "Ege",
    yuzolcumu: "5.555",
    nufus_artisi: "6,1%",
    erkek_nufus_yuzde: "49,78%",
    erkek_nufus: "182.931",
    kadin_nufus_yuzde: "50,22%",
    kadin_nufus: "184.583",
    nufus_yuzdesi_genel: "0,45%",
    ilceler: [
      {
        plaka_kodu: "64",
        ilce_kodu: "1704",
        il_adi: "UŞAK",
        ilce_adi: "MERKEZ",
        nufus: "260.843",
        erkek_nufus: "130.028",
        kadin_nufus: "130.815",
      },
      {
        plaka_kodu: "64",
        ilce_kodu: "1170",
        il_adi: "UŞAK",
        ilce_adi: "BANAZ",
        nufus: "35.594",
        erkek_nufus: "17.667",
        kadin_nufus: "17.927",
      },
      {
        plaka_kodu: "64",
        ilce_kodu: "1323",
        il_adi: "UŞAK",
        ilce_adi: "EŞME",
        nufus: "34.703",
        erkek_nufus: "17.175",
        kadin_nufus: "17.528",
      },
      {
        plaka_kodu: "64",
        ilce_kodu: "1436",
        il_adi: "UŞAK",
        ilce_adi: "KARAHALLI",
        nufus: "9.782",
        erkek_nufus: "4.900",
        kadin_nufus: "4.882",
      },
      {
        plaka_kodu: "64",
        ilce_kodu: "1629",
        il_adi: "UŞAK",
        ilce_adi: "SİVASLI",
        nufus: "20.006",
        erkek_nufus: "10.077",
        kadin_nufus: "9.929",
      },
      {
        plaka_kodu: "64",
        ilce_kodu: "1697",
        il_adi: "UŞAK",
        ilce_adi: "ULUBEY",
        nufus: "12.255",
        erkek_nufus: "6.044",
        kadin_nufus: "6.211",
      },
    ],
    kisa_bilgi:
      "İç Anadolu ile Ege Bölgesi arasında geçiş bölümü oluşturan, M.Ö. 4000 yılına uzanan geçmişiyle pek çok kültüre ev sahipliği yapmış olan il, aynı zamanda bir ilkler şehri. 1909 yılında elektrik kullanmaya başlayarak, ülkenin bu anlamda ilk şehri statüsüne sahip olmuştur. Türkiye’nin ilk demiryolu hattı buradan geçtiği gibi, 1926 yılında ilk şeker fabrikasının da açıldığı ildir aynı zamanda. Dolayısıyla ülke sanayileşmesinde değerli bir yeri vardır Uşak’ın. ",
  },
  {
    il_adi: "Van",
    plaka_kodu: "65",
    alan_kodu: "432",
    nufus: "1.128.749",
    bolge: "Doğu Anadolu ",
    yuzolcumu: "20.921",
    nufus_artisi: "-10,8%",
    erkek_nufus_yuzde: "51,12%",
    erkek_nufus: "574.461",
    kadin_nufus_yuzde: "48,88%",
    kadin_nufus: "549.323",
    nufus_yuzdesi_genel: "1,37%",
    ilceler: [
      {
        plaka_kodu: "65",
        ilce_kodu: "1770",
        il_adi: "VAN",
        ilce_adi: "BAHÇESARAY",
        nufus: "13.936",
        erkek_nufus: "6.945",
        kadin_nufus: "6.991",
      },
      {
        plaka_kodu: "65",
        ilce_kodu: "1175",
        il_adi: "VAN",
        ilce_adi: "BAŞKALE",
        nufus: "47.947",
        erkek_nufus: "25.660",
        kadin_nufus: "22.287",
      },
      {
        plaka_kodu: "65",
        ilce_kodu: "1786",
        il_adi: "VAN",
        ilce_adi: "ÇALDIRAN",
        nufus: "61.625",
        erkek_nufus: "32.045",
        kadin_nufus: "29.580",
      },
      {
        plaka_kodu: "65",
        ilce_kodu: "1236",
        il_adi: "VAN",
        ilce_adi: "ÇATAK",
        nufus: "19.376",
        erkek_nufus: "10.198",
        kadin_nufus: "9.178",
      },
      {
        plaka_kodu: "65",
        ilce_kodu: "1918",
        il_adi: "VAN",
        ilce_adi: "EDREMİT",
        nufus: "128.555",
        erkek_nufus: "64.140",
        kadin_nufus: "64.415",
      },
      {
        plaka_kodu: "65",
        ilce_kodu: "1309",
        il_adi: "VAN",
        ilce_adi: "ERCİŞ",
        nufus: "173.606",
        erkek_nufus: "89.542",
        kadin_nufus: "84.064",
      },
      {
        plaka_kodu: "65",
        ilce_kodu: "1350",
        il_adi: "VAN",
        ilce_adi: "GEVAŞ",
        nufus: "27.442",
        erkek_nufus: "13.774",
        kadin_nufus: "13.668",
      },
      {
        plaka_kodu: "65",
        ilce_kodu: "1372",
        il_adi: "VAN",
        ilce_adi: "GÜRPINAR",
        nufus: "33.791",
        erkek_nufus: "17.616",
        kadin_nufus: "16.175",
      },
      {
        plaka_kodu: "65",
        ilce_kodu: "2098",
        il_adi: "VAN",
        ilce_adi: "İPEKYOLU",
        nufus: "339.952",
        erkek_nufus: "169.479",
        kadin_nufus: "170.473",
      },
      {
        plaka_kodu: "65",
        ilce_kodu: "1533",
        il_adi: "VAN",
        ilce_adi: "MURADİYE",
        nufus: "47.971",
        erkek_nufus: "24.581",
        kadin_nufus: "23.390",
      },
      {
        plaka_kodu: "65",
        ilce_kodu: "1565",
        il_adi: "VAN",
        ilce_adi: "ÖZALP",
        nufus: "63.270",
        erkek_nufus: "32.771",
        kadin_nufus: "30.499",
      },
      {
        plaka_kodu: "65",
        ilce_kodu: "1980",
        il_adi: "VAN",
        ilce_adi: "SARAY",
        nufus: "20.224",
        erkek_nufus: "10.679",
        kadin_nufus: "9.545",
      },
      {
        plaka_kodu: "65",
        ilce_kodu: "2099",
        il_adi: "VAN",
        ilce_adi: "TUŞBA",
        nufus: "163.320",
        erkek_nufus: "84.515",
        kadin_nufus: "78.805",
      },
    ],
    kisa_bilgi:
      "Doğu Anadolu bölgesinin en büyük ili olan Van, Urartular’dan kalma ve hâlâ yaşanılan dünya üzerindeki en eski bir yerleşim yerlerinden biridir. ",
  },
  {
    il_adi: "Yozgat",
    plaka_kodu: "66",
    alan_kodu: "354",
    nufus: "418.442",
    bolge: "İç Anadolu",
    yuzolcumu: "13.690",
    nufus_artisi: "-0,1%",
    erkek_nufus_yuzde: "49,87%",
    erkek_nufus: "211.936",
    kadin_nufus_yuzde: "50,13%",
    kadin_nufus: "213.045",
    nufus_yuzdesi_genel: "0,52%",
    ilceler: [
      {
        plaka_kodu: "66",
        ilce_kodu: "1733",
        il_adi: "YOZGAT",
        ilce_adi: "MERKEZ",
        nufus: "108.024",
        erkek_nufus: "53.775",
        kadin_nufus: "54.249",
      },
      {
        plaka_kodu: "66",
        ilce_kodu: "1117",
        il_adi: "YOZGAT",
        ilce_adi: "AKDAĞMADENİ",
        nufus: "41.902",
        erkek_nufus: "20.965",
        kadin_nufus: "20.937",
      },
      {
        plaka_kodu: "66",
        ilce_kodu: "1877",
        il_adi: "YOZGAT",
        ilce_adi: "AYDINCIK",
        nufus: "9.722",
        erkek_nufus: "4.875",
        kadin_nufus: "4.847",
      },
      {
        plaka_kodu: "66",
        ilce_kodu: "1198",
        il_adi: "YOZGAT",
        ilce_adi: "BOĞAZLIYAN",
        nufus: "33.817",
        erkek_nufus: "17.213",
        kadin_nufus: "16.604",
      },
      {
        plaka_kodu: "66",
        ilce_kodu: "1895",
        il_adi: "YOZGAT",
        ilce_adi: "ÇANDIR",
        nufus: "4.240",
        erkek_nufus: "2.067",
        kadin_nufus: "2.173",
      },
      {
        plaka_kodu: "66",
        ilce_kodu: "1242",
        il_adi: "YOZGAT",
        ilce_adi: "ÇAYIRALAN",
        nufus: "12.294",
        erkek_nufus: "6.086",
        kadin_nufus: "6.208",
      },
      {
        plaka_kodu: "66",
        ilce_kodu: "1245",
        il_adi: "YOZGAT",
        ilce_adi: "ÇEKEREK",
        nufus: "18.688",
        erkek_nufus: "9.066",
        kadin_nufus: "9.622",
      },
      {
        plaka_kodu: "66",
        ilce_kodu: "1952",
        il_adi: "YOZGAT",
        ilce_adi: "KADIŞEHRİ",
        nufus: "10.491",
        erkek_nufus: "5.236",
        kadin_nufus: "5.255",
      },
      {
        plaka_kodu: "66",
        ilce_kodu: "1982",
        il_adi: "YOZGAT",
        ilce_adi: "SARAYKENT",
        nufus: "12.462",
        erkek_nufus: "6.261",
        kadin_nufus: "6.201",
      },
      {
        plaka_kodu: "66",
        ilce_kodu: "1602",
        il_adi: "YOZGAT",
        ilce_adi: "SARIKAYA",
        nufus: "32.544",
        erkek_nufus: "16.241",
        kadin_nufus: "16.303",
      },
      {
        plaka_kodu: "66",
        ilce_kodu: "1635",
        il_adi: "YOZGAT",
        ilce_adi: "SORGUN",
        nufus: "79.781",
        erkek_nufus: "39.914",
        kadin_nufus: "39.867",
      },
      {
        plaka_kodu: "66",
        ilce_kodu: "1655",
        il_adi: "YOZGAT",
        ilce_adi: "ŞEFAATLİ",
        nufus: "14.351",
        erkek_nufus: "7.129",
        kadin_nufus: "7.222",
      },
      {
        plaka_kodu: "66",
        ilce_kodu: "1998",
        il_adi: "YOZGAT",
        ilce_adi: "YENİFAKILI",
        nufus: "5.296",
        erkek_nufus: "2.614",
        kadin_nufus: "2.682",
      },
      {
        plaka_kodu: "66",
        ilce_kodu: "1726",
        il_adi: "YOZGAT",
        ilce_adi: "YERKÖY",
        nufus: "34.888",
        erkek_nufus: "17.204",
        kadin_nufus: "17.684",
      },
    ],
    kisa_bilgi:
      "Yaklaşık 5000 bin sene öncesine ait tarihi kalıntıların bulunduğu, Eti’lerin yerleşim merkezlerinden biri olan, ülkemizin tam ortasında yer alan, parmak çöreğiyle meşhur Yozgat, Türkiye’nin ilk milli parkı olan Çamlık Milli Parkı’nı da bünyesinde barındırıyor.",
  },
  {
    il_adi: "Zonguldak",
    plaka_kodu: "67",
    alan_kodu: "372",
    nufus: "588.510",
    bolge: "Karadeniz",
    yuzolcumu: "3.342",
    nufus_artisi: "-2,0%",
    erkek_nufus_yuzde: "49,58%",
    erkek_nufus: "297.303",
    kadin_nufus_yuzde: "50,42%",
    kadin_nufus: "302.395",
    nufus_yuzdesi_genel: "0,73%",
    ilceler: [
      {
        plaka_kodu: "67",
        ilce_kodu: "1741",
        il_adi: "ZONGULDAK",
        ilce_adi: "MERKEZ",
        nufus: "120.395",
        erkek_nufus: "59.407",
        kadin_nufus: "60.988",
      },
      {
        plaka_kodu: "67",
        ilce_kodu: "1758",
        il_adi: "ZONGULDAK",
        ilce_adi: "ALAPLI",
        nufus: "42.927",
        erkek_nufus: "21.420",
        kadin_nufus: "21.507",
      },
      {
        plaka_kodu: "67",
        ilce_kodu: "1240",
        il_adi: "ZONGULDAK",
        ilce_adi: "ÇAYCUMA",
        nufus: "90.362",
        erkek_nufus: "44.476",
        kadin_nufus: "45.886",
      },
      {
        plaka_kodu: "67",
        ilce_kodu: "1276",
        il_adi: "ZONGULDAK",
        ilce_adi: "DEVREK",
        nufus: "56.925",
        erkek_nufus: "28.012",
        kadin_nufus: "28.913",
      },
      {
        plaka_kodu: "67",
        ilce_kodu: "1313",
        il_adi: "ZONGULDAK",
        ilce_adi: "EREĞLİ",
        nufus: "175.726",
        erkek_nufus: "87.340",
        kadin_nufus: "88.386",
      },
      {
        plaka_kodu: "67",
        ilce_kodu: "1926",
        il_adi: "ZONGULDAK",
        ilce_adi: "GÖKÇEBEY",
        nufus: "20.839",
        erkek_nufus: "10.349",
        kadin_nufus: "10.490",
      },
      {
        plaka_kodu: "67",
        ilce_kodu: "2100",
        il_adi: "ZONGULDAK",
        ilce_adi: "KİLİMLİ",
        nufus: "33.620",
        erkek_nufus: "16.952",
        kadin_nufus: "16.668",
      },
      {
        plaka_kodu: "67",
        ilce_kodu: "2101",
        il_adi: "ZONGULDAK",
        ilce_adi: "KOZLU",
        nufus: "48.890",
        erkek_nufus: "23.866",
        kadin_nufus: "25.024",
      },
    ],
    kisa_bilgi:
      "Batı Karadeniz Bölgesi’nde yer alan, ülkenin en zengin taş kömürü madenlerini barındıran ve limanıyla da deniz ticaretinde önemli bir yeri olan il, ayrıca Karadeniz’in en güzel sahillerinden birkaçına sahip.",
  },
  {
    il_adi: "Aksaray",
    plaka_kodu: "68",
    alan_kodu: "382",
    nufus: "433.055",
    bolge: "İç Anadolu",
    yuzolcumu: "7.659",
    nufus_artisi: "9,2%",
    erkek_nufus_yuzde: "49,69%",
    erkek_nufus: "204.792",
    kadin_nufus_yuzde: "50,31%",
    kadin_nufus: "207.380",
    nufus_yuzdesi_genel: "0,50%",
    ilceler: [
      {
        plaka_kodu: "68",
        ilce_kodu: "1120",
        il_adi: "AKSARAY",
        ilce_adi: "MERKEZ",
        nufus: "315.222",
        erkek_nufus: "157.624",
        kadin_nufus: "157.598",
      },
      {
        plaka_kodu: "68",
        ilce_kodu: "1860",
        il_adi: "AKSARAY",
        ilce_adi: "AĞAÇÖREN",
        nufus: "7.633",
        erkek_nufus: "3.815",
        kadin_nufus: "3.818",
      },
      {
        plaka_kodu: "68",
        ilce_kodu: "1921",
        il_adi: "AKSARAY",
        ilce_adi: "ESKİL",
        nufus: "27.151",
        erkek_nufus: "13.782",
        kadin_nufus: "13.369",
      },
      {
        plaka_kodu: "68",
        ilce_kodu: "1932",
        il_adi: "AKSARAY",
        ilce_adi: "GÜLAĞAÇ",
        nufus: "19.443",
        erkek_nufus: "9.785",
        kadin_nufus: "9.658",
      },
      {
        plaka_kodu: "68",
        ilce_kodu: "1861",
        il_adi: "AKSARAY",
        ilce_adi: "GÜZELYURT",
        nufus: "10.677",
        erkek_nufus: "5.384",
        kadin_nufus: "5.293",
      },
      {
        plaka_kodu: "68",
        ilce_kodu: "1557",
        il_adi: "AKSARAY",
        ilce_adi: "ORTAKÖY",
        nufus: "32.130",
        erkek_nufus: "15.901",
        kadin_nufus: "16.229",
      },
      {
        plaka_kodu: "68",
        ilce_kodu: "1866",
        il_adi: "AKSARAY",
        ilce_adi: "SARIYAHŞİ",
        nufus: "5.183",
        erkek_nufus: "2.613",
        kadin_nufus: "2.570",
      },
      {
        plaka_kodu: "68",
        ilce_kodu: "2106",
        il_adi: "AKSARAY",
        ilce_adi: "SULTANHANI",
        nufus: "11.630",
        erkek_nufus: "5.937",
        kadin_nufus: "5.693",
      },
    ],
    kisa_bilgi:
      "1989 yılında Niğde’den ayrılarak il statüsüne kavuşan Aksaray, tarihi ve doğal zenginlikleriyle harika bir turistik rota. Hıristiyanlığın ilk yıllarında önemli bir din merkezi oluşu, pek çok kilisenin eski zamanlardan bu yana günümüze ulaştığı bir yere dönüştürüyor Aksaray’ı.",
  },
  {
    il_adi: "Bayburt",
    plaka_kodu: "69",
    alan_kodu: "458",
    nufus: "84.241",
    bolge: "Karadeniz",
    yuzolcumu: "3.746",
    nufus_artisi: "-9,5%",
    erkek_nufus_yuzde: "50,29%",
    erkek_nufus: "41.377",
    kadin_nufus_yuzde: "49,71%",
    kadin_nufus: "40.897",
    nufus_yuzdesi_genel: "0,10%",
    ilceler: [
      {
        plaka_kodu: "69",
        ilce_kodu: "1176",
        il_adi: "BAYBURT",
        ilce_adi: "MERKEZ",
        nufus: "70.277",
        erkek_nufus: "35.951",
        kadin_nufus: "34.326",
      },
      {
        plaka_kodu: "69",
        ilce_kodu: "1767",
        il_adi: "BAYBURT",
        ilce_adi: "AYDINTEPE",
        nufus: "6.234",
        erkek_nufus: "3.111",
        kadin_nufus: "3.123",
      },
      {
        plaka_kodu: "69",
        ilce_kodu: "1788",
        il_adi: "BAYBURT",
        ilce_adi: "DEMİRÖZÜ",
        nufus: "8.531",
        erkek_nufus: "4.340",
        kadin_nufus: "4.191",
      },
    ],
    kisa_bilgi:
      "Karadeniz bölgesinde bulunan bu minik ilin tarihi M.Ö. 3 bin yıllarına dek uzanıyor. 1989 yılında il statüsüne kavuşan Bayburt, daha öncesinde Erzurum ve Gümüşhane’ye bağlıydı.",
  },
  {
    il_adi: "Karaman",
    plaka_kodu: "70",
    alan_kodu: "338",
    nufus: "260.838",
    bolge: "İç Anadolu",
    yuzolcumu: "8.678",
    nufus_artisi: "7,7%",
    erkek_nufus_yuzde: "50,01%",
    erkek_nufus: "125.992",
    kadin_nufus_yuzde: "49,99%",
    kadin_nufus: "125.921",
    nufus_yuzdesi_genel: "0,31%",
    ilceler: [
      {
        plaka_kodu: "70",
        ilce_kodu: "1439",
        il_adi: "KARAMAN",
        ilce_adi: "MERKEZ",
        nufus: "203.830",
        erkek_nufus: "101.666",
        kadin_nufus: "102.164",
      },
      {
        plaka_kodu: "70",
        ilce_kodu: "1768",
        il_adi: "KARAMAN",
        ilce_adi: "AYRANCI",
        nufus: "7.859",
        erkek_nufus: "3.986",
        kadin_nufus: "3.873",
      },
      {
        plaka_kodu: "70",
        ilce_kodu: "1884",
        il_adi: "KARAMAN",
        ilce_adi: "BAŞYAYLA",
        nufus: "3.508",
        erkek_nufus: "1.792",
        kadin_nufus: "1.716",
      },
      {
        plaka_kodu: "70",
        ilce_kodu: "1316",
        il_adi: "KARAMAN",
        ilce_adi: "ERMENEK",
        nufus: "27.843",
        erkek_nufus: "14.138",
        kadin_nufus: "13.705",
      },
      {
        plaka_kodu: "70",
        ilce_kodu: "1862",
        il_adi: "KARAMAN",
        ilce_adi: "KAZIMKARABEKİR",
        nufus: "4.404",
        erkek_nufus: "2.151",
        kadin_nufus: "2.253",
      },
      {
        plaka_kodu: "70",
        ilce_kodu: "1983",
        il_adi: "KARAMAN",
        ilce_adi: "SARIVELİLER",
        nufus: "11.394",
        erkek_nufus: "5.818",
        kadin_nufus: "5.576",
      },
    ],
    kisa_bilgi:
      "İç Anadolu bölgesinde yer alan Karaman, 1989 yılında Konya’dan ayrılarak il statüsüne kavuştu. ",
  },
  {
    il_adi: "Kırıkkale",
    plaka_kodu: "71",
    alan_kodu: "318",
    nufus: "277.046",
    bolge: "İç Anadolu",
    yuzolcumu: "4791",
    nufus_artisi: "3,9%",
    erkek_nufus_yuzde: "50,00%",
    erkek_nufus: "143.305",
    kadin_nufus_yuzde: "50,00%",
    kadin_nufus: "143.297",
    nufus_yuzdesi_genel: "0,35%",
    ilceler: [
      {
        plaka_kodu: "71",
        ilce_kodu: "1469",
        il_adi: "KIRIKKALE",
        ilce_adi: "MERKEZ",
        nufus: "195.661",
        erkek_nufus: "96.816",
        kadin_nufus: "98.845",
      },
      {
        plaka_kodu: "71",
        ilce_kodu: "1880",
        il_adi: "KIRIKKALE",
        ilce_adi: "BAHŞILI",
        nufus: "7.247",
        erkek_nufus: "3.640",
        kadin_nufus: "3.607",
      },
      {
        plaka_kodu: "71",
        ilce_kodu: "1882",
        il_adi: "KIRIKKALE",
        ilce_adi: "BALIŞEYH",
        nufus: "5.898",
        erkek_nufus: "3.110",
        kadin_nufus: "2.788",
      },
      {
        plaka_kodu: "71",
        ilce_kodu: "1901",
        il_adi: "KIRIKKALE",
        ilce_adi: "ÇELEBİ",
        nufus: "2.302",
        erkek_nufus: "1.221",
        kadin_nufus: "1.081",
      },
      {
        plaka_kodu: "71",
        ilce_kodu: "1268",
        il_adi: "KIRIKKALE",
        ilce_adi: "DELİCE",
        nufus: "8.391",
        erkek_nufus: "4.268",
        kadin_nufus: "4.123",
      },
      {
        plaka_kodu: "71",
        ilce_kodu: "1954",
        il_adi: "KIRIKKALE",
        ilce_adi: "KARAKEÇİLİ",
        nufus: "3.054",
        erkek_nufus: "1.542",
        kadin_nufus: "1.512",
      },
      {
        plaka_kodu: "71",
        ilce_kodu: "1463",
        il_adi: "KIRIKKALE",
        ilce_adi: "KESKİN",
        nufus: "15.659",
        erkek_nufus: "8.635",
        kadin_nufus: "7.024",
      },
      {
        plaka_kodu: "71",
        ilce_kodu: "1638",
        il_adi: "KIRIKKALE",
        ilce_adi: "SULAKYURT",
        nufus: "6.212",
        erkek_nufus: "3.193",
        kadin_nufus: "3.019",
      },
      {
        plaka_kodu: "71",
        ilce_kodu: "1992",
        il_adi: "KIRIKKALE",
        ilce_adi: "YAHŞİHAN",
        nufus: "31.544",
        erkek_nufus: "15.229",
        kadin_nufus: "16.315",
      },
    ],
    kisa_bilgi:
      "Kızılırmak ve Delice ırmaklarının suladığı topraklarda yer alan Kırıkkale, ülkemizin ortasında bulunan, kuruluşu oldukça yeni bir şehir.",
  },
  {
    il_adi: "Batman",
    plaka_kodu: "72",
    alan_kodu: "488",
    nufus: "634.491",
    bolge: "Güneydoğu Anadolu",
    yuzolcumu: "4.477",
    nufus_artisi: "13,0%",
    erkek_nufus_yuzde: "50,31%",
    erkek_nufus: "301.413",
    kadin_nufus_yuzde: "49,69%",
    kadin_nufus: "297.690",
    nufus_yuzdesi_genel: "0,73%",
    ilceler: [
      {
        plaka_kodu: "72",
        ilce_kodu: "1174",
        il_adi: "BATMAN",
        ilce_adi: "MERKEZ",
        nufus: "477.456",
        erkek_nufus: "239.442",
        kadin_nufus: "238.014",
      },
      {
        plaka_kodu: "72",
        ilce_kodu: "1184",
        il_adi: "BATMAN",
        ilce_adi: "BEŞİRİ",
        nufus: "30.928",
        erkek_nufus: "16.422",
        kadin_nufus: "14.506",
      },
      {
        plaka_kodu: "72",
        ilce_kodu: "1345",
        il_adi: "BATMAN",
        ilce_adi: "GERCÜŞ",
        nufus: "19.304",
        erkek_nufus: "9.753",
        kadin_nufus: "9.551",
      },
      {
        plaka_kodu: "72",
        ilce_kodu: "1941",
        il_adi: "BATMAN",
        ilce_adi: "HASANKEYF",
        nufus: "7.496",
        erkek_nufus: "3.766",
        kadin_nufus: "3.730",
      },
      {
        plaka_kodu: "72",
        ilce_kodu: "1487",
        il_adi: "BATMAN",
        ilce_adi: "KOZLUK",
        nufus: "60.953",
        erkek_nufus: "30.722",
        kadin_nufus: "30.231",
      },
      {
        plaka_kodu: "72",
        ilce_kodu: "1607",
        il_adi: "BATMAN",
        ilce_adi: "SASON",
        nufus: "30.182",
        erkek_nufus: "15.647",
        kadin_nufus: "14.535",
      },
    ],
    kisa_bilgi:
      "Ülkemizin güneydoğusunda, Dicle Nehri ile yan kolları olan Batman ve Garzan çayları arasındaki havzada bulunan şehir, 1990 yılından bu yana il statüsüne sahip",
  },
  {
    il_adi: "Şırnak",
    plaka_kodu: "73",
    alan_kodu: "486",
    nufus: "557.605",
    bolge: "Güneydoğu Anadolu",
    yuzolcumu: "7.078",
    nufus_artisi: "20,0%",
    erkek_nufus_yuzde: "52,96%",
    erkek_nufus: "277.585",
    kadin_nufus_yuzde: "47,04%",
    kadin_nufus: "246.605",
    nufus_yuzdesi_genel: "0,64%",
    ilceler: [
      {
        plaka_kodu: "73",
        ilce_kodu: "1661",
        il_adi: "ŞIRNAK",
        ilce_adi: "MERKEZ",
        nufus: "100.206",
        erkek_nufus: "53.534",
        kadin_nufus: "46.672",
      },
      {
        plaka_kodu: "73",
        ilce_kodu: "1189",
        il_adi: "ŞIRNAK",
        ilce_adi: "BEYTÜŞŞEBAP",
        nufus: "15.636",
        erkek_nufus: "8.335",
        kadin_nufus: "7.301",
      },
      {
        plaka_kodu: "73",
        ilce_kodu: "1223",
        il_adi: "ŞIRNAK",
        ilce_adi: "CİZRE",
        nufus: "155.182",
        erkek_nufus: "78.539",
        kadin_nufus: "76.643",
      },
      {
        plaka_kodu: "73",
        ilce_kodu: "1931",
        il_adi: "ŞIRNAK",
        ilce_adi: "GÜÇLÜKONAK",
        nufus: "11.915",
        erkek_nufus: "6.305",
        kadin_nufus: "5.610",
      },
      {
        plaka_kodu: "73",
        ilce_kodu: "1403",
        il_adi: "ŞIRNAK",
        ilce_adi: "İDİL",
        nufus: "77.105",
        erkek_nufus: "38.749",
        kadin_nufus: "38.356",
      },
      {
        plaka_kodu: "73",
        ilce_kodu: "1623",
        il_adi: "ŞIRNAK",
        ilce_adi: "SİLOPİ",
        nufus: "141.621",
        erkek_nufus: "72.744",
        kadin_nufus: "68.877",
      },
      {
        plaka_kodu: "73",
        ilce_kodu: "1698",
        il_adi: "ŞIRNAK",
        ilce_adi: "ULUDERE",
        nufus: "44.924",
        erkek_nufus: "25.321",
        kadin_nufus: "19.603",
      },
    ],
    kisa_bilgi:
      "Asırlar süren bir gelenekle günümüze ulaşan Jirki ve Guyan kilimleri, çeşitli el sanatları ve halk oyunları ile zengin bir kültürel geçmişe sahip olan Şırnak, hem Güneydoğu Anadolu hem de Doğu Anadolu topraklarında yer alıyor. ",
  },
  {
    il_adi: "Bartın",
    plaka_kodu: "74",
    alan_kodu: "378",
    nufus: "203.351",
    bolge: "Karadeniz",
    yuzolcumu: "2330",
    nufus_artisi: "8,1%",
    erkek_nufus_yuzde: "49,71%",
    erkek_nufus: "98.913",
    kadin_nufus_yuzde: "50,29%",
    kadin_nufus: "100.086",
    nufus_yuzdesi_genel: " 0,24%",
    ilceler: [
      {
        plaka_kodu: "74",
        ilce_kodu: "1172",
        il_adi: "BARTIN",
        ilce_adi: "MERKEZ",
        nufus: "159.811",
        erkek_nufus: "78.858",
        kadin_nufus: "80.953",
      },
      {
        plaka_kodu: "74",
        ilce_kodu: "1761",
        il_adi: "BARTIN",
        ilce_adi: "AMASRA",
        nufus: "14.086",
        erkek_nufus: "7.129",
        kadin_nufus: "6.957",
      },
      {
        plaka_kodu: "74",
        ilce_kodu: "1496",
        il_adi: "BARTIN",
        ilce_adi: "KURUCAŞİLE",
        nufus: "6.424",
        erkek_nufus: "3.287",
        kadin_nufus: "3.137",
      },
      {
        plaka_kodu: "74",
        ilce_kodu: "1701",
        il_adi: "BARTIN",
        ilce_adi: "ULUS",
        nufus: "21.390",
        erkek_nufus: "10.686",
        kadin_nufus: "10.704",
      },
    ],
    kisa_bilgi:
      "Batı Karadeniz’de bulunan Bartın, 1991 yılında Zonguldak’tan ayrılarak il statüsüne kavuştu. Bu küçük il, 2000 yılında milli park ilan edilen ve 1998’de Dünya Doğayı Koruma Vakfı (WWF) tarafından Avrupa Ormanları’nda korumada öncelikli alanlardan biri olarak seçilen Küre Dağları Milli Parkı içerisinde yer alıyor.",
  },
  {
    il_adi: "Ardahan",
    plaka_kodu: "75",
    alan_kodu: "478",
    nufus: "92.481",
    bolge: "Doğu Anadolu",
    yuzolcumu: "4.934",
    nufus_artisi: "-26,2%",
    erkek_nufus_yuzde: "52,16%",
    erkek_nufus: "51.593",
    kadin_nufus_yuzde: "47,84%",
    kadin_nufus: "47.314",
    nufus_yuzdesi_genel: "0,12%",
    ilceler: [
      {
        plaka_kodu: "75",
        ilce_kodu: "1144",
        il_adi: "ARDAHAN",
        ilce_adi: "MERKEZ",
        nufus: "42.023",
        erkek_nufus: "21.560",
        kadin_nufus: "20.463",
      },
      {
        plaka_kodu: "75",
        ilce_kodu: "1252",
        il_adi: "ARDAHAN",
        ilce_adi: "ÇILDIR",
        nufus: "8.983",
        erkek_nufus: "4.708",
        kadin_nufus: "4.275",
      },
      {
        plaka_kodu: "75",
        ilce_kodu: "2008",
        il_adi: "ARDAHAN",
        ilce_adi: "DAMAL",
        nufus: "5.127",
        erkek_nufus: "2.763",
        kadin_nufus: "2.364",
      },
      {
        plaka_kodu: "75",
        ilce_kodu: "1356",
        il_adi: "ARDAHAN",
        ilce_adi: "GÖLE",
        nufus: "23.933",
        erkek_nufus: "12.353",
        kadin_nufus: "11.580",
      },
      {
        plaka_kodu: "75",
        ilce_kodu: "1380",
        il_adi: "ARDAHAN",
        ilce_adi: "HANAK",
        nufus: "8.418",
        erkek_nufus: "4.390",
        kadin_nufus: "4.028",
      },
      {
        plaka_kodu: "75",
        ilce_kodu: "1579",
        il_adi: "ARDAHAN",
        ilce_adi: "POSOF",
        nufus: "6.448",
        erkek_nufus: "3.394",
        kadin_nufus: "3.054",
      },
    ],
    kisa_bilgi:
      "Tarihi boyunca Urartu, Pers, Roma, Selçuklu, Osmanlı gibi medeniyetlere ev sahipliği yapan, ülkemizin Kafkaslara açılan kapısı niteliğindeki Ardahan, 1992 yılında Kars’tan ayrılarak il statüsüne kavuştu.",
  },
  {
    il_adi: "Iğdır",
    plaka_kodu: "76",
    alan_kodu: "476",
    nufus: "203.594",
    bolge: "Doğu Anadolu",
    yuzolcumu: "3664",
    nufus_artisi: "2,1%",
    erkek_nufus_yuzde: "51,74%",
    erkek_nufus: "102.171",
    kadin_nufus_yuzde: "48,26%",
    kadin_nufus: "95.285",
    nufus_yuzdesi_genel: "0,24%",
    ilceler: [
      {
        plaka_kodu: "76",
        ilce_kodu: "1398",
        il_adi: "IĞDIR",
        ilce_adi: "MERKEZ",
        nufus: "144.663",
        erkek_nufus: "73.933",
        kadin_nufus: "70.730",
      },
      {
        plaka_kodu: "76",
        ilce_kodu: "1142",
        il_adi: "IĞDIR",
        ilce_adi: "ARALIK",
        nufus: "21.142",
        erkek_nufus: "11.475",
        kadin_nufus: "9.667",
      },
      {
        plaka_kodu: "76",
        ilce_kodu: "2011",
        il_adi: "IĞDIR",
        ilce_adi: "KARAKOYUNLU",
        nufus: "13.816",
        erkek_nufus: "7.191",
        kadin_nufus: "6.625",
      },
      {
        plaka_kodu: "76",
        ilce_kodu: "1692",
        il_adi: "IĞDIR",
        ilce_adi: "TUZLUCA",
        nufus: "23.538",
        erkek_nufus: "12.347",
        kadin_nufus: "11.191",
      },
    ],
    kisa_bilgi:
      "Ülkemizin en doğusun yer alan Iğdır, 1992 yılında Kars’tan ayrılarak il statüsüne kavuştu. Ermenistan, İran ve Azerbaycan ile sınır komşusu olması nedeniyle hem kültürel anlamda çeşitliliğe sahiptir, hem de ülkemizin üç ülkeyle sınırı olan tek şehridir. ",
  },
  {
    il_adi: "Yalova",
    plaka_kodu: "77",
    alan_kodu: "226",
    nufus: "296.333",
    bolge: "Marmara",
    yuzolcumu: "798",
    nufus_artisi: "18,2%",
    erkek_nufus_yuzde: "50,11%",
    erkek_nufus: "131.403",
    kadin_nufus_yuzde: "49,89%",
    kadin_nufus: "130.831",
    nufus_yuzdesi_genel: "0,32%",
    ilceler: [
      {
        plaka_kodu: "77",
        ilce_kodu: "1716",
        il_adi: "YALOVA",
        ilce_adi: "MERKEZ",
        nufus: "156.838",
        erkek_nufus: "77.554",
        kadin_nufus: "79.284",
      },
      {
        plaka_kodu: "77",
        ilce_kodu: "2019",
        il_adi: "YALOVA",
        ilce_adi: "ALTINOVA",
        nufus: "31.279",
        erkek_nufus: "16.553",
        kadin_nufus: "14.726",
      },
      {
        plaka_kodu: "77",
        ilce_kodu: "2020",
        il_adi: "YALOVA",
        ilce_adi: "ARMUTLU",
        nufus: "10.349",
        erkek_nufus: "5.309",
        kadin_nufus: "5.040",
      },
      {
        plaka_kodu: "77",
        ilce_kodu: "2021",
        il_adi: "YALOVA",
        ilce_adi: "ÇINARCIK",
        nufus: "37.490",
        erkek_nufus: "18.491",
        kadin_nufus: "18.999",
      },
      {
        plaka_kodu: "77",
        ilce_kodu: "2022",
        il_adi: "YALOVA",
        ilce_adi: "ÇİFTLİKKÖY",
        nufus: "48.368",
        erkek_nufus: "24.129",
        kadin_nufus: "24.239",
      },
      {
        plaka_kodu: "77",
        ilce_kodu: "2026",
        il_adi: "YALOVA",
        ilce_adi: "TERMAL",
        nufus: "6.677",
        erkek_nufus: "3.704",
        kadin_nufus: "2.973",
      },
    ],
    kisa_bilgi:
      "Marmara Bölgesinde bulunan Yalova, 1995 yılında İstanbul’dan ayrılarak il statüsüne kavuştu. İlk yerleşim izlerinin M.Ö. 3000 yılına dek uzandığı, sağlık turizmiyle de ülkemizde önemli bir yeri olan Yalova’nın birbirinden güzel ilçeleri, özellikle İstanbullular için tam birer kaçış noktası niteliğine sahip.",
  },
  {
    il_adi: "Karabük",
    plaka_kodu: "78",
    alan_kodu: "370",
    nufus: "252.058",
    bolge: "Karadeniz",
    yuzolcumu: "4142",
    nufus_artisi: "11,1%",
    erkek_nufus_yuzde: "50,24%",
    erkek_nufus: "124.596",
    kadin_nufus_yuzde: "49,76%",
    kadin_nufus: "123.418",
    nufus_yuzdesi_genel: "0,30%",
    ilceler: [
      {
        plaka_kodu: "78",
        ilce_kodu: "1433",
        il_adi: "KARABÜK",
        ilce_adi: "MERKEZ",
        nufus: "135.351",
        erkek_nufus: "67.238",
        kadin_nufus: "68.113",
      },
      {
        plaka_kodu: "78",
        ilce_kodu: "1296",
        il_adi: "KARABÜK",
        ilce_adi: "EFLANİ",
        nufus: "8.409",
        erkek_nufus: "4.226",
        kadin_nufus: "4.183",
      },
      {
        plaka_kodu: "78",
        ilce_kodu: "1321",
        il_adi: "KARABÜK",
        ilce_adi: "ESKİPAZAR",
        nufus: "12.550",
        erkek_nufus: "6.492",
        kadin_nufus: "6.058",
      },
      {
        plaka_kodu: "78",
        ilce_kodu: "1561",
        il_adi: "KARABÜK",
        ilce_adi: "OVACIK",
        nufus: "3.674",
        erkek_nufus: "1.935",
        kadin_nufus: "1.739",
      },
      {
        plaka_kodu: "78",
        ilce_kodu: "1587",
        il_adi: "KARABÜK",
        ilce_adi: "SAFRANBOLU",
        nufus: "69.449",
        erkek_nufus: "34.679",
        kadin_nufus: "34.770",
      },
      {
        plaka_kodu: "78",
        ilce_kodu: "1856",
        il_adi: "KARABÜK",
        ilce_adi: "YENİCE",
        nufus: "19.854",
        erkek_nufus: "9.954",
        kadin_nufus: "9.900",
      },
    ],
    kisa_bilgi:
      "Batı Karadeniz bölgesinde yer alan Karabük en çok meşhur turistik beldesi Safranbolu ile biliniyor. 1994 yılında UNESCO Dünya Miras Listesi’ne alınan Safranbolu, 14. yy’dan bu yana Türklerin hakimiyetinde bulunuyor ve geleneksel şehir dokusunun olduğu gibi görülebileceği, koruma altında bulunan bir tarihi miras.",
  },
  {
    il_adi: "Kilis",
    plaka_kodu: "79",
    alan_kodu: "348",
    nufus: "147.919",
    bolge: "Güneydoğu Anadolu",
    yuzolcumu: "1412",
    nufus_artisi: "14,3%",
    erkek_nufus_yuzde: "51,10%",
    erkek_nufus: "72.841",
    kadin_nufus_yuzde: "48,90%",
    kadin_nufus: "69.700",
    nufus_yuzdesi_genel: "0,17%",
    ilceler: [
      {
        plaka_kodu: "79",
        ilce_kodu: "1476",
        il_adi: "KİLİS",
        ilce_adi: "MERKEZ",
        nufus: "122.288",
        erkek_nufus: "61.566",
        kadin_nufus: "60.722",
      },
      {
        plaka_kodu: "79",
        ilce_kodu: "2023",
        il_adi: "KİLİS",
        ilce_adi: "ELBEYLİ",
        nufus: "5.679",
        erkek_nufus: "3.027",
        kadin_nufus: "2.652",
      },
      {
        plaka_kodu: "79",
        ilce_kodu: "2024",
        il_adi: "KİLİS",
        ilce_adi: "MUSABEYLİ",
        nufus: "12.861",
        erkek_nufus: "6.632",
        kadin_nufus: "6.229",
      },
      {
        plaka_kodu: "79",
        ilce_kodu: "2025",
        il_adi: "KİLİS",
        ilce_adi: "POLATELİ",
        nufus: "4.998",
        erkek_nufus: "2.578",
        kadin_nufus: "2.420",
      },
    ],
    kisa_bilgi:
      "Suriye ile komşu olan ve 1995 yılından bu yana il statüsünde bulunan Kilis’in tarihi M.Ö. 3 bin yıllarına dek uzanıyor. Zengin tarihi ve kültürel dokuya sahip Kilis’te görebileceğiniz pek çok yer var: Konik bir tepe üzerine kurulu, Haçlı Seferlerine uzanan tarihiyle Ravanda Kalesi, yerel ağızda çeşme anlamına gelen kastelleri, hayranlık uyandıran hamamları (Eski Hamam, Paşa Hamamı, Hoca Hamamı, Tuğlu Hamamı gibi), Neşet Efendi Konağı, Kilis Evi, geleneksel konakları, Ahmet Bey Konağı sayabileceklerimiz arasında.",
  },
  {
    il_adi: "Osmaniye",
    plaka_kodu: "80",
    alan_kodu: "328",
    nufus: "559.405",
    bolge: "Akdeniz",
    yuzolcumu: "3320",
    nufus_artisi: "11,5%",
    erkek_nufus_yuzde: "50,50%",
    erkek_nufus: "269.875",
    kadin_nufus_yuzde: "49,50%",
    kadin_nufus: "264.540",
    nufus_yuzdesi_genel: "0,65%",
    ilceler: [
      {
        plaka_kodu: "80",
        ilce_kodu: "1560",
        il_adi: "OSMANİYE",
        ilce_adi: "MERKEZ",
        nufus: "279.992",
        erkek_nufus: "139.961",
        kadin_nufus: "140.031",
      },
      {
        plaka_kodu: "80",
        ilce_kodu: "1165",
        il_adi: "OSMANİYE",
        ilce_adi: "BAHÇE",
        nufus: "22.754",
        erkek_nufus: "11.463",
        kadin_nufus: "11.291",
      },
      {
        plaka_kodu: "80",
        ilce_kodu: "1743",
        il_adi: "OSMANİYE",
        ilce_adi: "DÜZİÇİ",
        nufus: "85.118",
        erkek_nufus: "42.784",
        kadin_nufus: "42.334",
      },
      {
        plaka_kodu: "80",
        ilce_kodu: "2027",
        il_adi: "OSMANİYE",
        ilce_adi: "HASANBEYLİ",
        nufus: "4.652",
        erkek_nufus: "2.413",
        kadin_nufus: "2.239",
      },
      {
        plaka_kodu: "80",
        ilce_kodu: "1423",
        il_adi: "OSMANİYE",
        ilce_adi: "KADİRLİ",
        nufus: "126.941",
        erkek_nufus: "63.615",
        kadin_nufus: "63.326",
      },
      {
        plaka_kodu: "80",
        ilce_kodu: "2028",
        il_adi: "OSMANİYE",
        ilce_adi: "SUMBAS",
        nufus: "13.441",
        erkek_nufus: "6.906",
        kadin_nufus: "6.535",
      },
      {
        plaka_kodu: "80",
        ilce_kodu: "2029",
        il_adi: "OSMANİYE",
        ilce_adi: "TOPRAKKALE",
        nufus: "20.114",
        erkek_nufus: "11.705",
        kadin_nufus: "8.409",
      },
    ],
    kisa_bilgi:
      "Akdeniz bölgesi ile Çukurova’nın doğusunda bulunan, yer fıstığıyla meşhur Osmaniye, 1933 yılına kadar vilayet iken, kazaya indirilmiş; ancak 1996 yılında tekrar il statüsüne kavuşmuştur. Akarsu ve bitki örtüsü zenginliğiyle dikkati çeken il, Zorkun-Olukbaşı-Ürün ve Kadirli Almacık gibi güzel yaylalara sahip.",
  },
  {
    il_adi: "Düzce",
    plaka_kodu: "81",
    alan_kodu: "380",
    nufus: "405.131",
    bolge: "Karadeniz",
    yuzolcumu: "2.492",
    nufus_artisi: "10,3%",
    erkek_nufus_yuzde: "50,03%",
    erkek_nufus: "194.051",
    kadin_nufus_yuzde: "49,97%",
    kadin_nufus: "193.793",
    nufus_yuzdesi_genel: "0,47%",
    ilceler: [
      {
        plaka_kodu: "81",
        ilce_kodu: "1292",
        il_adi: "DÜZCE",
        ilce_adi: "MERKEZ",
        nufus: "254.827",
        erkek_nufus: "125.739",
        kadin_nufus: "129.088",
      },
      {
        plaka_kodu: "81",
        ilce_kodu: "1116",
        il_adi: "DÜZCE",
        ilce_adi: "AKÇAKOCA",
        nufus: "39.500",
        erkek_nufus: "19.414",
        kadin_nufus: "20.086",
      },
      {
        plaka_kodu: "81",
        ilce_kodu: "1784",
        il_adi: "DÜZCE",
        ilce_adi: "CUMAYERİ",
        nufus: "15.060",
        erkek_nufus: "7.545",
        kadin_nufus: "7.515",
      },
      {
        plaka_kodu: "81",
        ilce_kodu: "1905",
        il_adi: "DÜZCE",
        ilce_adi: "ÇİLİMLİ",
        nufus: "19.848",
        erkek_nufus: "11.439",
        kadin_nufus: "8.409",
      },
      {
        plaka_kodu: "81",
        ilce_kodu: "1794",
        il_adi: "DÜZCE",
        ilce_adi: "GÖLYAKA",
        nufus: "20.506",
        erkek_nufus: "10.405",
        kadin_nufus: "10.101",
      },
      {
        plaka_kodu: "81",
        ilce_kodu: "2017",
        il_adi: "DÜZCE",
        ilce_adi: "GÜMÜŞOVA",
        nufus: "16.347",
        erkek_nufus: "8.231",
        kadin_nufus: "8.116",
      },
      {
        plaka_kodu: "81",
        ilce_kodu: "2031",
        il_adi: "DÜZCE",
        ilce_adi: "KAYNAŞLI",
        nufus: "20.540",
        erkek_nufus: "10.404",
        kadin_nufus: "10.136",
      },
      {
        plaka_kodu: "81",
        ilce_kodu: "1730",
        il_adi: "DÜZCE",
        ilce_adi: "YIĞILCA",
        nufus: "14.348",
        erkek_nufus: "7.435",
        kadin_nufus: "6.913",
      },
    ],
    kisa_bilgi:
      "Tarihi Hititlere dek uzanan, İstanbul-Ankara karayolunun önemli kavşak noktalarından biri konumundaki Düzce, deniz ve doğa severler için cazip bir yer. Motor, bisiklet ve havacılık sporları, rafting, kamp, karavan tatili, yelken ve dalış gibi su sporları, yayla, göl, mağara turizmi gibi çok çeşitli aktivitelere olanak sağlayan il, büyük şehirlerden kaçıp kafa dinlemek isteyenler için harika bir seçenek. ",
  },
];
