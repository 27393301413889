import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { Formik, Form, useFormikContext } from "formik";
import { TextArea, TextInput } from "../../components";
import * as Yup from "yup";
import style from "./contact.module.scss";
import { ContactIcon } from "../../assets/icon";
import { ApiRoutes } from "../../utils/ApiRoutes";
import { AxiosRequest } from "../../utils/AxiosRequest";
import axios from "axios";

const ContactForm = () => {
  const [isLoad, setLoad] = useState(false);
  const [isSuccess, setSuccess] = useState(false);

  const ContactSchema = Yup.object().shape({
    email: Yup.string().email(`Lütfen email giriniz`).required(`Zorunlu alan.`),
    name: Yup.string().required(`Zorunlu alan.`),
    message: Yup.string().required(`Zorunlu alan.`),
  });

  const createContact = (val) => {
    AxiosRequest("post", ApiRoutes.website.createContact, {
      name: val.name,
      surname: val.surname,
      email: val.email,
      message: val.message,
      phone: val.phone,
    })
      .then(async (res) => {
        setLoad(false);
        setSuccess(true);
      })
      .catch((err) => {
        setLoad(false);
      });
  };

  return (
    <Formik
      initialValues={{
        name: "",
        surname: "",
        email: "",
        phone: "",
        message: "",
      }}
      validationSchema={ContactSchema}
      onSubmit={(values) => {
        // createPayment(values);
        if (!isSuccess) {
          setLoad(true);
          createContact(values);
        }
      }}

      // innerRef={formikRef}
    >
      {({ errors, touched, values, handleSubmit }) => (
        <Form id="payment" onSubmit={handleSubmit}>
          <div className={style.inputWrapper}>
            <div className={style.formSplit}>
              <div className={style.input}>
                <TextInput
                  name={"name"}
                  title={"Ad"}
                  placeholder={"Adınızı girin"}
                  value={values.name}
                />
                {errors.name && touched.name ? (
                  <span>{errors.name}</span>
                ) : null}
              </div>
              <div className={style.input}>
                <TextInput
                  name={"surname"}
                  title={"Soyad"}
                  placeholder={"Soyadınızı girin"}
                  value={values.surname}
                />
                {errors.surname && touched.surname ? (
                  <span>{errors.surname}</span>
                ) : null}
              </div>
            </div>
            <div className={style.formSplit}>
              <div className={style.input}>
                <TextInput
                  name={"phone"}
                  title={"Telefon"}
                  placeholder={"Telefonunuzu girin"}
                  value={values.phone}
                  type={"tel"}
                />
                {errors.phone && touched.phone ? (
                  <span>{errors.phone}</span>
                ) : null}
              </div>
              <div className={style.input}>
                <TextInput
                  name={"email"}
                  title={"Email"}
                  placeholder={"Emailinizi girin"}
                  value={values.email}
                  type={"email"}
                />
                {errors.email && touched.email ? (
                  <span>{errors.email}</span>
                ) : null}
              </div>
            </div>
            <div className={style.input}>
              <TextArea
                name={"message"}
                title={"Mesaj"}
                placeholder={"Mesajınızı girin"}
                value={values.message}
              />
              {errors.message && touched.message ? (
                <span>{errors.message}</span>
              ) : null}
            </div>
            <button
              disabled={isLoad}
              className={`${style.submit} ${isSuccess ? style.success : null}`}
            >
              {isLoad ? "..." : isSuccess ? "Başarıyla Gönderildi" : "Gönder"}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const Contact = () => {
  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <h1>Bize Ulaşın</h1>
        <p className={style.subtitle}>
          Herhangi bir sorunuz veya yorumunuz var mı? Sadece bize bir mesaj
          yazın!
        </p>
        <div className={style.card}>
          <div className={style.contactCard}>
            <h5>İletişim Bilgileri</h5>
            <ul>
              <li>
                <div className={style.iconWrapper}>
                  <ContactIcon type={"phone"} />
                </div>
                <p>+90 (532) 461 80 11</p>
              </li>
              <li>
                <div className={style.iconWrapper}>
                  <ContactIcon type={"email"} />
                </div>
                <div className={style.linkWrapper}>
                  <a target="__blank" href="mailto:bilgi@borvey.com">
                    bilgi@borvey.com
                  </a>
                  <a target="__blank" href="mailto:destek@borvey.com">
                    destek@borvey.com
                  </a>
                </div>
              </li>
              <li>
                <div className={style.iconWrapper}>
                  <ContactIcon type={"location"} />
                </div>
                <p>Küçükçekmece / İstanbul</p>
              </li>
            </ul>
          </div>
          <div className={style.formWrapper}>
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
