export const helpData = [
  {
    id: 1,
    title: "Nasıl ilan veririm?",
    description:
      "borvey'e ücretsiz üye olup tüm taşıma işleriniz için nakliye şirketlerinden en uygun ve karlı teklifi alabilirsiniz.",
  },
  {
    id: 2,
    title: "İlan verdikten sonra ne yapmam gerekir?",
    description:
      "İlanınız yayınlandıktan sonra nakliye firmalarından gelen teklifleri değerlendirmek için borvey'den mesajlarınızı kontrol etmeniz yeterli.",
  },
  {
    id: 3,
    title: "Tek parça taşıma için ilan verebilir miyim?",
    description:
      "Evet. İster ev, ister ofis, ister tek parça ya da yakın mesafe için ilan oluşturup nakliye hizmeti alabilirsiniz.",
  },
];
